import { TableStoreOption } from '@/classes/TableStoreOption'
import { getStoreDefinition } from '@/composables/StoreDefinition'
import { TableStoreId } from '@/enums/TableStoreId'
import type { ITableStore } from '@/interfaces/api/ITableStore'
import { defineStore } from 'pinia'

export const useDailyChecksStore = (): ITableStore => {
  const useStore = defineStore(
    TableStoreId.DailyChecks,
    getStoreDefinition(TableStoreOption.DailyChecks)
  )
  return useStore() as unknown as ITableStore
}

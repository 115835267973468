import { EndPoint } from '../enums/EndPoint'
import { TableStoreId } from '../enums/TableStoreId'

/**
 * Enum of endpoint suffixes available from the server.
 */
export class TableStoreOption {
  public static AccountSettings = {
    tableStoreId: TableStoreId.Events,
    endpoint: EndPoint.Events
  }

  public static CustomFields = {
    tableStoreId: TableStoreId.CustomFields,
    endpoint: EndPoint.CustomFields
  }

  public static DailyChecks = {
    tableStoreId: TableStoreId.DailyChecks,
    endpoint: EndPoint.DailyChecks
  }

  public static Devices = {
    tableStoreId: TableStoreId.Devices,
    endpoint: EndPoint.Devices
  }

  public static Events = {
    tableStoreId: TableStoreId.Events,
    endpoint: EndPoint.Events
  }

  public static Files = {
    tableStoreId: TableStoreId.Files,
    endpoint: EndPoint.Files
  }

  public static FitTests = {
    tableStoreId: TableStoreId.FitTests,
    endpoint: EndPoint.FitTests
  }

  public static Masks = {
    tableStoreId: TableStoreId.Masks,
    endpoint: EndPoint.Masks
  }

  public static MedicalClearances = {
    tableStoreId: TableStoreId.MedicalClearances,
    endpoint: EndPoint.MedicalClearances
  }

  public static People = {
    tableStoreId: TableStoreId.People,
    endpoint: EndPoint.People
  }
}

/**
 * Error that is thrown when a import value is not a valid date.
 */
export class ImportDateError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'ImportDateError'
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

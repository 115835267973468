import createFusionComponents, { type FusionComponentsOptions } from '@fusion/saas-components-v3'

const componentOptions: FusionComponentsOptions = {
  applicationID: APP_ENV.VITE_APP_APPLICATION_ID,
  clientID: APP_ENV.VITE_APP_CLIENT_ID,
  clientURL: APP_ENV.VITE_APP_CLIENT_URL,
  accountsAPIHost: APP_ENV.VITE_APP_ACCOUNTS_HOST,
  accountsAPIHostV2: APP_ENV.VITE_APP_ACCOUNTS_HOST_V2,
  applicationsAPIHost: APP_ENV.VITE_APP_APPLICATIONS_HOST,
  formsAPIHost: APP_ENV.VITE_APP_FORMS_HOST,
  appPublicPath: APP_ENV.VITE_APP_PUBLIC_PATH,
  createAccountLink: APP_ENV.VITE_APP_CREATE_ACCOUNT_URL,
  learnMoreLink: APP_ENV.VITE_APP_LEARN_MORE_URL,
  studiesAPIHost: APP_ENV.VITE_APP_STUDIES_HOST,
  devicesAPIHost: APP_ENV.VITE_APP_DEVICES_HOST,
  subscriptionsAPIHost: ''
}

export const fusionComponents = createFusionComponents(componentOptions)

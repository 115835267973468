import tinymce, { Editor } from 'tinymce'

const iconId = 'iconVeriable'
const buttonId = 'addVariable'

function plugin(editor: Editor) {
  /**
   * Get editor parameters during initialization.
   */
  const prefix = editor.getParam('variable_prefix', '{{')
  const suffix = editor.getParam('variable_suffix', '}}')

  /**
   * Add the toolbar icon and menu items
   */
  const icon = `<svg width="22" height="22" viewBox="0 0 24 24"><path d="M3 6C3 4.34315 4.34315 3 6 3C6.55228 3 7 3.44772 7 4C7 4.55228 6.55228 5 6 5C5.44772 5 5 5.44772 5 6V9.93845C5 10.7267
    4.69282 11.457 4.1795 12C4.69282 12.543 5 13.2733 5 14.0616V18C5 18.5523 5.44772 19 6 19C6.55228 19 7 19.4477 7 20C7 20.5523 6.55228 21 6 21C4.34315 21 3 19.6569 3 18V14.0616C3 13.6027
    2.6877 13.2027 2.24254 13.0914L1.75746 12.9701C1.3123 12.8589 1 12.4589 1 12C1 11.5411 1.3123 11.1411 1.75746 11.0299L2.24254 10.9086C2.6877 10.7973 3 10.3973 3 9.93845V6ZM21 6C21 4.34315
    19.6569 3 18 3C17.4477 3 17 3.44772 17 4C17 4.55228 17.4477 5 18 5C18.5523 5 19 5.44772 19 6V9.93845C19 10.7267 19.3072 11.457 19.8205 12C19.3072 12.543 19 13.2733 19 14.0616V18C19 18.5523
    18.5523 19 18 19C17.4477 19 17 19.4477 17 20C17 20.5523 17.4477 21 18 21C19.6569 21 21 19.6569 21 18V14.0616C21 13.6027 21.3123 13.2027 21.7575 13.0914L22.2425 12.9701C22.6877 12.8589 23
    12.4589 23 12C23 11.5411 22.6877 11.1411 22.2425 11.0299L21.7575 10.9086C21.3123 10.7973 21 10.3973 21 9.93845V6ZM9.28935 6.88606C8.95028 6.45011 8.32201 6.37158 7.88606 6.71065C7.45011
    7.04972 7.37158 7.67799 7.71065 8.11394L10.7331 12L7.71065 15.8861C7.37158 16.322 7.45011 16.9503 7.88606 17.2894C8.32201 17.6284 8.95028 17.5499 9.28935 17.1139L12 13.6288L14.7106
    17.1139C15.0497 17.5499 15.678 17.6284 16.1139 17.2894C16.5499 16.9503 16.6284 16.322 16.2894 15.8861L13.2669 12L16.2894 8.11394C16.6284 7.67799 16.5499 7.04972 16.1139 6.71065C15.678 6.37158
    15.0497 6.45011 14.7106 6.88606L12 10.3712L9.28935 6.88606Z" fill-rule="nonzero"/></svg>`
  editor.ui.registry.addIcon(iconId, icon)

  const addMenuButton = () => {
    editor.ui.registry.addMenuButton(buttonId, {
      icon: iconId,
      tooltip: 'Add Variable',
      fetch: (callback: any) => {
        const variables = editor.getParam('variables', [])
        const options = variables.map((variable: { key: string; value: string }) => {
          return {
            type: 'menuitem',
            text: variable.value.trim(),
            onAction: () => addVariable(variable)
          }
        })
        callback(options)
      }
    })
  }

  addMenuButton()

  /**
   * Escape all special characters for use with a Regular Expression.
   * @param value
   * @returns
   */
  function escape(value: string) {
    return value.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
  }

  /**
   * Convert a text variable "x" to a span with supporting attributes.
   * @param slug
   * @returns
   */
  function createHTMLVariable(slug: string) {
    const key = cleanSlug(slug)
    const value = getValue(key)
    const className = editor.getParam('variable_class', 'variable')
    return `<span class="${className}" data-original-variable="${slug}" contenteditable="false">${value}</span>`
  }

  /**
   * Insert a variable into the editor at the current cursor location
   * @param variable
   */
  function addVariable(variable: { key: string; value: string }) {
    const htmlVariable = createHTMLVariable(`${prefix} ${variable.key} ${suffix}`)
    editor.execCommand('mceInsertContent', false, htmlVariable)
    editor.focus()
  }

  /**
   * Clean the slug to get the plain variable key.
   * @example "{{ test }}" => "test"
   * @param value
   * @returns
   */
  function cleanSlug(value: string): string {
    return value.replace(/[^a-zA-Z0-9._]/g, '')
  }

  /**
   * Get the value using the variable key.
   * @param key
   * @returns
   */
  function getValue(key: string): string {
    const variables = editor.getParam('variables', [])
    const variable = variables.find((variable: { key: string; value: string }) => {
      return variable.key === key
    })
    return variable ? variable.value : 'unknown'
  }

  /**
   * RegExp is not stateless with '\g' so we return a new variable each call
   * @returns
   */
  function getStringVariableRegex() {
    return new RegExp(`${escape(prefix)}([ ]*[a-zA-Z0-9._]*[ ]*)?${escape(suffix)}`, 'g')
  }

  /**
   * Convert variable strings into html elements.
   * @returns
   */
  function stringToHTML() {
    const nodeList: Array<any> = []
    let nodeValue, node, div

    // find nodes that contain a string variable
    tinymce.walk(
      editor.getBody(),
      function (n) {
        if (n.nodeType == 3 && n.nodeValue && getStringVariableRegex().test(n.nodeValue)) {
          nodeList.push(n)
        }
      },
      'childNodes'
    )

    // loop over all nodes that contain a string variable
    for (let i = 0; i < nodeList.length; i++) {
      nodeValue = nodeList[i].nodeValue.replace(getStringVariableRegex(), createHTMLVariable)
      div = editor.dom.create('div', {}, nodeValue)
      while ((node = div.lastChild)) {
        editor.dom.insertAfter(node, nodeList[i])
      }

      editor.dom.remove(nodeList[i])
    }
  }

  /**
   * Convert HTML variables back into their original string format
   * for example when a user opens source view.
   */
  function htmlToString() {
    const nodeList: Array<any> = []
    let nodeValue, node, div

    // find nodes that contain a HTML variable
    tinymce.walk(
      editor.getBody(),
      function (n) {
        if (n.nodeType == 1) {
          const original = n.getAttribute('data-original-variable')
          if (original !== null) {
            nodeList.push(n)
          }
        }
      },
      'childNodes'
    )

    // Loop over all nodes that contain an HTML variable
    for (let i = 0; i < nodeList.length; i++) {
      nodeValue = nodeList[i].getAttribute('data-original-variable')
      div = editor.dom.create('div', {}, nodeValue)
      while ((node = div.lastChild)) {
        editor.dom.insertAfter(node, nodeList[i])
      }

      // remove HTML variable node
      // because we now have an text representation of the variable
      editor.dom.remove(nodeList[i])
    }
  }

  /**
   * Handle formatting the content of the editor based on the current format.
   * For example if a user switches to source view and back again.
   * @param event
   * @returns
   */
  function handleContentRerender(event: any) {
    return event.format === 'raw' ? stringToHTML() : htmlToString()
  }

  /**
   * Returns true if the element represents a variable.
   * @param element
   * @returns
   */
  function isVariable(element: Element) {
    if (
      typeof element.getAttribute === 'function' &&
      element.hasAttribute('data-original-variable')
    )
      return true

    return false
  }

  let previousCharacter = ''

  /**
   * Don't allow users to drag/drop variables. It's too glitchy.
   * @param event
   * @returns
   */
  function preventDrag(event: any) {
    const target = event.target

    if (!isVariable(target)) return null

    event.preventDefault()
    event.stopImmediatePropagation()
  }

  const keyDown = (event: KeyboardEvent) => {
    const chars = `${previousCharacter}${event.key}`
    if (chars === prefix || chars === suffix) {
      event.preventDefault()
      event.stopImmediatePropagation()
    }
    previousCharacter = event.key
  }

  editor.on('beforegetcontent', handleContentRerender)
  editor.on('init', stringToHTML)
  editor.on('getcontent', stringToHTML)
  editor.on('mousedown', preventDrag)
  editor.on('keydown', keyDown)
  editor.on('keyup', stringToHTML)
  return {
    addVariable
  }
}

tinymce.PluginManager.add('variable', plugin)

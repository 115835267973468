import { isDefined } from '@/composables/utils'
import type { ITableHeader } from '@/interfaces/ITableHeader'
import { type Composer } from 'vue-i18n'
import type { MedicalClearanceRecord } from '../MedicalClearanceRecord'

export const getTableHeaders = (
  i18n: Composer<{}, {}, {}, string, never, string>
): Array<ITableHeader> => {
  return [
    {
      title: i18n.t('fields.clearance.clearanceDate'),
      key: 'clearanceDate',
      align: 'start',
      value: (item: MedicalClearanceRecord) => {
        return isDefined(item.clearanceDate) ? item.clearanceDate : null
      }
    },
    {
      title: i18n.t('fields.clearance.expiration'),
      key: 'expiration',
      align: 'start',
      value: (item: MedicalClearanceRecord) => {
        return isDefined(item.expiration) ? item.expiration : null
      }
    },
    {
      title: i18n.t('fields.clearance.singleUse'),
      key: 'singleUse',
      align: 'start',
      value: (item: MedicalClearanceRecord) =>
        `${item.singleUse ? i18n.t('labels.yes') : i18n.t('labels.no')}`
    },
    {
      title: i18n.t('fields.clearance.scba'),
      key: 'scba',
      align: 'start',
      value: (item: MedicalClearanceRecord) =>
        `${item.scba ? i18n.t('labels.yes') : i18n.t('labels.no')}`
    },
    {
      title: i18n.t('fields.clearance.nonPressuredHood'),
      key: 'nonPressuredHood',
      align: 'start',
      value: (item: MedicalClearanceRecord) =>
        `${item.nonPressuredHood ? i18n.t('labels.yes') : i18n.t('labels.no')}`
    },
    {
      title: i18n.t('fields.clearance.hoodPAPR'),
      key: 'hoodPAPR',
      align: 'start',
      value: (item: MedicalClearanceRecord) =>
        `${item.hoodPAPR ? i18n.t('labels.yes') : i18n.t('labels.no')}`
    },
    {
      title: i18n.t('fields.clearance.hfPAPR'),
      key: 'hfPAPR',
      align: 'start',
      value: (item: MedicalClearanceRecord) =>
        `${item.hfPAPR ? i18n.t('labels.yes') : i18n.t('labels.no')}`
    },
    {
      title: i18n.t('fields.clearance.hfNegPressure'),
      key: 'hfNegPressure',
      align: 'start',
      value: (item: MedicalClearanceRecord) =>
        `${item.hfNegPressure ? i18n.t('labels.yes') : i18n.t('labels.no')}`
    },
    {
      title: i18n.t('fields.clearance.ffPAPR'),
      key: 'ffPAPR',
      align: 'start',
      value: (item: MedicalClearanceRecord) =>
        `${item.ffPAPR ? i18n.t('labels.yes') : i18n.t('labels.no')}`
    },
    {
      title: i18n.t('fields.clearance.ffNegPressure'),
      key: 'ffNegPressure',
      align: 'start',
      value: (item: MedicalClearanceRecord) =>
        `${item.ffNegPressure ? i18n.t('labels.yes') : i18n.t('labels.no')}`
    }
  ]
}

<template>
  <BaseEditor
    v-bind="$attrs"
    :title="getTitle"
    :max-width="700"
    :table-store-id="TableStoreId.MedicalClearances"
    @save-item="saveItem"
  >
    <v-row>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
        <DateField
          v-model="clearanceDate"
          :label="t('fields.clearance.clearanceDate')"
          @update:model-value="updateExpiration"
        />
        <DateField v-model="expiration" :label="t('fields.clearance.expiration')" />
        <PrimaryTextArea
          :maxlength="255"
          v-model="item.restrictions"
          :label="t('fields.clearance.restrictions')"
        />
        <StatusChip
          v-if="settingsStore.globals.clearance.enabled"
          :label="t('fields.clearance.status')"
          :iso-date="expiration?.toISOString()"
          :days-warning="settingsStore.globals.clearance.daysWarning"
          :msg-compliant="t('labels.cleared')"
          :msg-no-record="t('labels.notCleared')"
          :expires="settingsStore.globals.clearance.expires"
        />
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" xxl="6">
        <PrimarySwitch :label="t('fields.clearance.singleUse')" v-model="item.singleUse" />
        <PrimarySwitch :label="t('fields.clearance.scba')" v-model="item.scba" />
        <PrimarySwitch
          :label="t('fields.clearance.nonPressuredHood')"
          v-model="item.nonPressuredHood"
        />
        <PrimarySwitch :label="t('fields.clearance.hoodPAPR')" v-model="item.hoodPAPR" />
        <PrimarySwitch :label="t('fields.clearance.hfPAPR')" v-model="item.hfPAPR" />
        <PrimarySwitch
          density="compact"
          :label="t('fields.clearance.hfNegPressure')"
          v-model="item.hfNegPressure"
          color="primary"
        />
        <PrimarySwitch :label="t('fields.clearance.ffPAPR')" v-model="item.ffPAPR" />
        <PrimarySwitch :label="t('fields.clearance.ffNegPressure')" v-model="item.ffNegPressure" />
      </v-col>
    </v-row>

    <p class="required-text">* = {{ t('labels.required').toLowerCase() }}</p>
  </BaseEditor>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { isDefined } from '@/composables/utils'
import { TableStoreId } from '@/enums/TableStoreId'
import type { IPeopleStore } from '@/interfaces/api/IPeopleStore'
import type { ITableStore } from '@/interfaces/api/ITableStore'
import { MedicalClearanceRecord } from '@/models/MedicalClearanceRecord'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import dayjs from 'dayjs'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import DateField from '../inputs/DateField.vue'

const store = TableStoreFactory.get(TableStoreId.MedicalClearances) as ITableStore
const peopleStore = TableStoreFactory.get(TableStoreId.People) as IPeopleStore
const settingsStore = useSettingsStore()
const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS

// REACTIVE VARIABLES
const item = ref<MedicalClearanceRecord>(new MedicalClearanceRecord())

// COMPUTED PROPERTIES
const clearanceDate = computed({
  get: (): Date | null => {
    if (isDefined(item.value.clearanceDate)) {
      return new Date(item.value.clearanceDate!)
    }
    return null
  },
  set: (value: Date | null) => {
    item.value.clearanceDate = isDefined(value) ? value!.toISOString() : null
  }
})

const expiration = computed({
  get: (): Date | null => {
    if (isDefined(item.value.expiration)) {
      return new Date(item.value.expiration!)
    }
    return null
  },
  set: (value: Date | null) => {
    item.value.expiration = isDefined(value) ? value!.toISOString() : null
  }
})

const getTitle = computed(() => {
  return `${t('dialogs.headers.medicalClearance')} for ${peopleStore.selectedItem?.firstName} ${peopleStore.selectedItem?.lastName}`
})

// WATCHERS
watch(
  (): any => store.selectedItem,

  (value) => {
    item.value = { ...value }
  }
)

// LIFECYCLE HOOKS

// FUNCTIONS
const saveItem = async () => {
  const response = await store.save({ ...item.value }, false, true)
  if (!response.error) {
    peopleStore.loadItems()
  }
}

const updateExpiration = () => {
  if (clearanceDate.value) {
    expiration.value = dayjs(clearanceDate.value)
      .add(settingsStore.globals.clearance.daysToExpire, 'days')
      .toDate()
  } else {
    expiration.value = null
  }
}
</script>

<style lang="scss" scoped></style>

<template>
  <BaseDialog
    :max-width="500"
    v-bind="$attrs"
    v-model="model"
    class="delete-dialog"
    icon="mdi-delete-circle-outline"
    :icon-color="Colors.accentError"
    :persistent="true"
  >
    {{ message }}
    <template v-slot:actions>
      <ButtonSecondary @click="finalizeDelete" :loading="loading" :text="t('buttons.cancel')" />
      <v-spacer />
      <ButtonPrimary @click="deleteItem" :loading="loading" :text="t('buttons.delete')" />
    </template>
  </BaseDialog>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import Colors from '@/styles/_colors.module.scss'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS
defineProps({
  loading: { type: Boolean, default: false },
  message: { type: String, default: null }
})

const emit = defineEmits<{
  (e: 'delete'): void
  (e: 'cancel'): void
}>()

// REACTIVE VARIABLES
const model = defineModel<boolean>({ default: false })

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// FUNCTIONS
const deleteItem = () => {
  emit('delete')
}

const finalizeDelete = () => {
  emit('cancel')
}
</script>

<style lang="scss" scoped>
.delete-dialog v-icon {
  color: $color-accent-error;
}
</style>

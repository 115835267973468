<template>
  <BasePage>
    <embed v-if="isPdf" class="embed-sizing" :src="file?.encodedFile" :type="file?.type" />
    <img v-if="!isPdf" class="sizing" :src="file?.encodedFile" />
  </BasePage>
</template>

<script setup lang="ts">
import { TableStoreId } from '@/enums/TableStoreId'
import type { ITableStore } from '@/interfaces/api/ITableStore'
import { FileRecord } from '@/models/FileRecord'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { computed, onBeforeMount, ref } from 'vue'

// STORES, IMPORTS, & COMPOSABLES
const filesStore = TableStoreFactory.get(TableStoreId.Files) as ITableStore

// PROPS & EMITS

// REACTIVE VARIABLES
const file = ref<FileRecord>()

// COMPUTED PROPERTIES
const isPdf = computed(() => {
  return file.value?.type === 'application/pdf'
})

// LIFECYCLE HOOKS
onBeforeMount(async () => {
  const params = new URLSearchParams(window.location.search)
  const id = Number(params.get('id'))
  if (isNaN(id)) {
    return
  }

  const response = await filesStore.getOne(id)
  if (!response.error && response.data) {
    file.value = response.data as FileRecord
  }
})
// FUNCTIONS
</script>

<style lang="scss" scoped>
.embed-sizing {
  width: 100%;
  height: 100vmax;
}
</style>

import { WorkStatus } from '@/classes/WorkStatus'
import { EditorMode } from '@/enums/EditorMode'
import { EndPoint } from '@/enums/EndPoint'
import { SearchType } from '@/enums/SearchType'
import { TableStoreId } from '@/enums/TableStoreId'
import type { View } from '@/enums/View'
import type { ICollectionResponse } from '@/interfaces/api/ICollectionResponse'
import type { IFieldSearchOptions } from '@/interfaces/api/IFieldSearchOptions'
import type { IItemResponse } from '@/interfaces/api/IItemResponse'
import type { IQueryOptions } from '@/interfaces/api/IQueryOptions'
import type { ISort } from '@/interfaces/api/ISort'
import type { ITableHeader } from '@/interfaces/ITableHeader'
import type { ITableOptions } from '@/interfaces/ITableOptions'
import { getSearchFields } from '@/models/defaults/SearchFields'
import { getDefaultSort } from '@/models/defaults/TableSorts'
import { getTableHeaders } from '@/models/tableHeaders'
import { TableRecord } from '@/models/TableRecord'
import { useAPIStore } from '@/stores/db/ApiStore'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import { debounce, isEqual, type DebouncedFunc } from 'lodash'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { convertTableSortToOrderBy, isDefined } from './utils'

export const getStoreDefinition: Function = (options: {
  tableStoreId: TableStoreId
  endpoint: EndPoint
}) => {
  const tableStoreDefinition = () => {
    // STORES, IMPORTS, & COMPOSABLES
    const apiStore = useAPIStore()
    const settingsStore = useSettingsStore()
    const i18n = useI18n({ useScope: 'global' })

    let _searchPending: DebouncedFunc<any>

    // REACTIVE VARIABLES
    const _selectedItem = ref<TableRecord>()
    const advancedFilter = ref<Record<string, string | number | boolean | null>>({})
    const advancedFilterApplied = ref<boolean>(false)
    const count = ref<number>(0)
    const defaultEditorMode = ref<EditorMode>(EditorMode.Edit)
    const editorMode = ref<EditorMode>(defaultEditorMode.value)
    const expandedItems = ref<Array<number>>([])
    const headers = ref<Array<ITableHeader>>([])
    const items = ref<Array<TableRecord>>([])
    const itemsPerPage = ref<number>(10)
    const searchText = ref<string>('')
    const searchType = ref<SearchType>(SearchType.FieldSearch)
    const showEditDialog = ref<boolean>(false)
    const showDeleteDialog = ref<boolean>(false)
    const _view = ref<string>()
    const workStatus = ref<WorkStatus>(new WorkStatus())

    const itemQuery = ref<IQueryOptions | IFieldSearchOptions>({
      where: []
    })

    // COMPUTED PROPERTIES
    const defaultSort = computed((): Array<ISort> => {
      return getDefaultSort(options.tableStoreId)
    })

    const searchFields = computed(() => {
      return getSearchFields(options.tableStoreId)
    })

    const selectedItem = computed(() => {
      return _selectedItem.value
    })

    const filteredHeaders = computed(() => {
      return headers.value.filter((header: ITableHeader) => {
        if (header.key === 'actions' || !settingsStore.disabledHeaders[options.tableStoreId]) {
          return true
        }
        return !settingsStore.disabledHeaders[options.tableStoreId].includes(header.key)
      })
    })

    // WATCHERS
    watch(
      (): any => advancedFilter,
      () => {
        advancedFilterApplied.value = !isEqual(advancedFilter.value, {})
      },
      { deep: true }
    )

    // FUNCTIONS
    const finalizeDelete = () => {
      showDeleteDialog.value = false
      setSelectedItem(undefined)
    }

    const finalizeEdit = () => {
      showEditDialog.value = false
      editorMode.value = defaultEditorMode.value
      setSelectedItem(undefined)
    }

    const confirmDelete = (item: TableRecord) => {
      setSelectedItem(item)
      showDeleteDialog.value = true
    }

    const createNewItem = (item: TableRecord) => {
      _selectedItem.value = item
      showEditDialog.value = true
    }

    const deleteOne = async (
      item: TableRecord | undefined,
      showError: boolean = true
    ): Promise<IItemResponse | undefined> => {
      if (item?.id) {
        workStatus.value.deleteOne = true
        const response = await apiStore.deleteOne(options.endpoint, item.id, showError)
        workStatus.value.deleteOne = false
        await loadItems()
        finalizeDelete()
        return response
      }
    }

    const editItem = (item: TableRecord) => {
      setSelectedItem(item)
      showEditDialog.value = true
    }

    const getOne = async (
      id: number,
      fields: Array<string> = [],
      showError: boolean = true
    ): Promise<IItemResponse> => {
      workStatus.value.getOne = true
      const response = await apiStore.getOne(options.endpoint, id, fields, showError)
      workStatus.value.getOne = false
      return response
    }

    const _parseFieldSearch = (tableOptions: ITableOptions) => {
      const fieldSearchOptions: IFieldSearchOptions = {
        currentPage: tableOptions.page,
        itemsPerPage: tableOptions.itemsPerPage,
        fields: searchFields.value,
        text: tableOptions.search,
        orderBy: convertTableSortToOrderBy(tableOptions.sortBy)
      }
      return fieldSearchOptions
    }

    const _parseQuery = (tableOptions: ITableOptions) => {
      const queryOptions: IQueryOptions = {
        where: itemQuery.value.where,
        limit: tableOptions.itemsPerPage,
        offset: Math.floor(tableOptions.itemsPerPage * (tableOptions?.page - 1)),
        orderBy: convertTableSortToOrderBy(tableOptions.sortBy)
      }
      return queryOptions
    }

    const loadItems = async (
      defer: boolean = false,
      tableOptions?: ITableOptions,
      showError: boolean = true
    ): Promise<void> => {
      let response: ICollectionResponse = {
        data: {
          results: [],
          count: 0
        },
        error: null
      }

      if (_searchPending) {
        _searchPending.cancel()
      }

      // Substitute the view if it is defined on the store.
      const endpoint = isDefined(_view.value) ? EndPoint.Views : options.endpoint

      let searchMethod = null
      if (searchType.value === SearchType.FieldSearch) {
        workStatus.value.search = true
        if (tableOptions) {
          itemQuery.value = _parseFieldSearch(tableOptions)
        }
        searchMethod = apiStore.search
      } else {
        workStatus.value.getMany = true
        if (tableOptions) {
          itemQuery.value = _parseQuery(tableOptions)
        }
        searchMethod = apiStore.getMany
      }
      itemQuery.value.view = _view.value

      if (defer) {
        _searchPending = debounce(async () => {
          response = await searchMethod(endpoint, itemQuery.value as IFieldSearchOptions, showError)
          items.value = (response.data?.results as Array<TableRecord>) || []
          count.value = response.data?.count || 0
        }, 750)
        _searchPending()
      } else {
        response = await searchMethod(endpoint, itemQuery.value as IFieldSearchOptions, showError)
        items.value = (response.data?.results as Array<TableRecord>) || []
        count.value = response.data?.count || 0
      }
      workStatus.value.search = false
      workStatus.value.getMany = false
    }

    const getHeader = (key: string): ITableHeader | undefined => {
      return headers.value.find((header) => {
        return header.key === key
      })
    }

    const resetAdvancedFilter = async () => {
      advancedFilter.value = {}
      itemQuery.value.where = []
      await loadItems()
    }

    const revertEdit = async () => {
      _selectedItem.value = _selectedItem.value ? { ...selectedItem.value } : undefined
      editorMode.value = EditorMode.View
    }

    const save = async (
      item: TableRecord,
      showError: boolean = true,
      skipRefresh: boolean = false
    ): Promise<IItemResponse> => {
      workStatus.value.save = true
      const response = await apiStore.save(options.endpoint, item, showError)
      if (!response.error) {
        if (skipRefresh === false) {
          await loadItems()
        }
        finalizeEdit()
      }
      workStatus.value.save = false
      return response
    }

    const setSelectedItem = (item: TableRecord | undefined) => {
      _selectedItem.value = item
    }

    const updateHeaders = async () => {
      headers.value = await getTableHeaders(options.tableStoreId, i18n)
    }

    const updateMany = async (
      queryOptions?: IQueryOptions,
      showError: boolean = true
    ): Promise<ICollectionResponse> => {
      workStatus.value.updateMany = true
      const response = await apiStore.updateMany(options.endpoint, queryOptions, showError)
      workStatus.value.updateMany = false
      return response
    }

    const useView = (view: View) => {
      _view.value = view
    }

    const state = {
      confirmDelete,
      createNewItem,
      deleteOne,
      editItem,
      finalizeDelete,
      finalizeEdit,
      getOne,
      getHeader,
      loadItems,
      resetAdvancedFilter,
      revertEdit,
      save,
      setSelectedItem,
      updateHeaders,
      updateMany,
      useView,
      advancedFilter,
      advancedFilterApplied,
      count,
      defaultEditorMode,
      defaultSort,
      editorMode,
      expandedItems,
      filteredHeaders,
      headers,
      itemQuery,
      items,
      itemsPerPage,
      searchFields,
      searchText,
      searchType,
      selectedItem,
      showEditDialog,
      showDeleteDialog,
      workStatus
    }
    return state
  }
  return tableStoreDefinition
}

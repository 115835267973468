/**
 * Enum of all table store ids.
 */
export enum StoreId {
  Appointments = 'appointments',
  Dashboard = 'dashboard',
  EmailTemplates = 'emailTemplates',
  Calendar = 'calendar',
  Import = 'import',
  Scheduler = 'scheduler',
  Settings = 'settings',
  SnackBars = 'snackbars'
}

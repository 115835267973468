import { TableStoreOption } from '@/classes/TableStoreOption'
import { getStoreDefinition } from '@/composables/StoreDefinition'
import { SearchType } from '@/enums/SearchType'
import { SqlComparison } from '@/enums/SqlComparison'
import { TableStoreId } from '@/enums/TableStoreId'
import { View } from '@/enums/View'
import type { IEventsStore } from '@/interfaces/api/IEventsStore'
import type { ITableStore } from '@/interfaces/api/ITableStore'
import type { ICalendarRange } from '@/interfaces/ICalendarRange'
import { defineStore } from 'pinia'

let _store: IEventsStore

export const useEventsStore = (): ITableStore => {
  if (_store) {
    return _store
  }

  const useStore = defineStore(TableStoreId.Events, getStoreDefinition(TableStoreOption.Events))
  _store = useStore() as unknown as IEventsStore
  _extend(_store)
  return _store
}

const _extend = (store: IEventsStore) => {
  store.useView(View.EventsWithDetail)
  store.searchType = SearchType.Query

  store.loadEventsByRange = async (range: ICalendarRange) => {
    store.items = []
    store.itemQuery = {
      where: [
        {
          field: 'dtStart',
          comparison: SqlComparison.Between,
          value1: new Date(range.start).toISOString(),
          value2: new Date(range.end).toISOString()
        }
      ]
    }
    await store.loadItems()
  }
}

import { SqlOrder } from '@/enums/SqlOrder'
import { TableStoreId } from '@/enums/TableStoreId'
import type { ISort } from '@/interfaces/api/ISort'

const _tableSorts = new Map<TableStoreId, Array<ISort>>()

const _initialize = () => {
  _tableSorts.set(TableStoreId.MedicalClearances, [{ field: 'expiration', order: SqlOrder.DESC }])
  _tableSorts.set(TableStoreId.CustomFields, [{ field: 'labelName', order: SqlOrder.ASC }])
  _tableSorts.set(TableStoreId.DailyChecks, [{ field: 'date', order: SqlOrder.DESC }])
  _tableSorts.set(TableStoreId.Devices, [{ field: 'serialNumber', order: SqlOrder.ASC }])
  _tableSorts.set(TableStoreId.FitTests, [{ field: 'testDate', order: SqlOrder.DESC }])
  _tableSorts.set(TableStoreId.Masks, [{ field: 'maskManufacturer', order: SqlOrder.ASC }])
  _tableSorts.set(TableStoreId.People, [{ field: 'lastName', order: SqlOrder.ASC }])
}

const getDefaultSort = (tableStoreId: TableStoreId): Array<ISort> => {
  if (_tableSorts.size === 0) {
    _initialize()
  }
  const sort = _tableSorts.get(tableStoreId)
  if (sort) {
    return sort
  }
  return []
}

export { getDefaultSort }

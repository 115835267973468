/**
 * Enum of message types used by the snackbar component.
 */
export enum MessageType {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  DatabaseError = 'database'
}

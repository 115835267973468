<template>
  <BasePage>
    <v-tabs
      v-model="schedulerStore.selectedTab"
      align-tabs="start"
      density="compact"
      color="primary"
      :grow="false"
    >
      <v-tab :key="SchedulerTab.Appointments" :value="SchedulerTab.Appointments">
        <v-icon icon="mdi-calendar-account"></v-icon>{{ t('tabs.scheduler.appointments') }}</v-tab
      >
      <v-tab :key="SchedulerTab.ManageSlots" :value="SchedulerTab.ManageSlots">
        <v-icon icon="mdi-calendar-multiple"></v-icon>{{ t('tabs.scheduler.manageSlots') }}</v-tab
      >
    </v-tabs>
    <v-divider class="tab-divider"></v-divider>
    <v-tabs-window class="tab-content" v-model="schedulerStore.selectedTab">
      <v-tabs-window-item :key="SchedulerTab.Appointments" :value="SchedulerTab.Appointments">
        <AppointmentsView />
      </v-tabs-window-item>
      <v-tabs-window-item :key="SchedulerTab.ManageSlots" :value="SchedulerTab.ManageSlots">
        <ManageSlotsView />
      </v-tabs-window-item>
    </v-tabs-window>
    <EditEvent />
  </BasePage>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { SchedulerTab } from '@/enums/SchedulerTab'
import { useSchedulerStore } from '@/stores/ui/SchedulerStore'
import { useI18n } from 'vue-i18n'
import AppointmentsView from './scheduler/AppointmentsView.vue'
import ManageSlotsView from './scheduler/ManageSlotsView.vue'

const { t } = useI18n({ useScope: 'global' })
const schedulerStore = useSchedulerStore()

// PROPS & EMITS

// REACTIVE VARIABLES

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// FUNCTIONS
</script>

<style lang="scss" scoped></style>

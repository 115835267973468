import { TableRecord } from '@/models/TableRecord'

export class CustomFieldRecord extends TableRecord {
  public labelName!: string
  public required: number = 1
  public enabled: number = 1
  public active: number = 1
  public combo: number = 1
  public option0: string | null = null
  public option1: string | null = null
  public option2: string | null = null
  public option3: string | null = null
  public option4: string | null = null
  public option5: string | null = null
  public option6: string | null = null
  public option7: string | null = null
  public option8: string | null = null
  public option9: string | null = null
  public autoComplete: boolean = true
  public allowFiltering: boolean = true
  public validation: string | null = null
}

import { TableRecord } from '@/models/TableRecord'
import { v4 as uuidv4 } from 'uuid'

export class PeopleRecord extends TableRecord {
  public firstName: string | null = null
  public lastName: string | null = null
  public idNumber: string | null = null
  public company: string | null = null
  public location: string | null = null
  public note: string | null = null
  public custom1Label: string | null = null
  public custom1Data: string | null = null
  public custom2Label: string | null = null
  public custom2Data: string | null = null
  public custom3Label: string | null = null
  public custom3Data: string | null = null
  public custom4Label: string | null = null
  public custom4Data: string | null = null
  public inactive: boolean = false
  public reserved1: string | null = null
  public reserved2: string | null = null
  public reserved3: string | null = null
  public reserved4: string | null = null
  public email: string | null = null
  public respiratorRequired: boolean = true
  public supervisorId: number | null = null
  public trainingDate?: string | null = null
  public trainingExpiration?: string | null = null
  public isTestAdmin: boolean = false
  public isSupervisor: boolean = false
  public schedulingKey: string = uuidv4()
}

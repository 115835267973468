/**
 * Enum of endpoint suffixes available from the server.
 */
export enum EndPoint {
  AccountSettings = 'account-settings',
  Events = 'events',
  Exec = 'exec',
  MedicalClearances = 'medical-clearances',
  CustomFields = 'custom-fields',
  DailyChecks = 'daily-checks',
  Devices = 'devices',
  Files = 'files',
  FitTests = 'fit-tests',
  Masks = 'masks',
  People = 'people',
  Routes = 'routes',
  Views = 'views'
}

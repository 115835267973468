<template>
  <span
    :disabled="disabled"
    :class="{ 'button-icon': !disabled, 'button-icon-disabled': disabled }"
    v-resize="onResize"
  >
    <v-icon :disabled="disabled" v-bind="$attrs" v-tooltip="{ text, disabled: !isMini }"></v-icon>
    <span v-if="!isMini" class="text">{{ text }}</span>
  </span>
</template>

<script setup lang="ts">
import { ref } from 'vue'

// STORES, IMPORTS, & COMPOSABLES

// PROPS & EMITS
defineProps({
  disabled: { type: Boolean, default: false },
  text: { type: String, required: true }
})
// REACTIVE VARIABLES
const isMini = ref<boolean>(false)

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// FUNCTIONS
const onResize = () => {
  isMini.value = window.innerWidth < 600
}
</script>

<style lang="scss" scoped>
.button-icon {
  margin-right: 1rem;
  cursor: pointer;
}
.button-icon:hover {
  color: $color-main-primary;
}
.button-icon-disabled {
  margin-right: 1rem;
  color: $color-dark-56;
  cursor: default;
}

.text {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  font-size: 0.75rem;
  margin-left: 0.25rem;
}
</style>

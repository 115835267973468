<template>
  <BaseDialog
    v-model="model"
    v-bind="$attrs"
    :max-height="600"
    :max-width="800"
    icon="mdi-calendar-export"
  >
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          Please select the calendar subscription format and follow the instructions. Calendar
          subscriptions are read-only. Events will be refreshed every 15 minutes. You can subscribe
          to multiple calendars.
        </v-col>
      </v-row>
      <v-row>
        <v-col style="margin-bottom: 0">
          <PrimarySelect
            v-model="selectedType"
            :label="t('labels.calendar.selectSubscriptionType')"
            :items="calendars"
          ></PrimarySelect>
          <PrimarySelect
            v-model="selectedCategory"
            :label="t('labels.calendar.type')"
            :items="eventCategories"
          ></PrimarySelect>
          <PrimarySwitch
            v-model="includeTentative"
            color="primary"
            density="compact"
            :hide-details="true"
            :label="t('labels.calendar.includeTentative')"
          ></PrimarySwitch>
        </v-col>
      </v-row>
      <v-row v-if="selectedType === 1">
        <v-col>
          <p class="instructions">
            Click below to subscribe to the selected calendar. If the incorrect calendar opens you
            will need to either change your default calendar or manually subscribe.
          </p>
          <a class="button-link" :href="subscribeLink">
            <v-icon style="margin-right: 0.5rem" icon=" mdi-calendar-export" />
            <span class="link-text"> {{ t('buttons.subscribe') }}</span>
          </a>
        </v-col>
      </v-row>
      <v-row v-if="selectedType === 2">
        <v-col>
          <p class="instructions">Click below to open your Google Calendar and subscribe.</p>
          <a
            target="_blank"
            class="button-link"
            :href="`https://calendar.google.com/calendar/u/0/r?cid=${subscribeLink}`"
          >
            <v-icon style="margin-right: 0.5rem" icon="mdi-open-in-new" />
            <span class="link-text"> {{ t('buttons.openCalendar') }}</span>
          </a>
        </v-col>
      </v-row>
      <v-row v-if="selectedType === 5">
        <v-col>
          <p class="instructions">Click below to open your calendar and subscribe.</p>
          <a
            target="_blank"
            class="button-link"
            :href="`https://outlook.office.com/calendar/0/addfromweb?url=${subscribeLink}`"
          >
            <v-icon style="margin-right: 0.5rem" icon="mdi-open-in-new" />
            <span class="link-text"> {{ t('buttons.openCalendar') }}</span>
          </a>
        </v-col>
      </v-row>
      <v-row v-if="[3, 6].includes(selectedType)">
        <v-col>
          <p class="instructions">Click below to copy the Calendar link and subscribe manually.</p>
          <ButtonIcon
            style="margin-top: 0.25rem"
            @click="copyToClipboard"
            :text="t('buttons.copyToClipboard')"
            icon="mdi-content-copy"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="selectedType === 4">
          <p class="instructions">
            Click below to download a calendar file to be imported manually.
          </p>
          <a class="button-link" :href="downloadLink">
            <v-icon style="margin-right: 0.5rem" icon="mdi-download" />
            <span class="link-text">{{ t('buttons.download') }}</span>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </BaseDialog>
</template>

<script setup lang="ts">
import { MessageType } from '@/enums/MessageType'
import { ProgramCategory } from '@/enums/ProgramCategory'
import type { ISimpleOption } from '@/interfaces/ISimpleOption'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import { useSnackbarStore } from '@/stores/ui/SnackbarStore'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import PrimarySelect from '../inputs/PrimarySelect.vue'

// STORES, IMPORTS, & COMPOSABLES
const settingsStore = useSettingsStore()
const snackbarStore = useSnackbarStore()

const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS
const model = defineModel<boolean>({ default: false })

// REACTIVE VARIABLES
const calendars = ref<Array<ISimpleOption>>([
  { title: 'Apple Calendar, Lotus Notes, Outlook for Windows, Thunderbird', value: 1 },
  { title: 'Google Calendar', value: 2 },
  { title: 'Copy as Link (manually subscribe)', value: 3 },
  { title: 'Download ICS File', value: 4 },
  { title: 'Outlook 365, Outlook for Mac', value: 5 },
  { title: 'Yahoo Calendar', value: 6 }
])

const includeTentative = ref<boolean>(false)
const selectedType = ref<number>(1)
const selectedCategory = ref<number>(1)

// COMPUTED PROPERTIES
const downloadLink = computed(() => {
  return getLink()
})

const eventCategories = computed(() => {
  const categories = [{ title: t('labels.categories.testing'), value: ProgramCategory.FitTest }]
  if (settingsStore.globals.clearance.enabled) {
    categories.push({
      title: t('labels.categories.clearance'),
      value: ProgramCategory.MedicalClearance
    })
  }
  if (settingsStore.globals.training.enabled) {
    categories.push({ title: t('labels.categories.training'), value: ProgramCategory.Training })
  }
  return categories
})

const subscribeLink = computed(() => {
  return getLink(true)
})

// LIFECYCLE HOOKS

// FUNCTIONS
const copyToClipboard = () => {
  navigator.clipboard.writeText(getLink())
  snackbarStore.addMessage(t('text.copiedToClipboard'), MessageType.Info)
}

const getLink = (useWebCal: boolean = false): string => {
  let host = APP_ENV.VITE_APP_FITPRO_HOST
  if (useWebCal) {
    host = host.replace(location.protocol, 'webcal:')
  }
  let link = `${host}/calendar.ics?auth=${settingsStore.sapAccountId}&category=${selectedCategory.value}`
  if (includeTentative.value) {
    link += `&tentative=true`
  }
  return link
}
</script>

<style lang="scss" scoped>
.button-link {
  color: $color-text-primary;
  text-decoration: none;
}
.button-link:hover {
  color: $color-main-primary;
}

.instructions {
  margin-top: 0;
}

.link-text {
  text-decoration: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  font-size: 0.75rem;
}
</style>

<template>
  <div style="margin-bottom: 0.75rem">
    <div class="display-label">{{ label }}</div>
    <div>
      <div class="display-value">
        <div v-if="Array.isArray(values)" style="max-height: 9rem; overflow: auto">
          <div>
            <div :key="index" v-for="(value, index) in values" style="margin-left: 0.25rem">
              {{ isDefined(value) ? value : _defaultValue }}
            </div>
          </div>
        </div>
        <div v-else>{{ isDefined(value) ? value : _defaultValue }}<slot name="icon"></slot></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { isDefined } from '@/composables/utils'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS
const props = defineProps({
  defaultValue: { type: String },
  label: { type: String },
  value: { type: [String, Number, null, Array<string | number>] },
  values: { type: [Array<string | number>] }
})

// REACTIVE VARIABLES
const _defaultValue = ref<string>(t('labels.dash'))

// COMPUTED PROPERTIES

// WATCHERS
watch(
  (): any => props.defaultValue,
  (value) => {
    _defaultValue.value = value
  }
)

// LIFECYCLE HOOKS

// FUNCTIONS
</script>

<style lang="scss" scoped></style>

<template>
  <BaseEditor
    v-bind="$attrs"
    :table-store-id="TableStoreId.Masks"
    :title="title"
    :max-height="600"
    :max-width="700"
    @save-item="saveItem"
  >
    <v-row>
      <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6">
        <PrimaryTextField
          required
          :label="t('fields.masks.manufacturer')"
          :maxlength="64"
          v-model.trim="item.maskManufacturer"
          :rules="RulesManager.getRules(Rule.Required)"
          @update:model-value="updateDescription()"
        />
        <PrimaryTextField
          required
          :label="t('fields.masks.model')"
          :maxlength="64"
          v-model.trim="item.maskModel"
          :rules="RulesManager.getRules(Rule.Required)"
          @update:model-value="updateDescription()"
        />
        <PrimaryTextField
          required
          :label="t('fields.masks.style')"
          :maxlength="64"
          v-model.trim="item.maskStyle"
          :rules="RulesManager.getRules(Rule.Required)"
          @update:model-value="updateDescription()"
        />
        <PrimaryTextField
          required
          inputmode="numeric"
          :label="t('fields.masks.passLevel')"
          min="0"
          max="99999"
          :maxlength="5"
          v-model.trim="item.ffPassLevel"
          :rules="RulesManager.getRules([Rule.ValidNumber, Rule.Required])"
          @update:model-value="updateDescription()"
        />
        <PrimaryTextField
          :label="t('fields.masks.approval')"
          :maxlength="64"
          v-model.trim="item.approval"
        />
      </v-col>
      <v-col style="margin-top: 0.5rem" cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6">
        <PrimarySelect
          required
          v-model="item.reserved1"
          :label="t('fields.masks.reserved1')"
          :items="[
            { title: t('fields.masks.reserved1Option1'), value: '1' },
            { title: t('fields.masks.reserved1Option2'), value: '0' }
          ]"
        ></PrimarySelect>
        <PrimarySwitch :label="t('fields.masks.n95')" v-model="item.n95" />
        <p class="n95-hint">{{ t('fields.masks.n95Hint') }}</p>
        <PrimarySwitch :label="t('fields.masks.autoDescription')" v-model="item.autoDescription" />
        <PrimaryTextField
          required
          :disabled="item.autoDescription"
          :label="t('fields.masks.maskDescription')"
          :maxlength="64"
          v-model.trim="item.maskDescription"
          :rules="RulesManager.getRules(Rule.Required)"
        />
      </v-col>
    </v-row>

    <p class="required-text">* = {{ t('labels.required').toLowerCase() }}</p>
  </BaseEditor>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { useRulesManager } from '@/composables/RulesManager'
import { Rule } from '@/enums/Rule'
import { TableStoreId } from '@/enums/TableStoreId'
import type { ITableStore } from '@/interfaces/api/ITableStore'
import { MaskRecord } from '@/models/MaskRecord'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const store = TableStoreFactory.get(TableStoreId.Masks) as ITableStore
const RulesManager = useRulesManager()
const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS
const item = ref<MaskRecord>(new MaskRecord())

// REACTIVE VARIABLES

// COMPUTED PROPERTIES

const title = computed((): string => {
  if (item.value.id) {
    return t('dialogs.headers.editMask')
  }
  return t('dialogs.headers.createMask')
})

// WATCHERS
watch(
  (): any => store.selectedItem,

  (value) => {
    item.value = { ...value }
  }
)

// LIFECYCLE HOOKS

// FUNCTIONS
const saveItem = async () => {
  await store.save({ ...item.value })
}

const updateDescription = () => {
  if (item.value.autoDescription) {
    const pieces = []
    if (item.value.maskManufacturer) {
      pieces.push(item.value.maskManufacturer)
    }
    if (item.value.maskModel) {
      pieces.push(item.value.maskModel)
    }
    if (item.value.maskStyle) {
      pieces.push(item.value.maskStyle)
    }
    pieces.push(`[${item.value.ffPassLevel}]`)
    item.value.maskDescription = pieces.join(' ')
  }
}
</script>

<style lang="scss" scoped>
.n95-hint {
  padding: 0;
  margin: 0 0 1rem 0;
  font-size: 0.75rem;
}
</style>

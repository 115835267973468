/**
 * Error that is thrown when a import value is not a valid number.
 */
export class ImportNumberError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'ImportNumberError'
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

import { TableStoreId } from '@/enums/TableStoreId'

const _searchFields = new Map<TableStoreId, Array<string>>()

const _initialize = () => {
  _searchFields.set(TableStoreId.MedicalClearances, ['clearanceDate', 'expiration', 'restrictions'])
  _searchFields.set(TableStoreId.DailyChecks, ['serialNumber', 'particle'])
  _searchFields.set(TableStoreId.Devices, ['name', 'serialNumber', 'model', 'location', 'planInfo'])
  _searchFields.set(TableStoreId.Masks, [
    'maskManufacturer',
    'maskModel',
    'maskStyle',
    'approval',
    'maskDescription'
  ])
  _searchFields.set(TableStoreId.People, [
    'firstName',
    'lastName',
    'idNumber',
    'company',
    'location',
    'note',
    'custom1Data',
    'custom2Data',
    'custom3Data',
    'custom4Data',
    'email'
  ])
}

const getSearchFields = (tableStoreId: TableStoreId): Array<string> => {
  if (_searchFields.size === 0) {
    _initialize()
  }
  const fields = _searchFields.get(tableStoreId)
  if (fields) {
    return fields
  }
  return []
}

export { getSearchFields }

import { TableStoreId } from '@/enums/TableStoreId'
import type { ITableHeader } from '@/interfaces/ITableHeader'
import type { Composer } from 'vue-i18n'
import { getTableHeaders as getMedicalClearanceHeaders } from './HeadersClearance'
import { getTableHeaders as getCustomFieldsHeaders } from './HeadersCustomFields'
import { getTableHeaders as getDailyChecksHeaders } from './HeadersDailyChecks'
import { getTableHeaders as getDeviceHeaders } from './HeadersDevices'
import { getTableHeaders as getFitTestHeaders } from './HeadersFitTests'
import { getTableHeaders as getMaskHeaders } from './HeadersMasks'
import { getTableHeaders as getPeopleHeaders } from './HeadersPeople'

const _getTableHeader = async (
  tableStoreId: TableStoreId,
  i18n: Composer<{}, {}, {}, string, never, string>
) => {
  switch (tableStoreId) {
    case TableStoreId.CustomFields: {
      return getCustomFieldsHeaders(i18n)
    }
    case TableStoreId.DailyChecks: {
      return getDailyChecksHeaders(i18n)
    }
    case TableStoreId.Devices: {
      return getDeviceHeaders(i18n)
    }
    case TableStoreId.FitTests: {
      return getFitTestHeaders(i18n)
    }
    case TableStoreId.Masks: {
      return getMaskHeaders(i18n)
    }
    case TableStoreId.MedicalClearances: {
      return getMedicalClearanceHeaders(i18n)
    }
    case TableStoreId.People: {
      const peopleHeaders = await getPeopleHeaders(i18n)
      return peopleHeaders
    }
  }
}

const getTableHeaders = async (
  tableStoreId: TableStoreId,
  i18n: Composer<{}, {}, {}, string, never, string>
): Promise<Array<ITableHeader>> => {
  const tableHeaders = await _getTableHeader(tableStoreId, i18n)
  if (tableHeaders) {
    return tableHeaders
  }
  return []
}

export { getTableHeaders }

import { CalendarId } from '@/enums/CalendarId'
import { CalendarView } from '@/enums/CalendarView'
import { EventStatus } from '@/enums/EventStatus'
import { ProgramCategory } from '@/enums/ProgramCategory'
import { StoreId } from '@/enums/StoreId'
import type { ICalendarRange } from '@/interfaces/ICalendarRange'
import type { EventRecord } from '@/models/EventRecord'
import dayjs, { type ManipulateType } from 'dayjs'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export const useCalendarStore = defineStore(
  StoreId.Calendar,
  () => {
    // STORES, IMPORTS, & COMPOSABLES
    const i18n = useI18n({ useScope: 'global' })

    // REACTIVE VARIABLES
    const currentView = ref<string>()
    const enableClearance = ref<boolean>(true)
    const enableFitTests = ref<boolean>(true)
    const enableTraining = ref<boolean>(true)
    const enableTentative = ref<boolean>(true)
    const range = ref<ICalendarRange>()
    const selectedDate = ref<string>(dayjs().format('YYYY-MM-DD'))
    const showSubscribeDialog = ref<boolean>(false)

    // WATCHERS

    // COMPUTED
    const selectedDateValue = computed({
      get() {
        return dayjs(selectedDate.value).toDate()
      },
      set(newValue) {
        selectedDate.value = dayjs(newValue).format('YYYY-MM-DD')
      }
    })

    const views = computed(() => {
      return [
        { title: i18n.t('labels.calendar.day'), value: CalendarView.Day },
        { title: i18n.t('labels.calendar.week'), value: CalendarView.Week },
        { title: i18n.t('labels.calendar.month'), value: CalendarView.Month },
        { title: i18n.t('labels.calendar.agenda'), value: CalendarView.Agenda }
      ]
    })

    // FUNCTIONS
    const getCalendarId = (event: EventRecord): CalendarId => {
      if (event.status === EventStatus.Tentative) {
        return CalendarId.Tentative
      }
      switch (event.category) {
        case ProgramCategory.FitTest: {
          return CalendarId.Testing
        }
        case ProgramCategory.MedicalClearance: {
          return CalendarId.Clearance
        }
        case ProgramCategory.Training: {
          return CalendarId.Training
        }
      }
      return CalendarId.Unknown
    }

    const getDateTitle = () => {
      switch (currentView.value) {
        case CalendarView.Agenda:
        case CalendarView.Week:
        case CalendarView.Month: {
          if (range.value) {
            return `${dayjs(range.value.start).format('ll')} ${i18n.t('labels.to')} ${dayjs(range.value.end).format('ll')}`
          }
          return ''
        }
        case CalendarView.Day: {
          return dayjs(selectedDate.value).format('ll')
        }
        default: {
          return ''
        }
      }
    }

    const getEventTitle = (event: EventRecord): string => {
      let title = ''
      switch (event.category) {
        case ProgramCategory.FitTest: {
          title = i18n.t('labels.categories.testing')
          break
        }
        case ProgramCategory.MedicalClearance: {
          title = i18n.t('labels.categories.clearance')
          break
        }
        case ProgramCategory.Training: {
          title = i18n.t('labels.categories.training')
          break
        }
      }
      if (event.status === EventStatus.Tentative) {
        title += ` (${i18n.t('labels.categories.tentative')})`
      }
      return title
    }

    const getDateUnit = (): ManipulateType | undefined => {
      if (currentView.value === CalendarView.Agenda || currentView.value === CalendarView.Month) {
        return 'month'
      }
      return currentView.value as ManipulateType
    }

    return {
      currentView,
      enableClearance,
      enableFitTests,
      enableTraining,
      enableTentative,
      range,
      selectedDate,
      selectedDateValue,
      showSubscribeDialog,
      views,
      getCalendarId,
      getDateTitle,
      getDateUnit,
      getEventTitle
    }
  },
  {
    persist: {
      storage: localStorage,
      pick: [
        'currentView',
        'enableClearance',
        'enableFitTests',
        'enableTraining',
        'enableTentative',
        'selectedDate'
      ]
    }
  }
)

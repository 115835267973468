<template>
  <BasePage>
    <v-tabs
      v-model="settingsStore.selectedTab"
      align-tabs="start"
      density="compact"
      color="primary"
      :grow="false"
    >
      <v-tab :key="SettingsTab.Account" :value="SettingsTab.Account">
        <v-icon icon="mdi-cogs"></v-icon>{{ t('tabs.settings.account') }}</v-tab
      >
      <v-tab :key="SettingsTab.CustomFields" :value="SettingsTab.CustomFields">
        <v-icon icon="mdi-account-clock"></v-icon>{{ t('tabs.settings.customFields') }}</v-tab
      >
      <v-tab :key="SettingsTab.Masks" :value="SettingsTab.Masks">
        <v-icon icon="mdi-face-mask"></v-icon>{{ t('tabs.settings.masks') }}</v-tab
      >
      <v-tab :key="SettingsTab.Devices" :value="SettingsTab.Devices">
        <v-icon icon="mdi-package"></v-icon>{{ t('tabs.settings.devices') }}</v-tab
      >
      <v-tab :key="SettingsTab.Import" :value="SettingsTab.Import">
        <v-icon icon="mdi-import"></v-icon>{{ t('tabs.settings.import') }}</v-tab
      >
      <v-tab :key="SettingsTab.Templates" :value="SettingsTab.Templates">
        <v-icon icon="mdi-email-edit"></v-icon>{{ t('tabs.settings.templates') }}</v-tab
      >
    </v-tabs>
    <v-divider class="tab-divider"></v-divider>
    <v-tabs-window class="tab-content" v-model="settingsStore.selectedTab">
      <v-tabs-window-item :key="SettingsTab.Account" :value="SettingsTab.Account">
        <AccountView />
      </v-tabs-window-item>
      <v-tabs-window-item :key="SettingsTab.CustomFields" :value="SettingsTab.CustomFields">
        <CustomFieldsView />
      </v-tabs-window-item>
      <v-tabs-window-item :key="SettingsTab.Masks" :value="SettingsTab.Masks">
        <MasksView />
      </v-tabs-window-item>
      <v-tabs-window-item :key="SettingsTab.Devices" :value="SettingsTab.Devices">
        <DevicesView />
      </v-tabs-window-item>
      <v-tabs-window-item :key="SettingsTab.Import" :value="SettingsTab.Import">
        <ImportView />
      </v-tabs-window-item>
      <v-tabs-window-item :key="SettingsTab.Templates" :value="SettingsTab.Templates">
        <EmailTemplatesView />
      </v-tabs-window-item>
    </v-tabs-window>
  </BasePage>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { SettingsTab } from '@/enums/SettingsTab'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import AccountView from '@/views/settings/AccountView.vue'
import CustomFieldsView from '@/views/settings/CustomFieldsView.vue'
import DevicesView from '@/views/settings/DevicesView.vue'
import ImportView from '@/views/settings/ImportView.vue'
import MasksView from '@/views/settings/MasksView.vue'
import { useI18n } from 'vue-i18n'
import EmailTemplatesView from './settings/EmailTemplatesView.vue'
const { t } = useI18n({ useScope: 'global' })
const settingsStore = useSettingsStore()

// PROPS & EMITS

// REACTIVE VARIABLES

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// FUNCTIONS
</script>

<style lang="scss" scoped>
.tab-content {
  margin-top: 1em;
  margin-left: 1em;
}
</style>

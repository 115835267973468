import { TableRecord } from '@/models/TableRecord'

export class MaskRecord extends TableRecord {
  public maskManufacturer: string | null = null
  public maskModel: string | null = null
  public maskStyle: string | null = null
  public approval: string | null = ''
  public ffPassLevel: number = 0
  public n95: boolean = false
  public maskDescription: string = ''
  public autoDescription: boolean = true
  public reserved1: string = '0'
  public reserved2?: string | null = null
  public reserved3?: string | null = null
  public reserved4?: string | null = null
}

<template>
  <BaseEditor
    :table-store-id="TableStoreId.CustomFields"
    :max-width="600"
    v-bind="$attrs"
    :title="title"
    :disable-save="isSaveDisabled"
    @save-item="saveItem"
  >
    <v-row>
      <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6">
        <PrimarySwitch
          :label="t('fields.customFields.required')"
          :true-value="1"
          :false-value="0"
          v-model="item.required"
        />
        <PrimarySwitch
          v-if="item.combo === 1"
          :label="t('fields.customFields.autoComplete')"
          v-model="item.autoComplete"
        />
        <PrimarySwitch
          :label="t('fields.customFields.allowDashboardFiltering')"
          v-model="item.allowFiltering"
        />
        <PrimaryTextField
          required
          :disabled="item.enabled === 0"
          :label="t('fields.customFields.labelName')"
          :maxlength="20"
          v-model.trim="item.labelName"
          :rules="
            RulesManager.getRules([
              {
                key: Rule.DuplicateString,
                payload: labelNames,
                errorSlug: 'validation.duplicateOption'
              },
              Rule.Required
            ])
          "
        />
        <PrimarySelect
          required
          v-model="item.combo"
          v-on:update:model-value="onTypeChange"
          :label="t('fields.customFields.type')"
          :items="[
            { title: t('fields.customFields.values.textInput'), value: 1 },
            { title: t('fields.customFields.values.dropdown'), value: 0 }
          ]"
        ></PrimarySelect>
      </v-col>
      <v-col v-if="item.combo === 0" cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6">
        <PrimaryTextField
          style="margin-bottom: 1rem"
          :disabled="options.length >= MAX_OPTIONS"
          :label="t('fields.customFields.addOption')"
          :maxlength="20"
          v-model.trim="newOption"
          @keyup.enter="addOption"
          :rules="
            RulesManager.getRules({
              key: Rule.DuplicateString,
              payload: options,
              errorSlug: 'validation.duplicateOption'
            })
          "
          :hint="t('fields.customFields.hint', { max: MAX_OPTIONS - options.length })"
          :persistent-hint="true"
        >
          <template v-slot:append-inner
            ><v-icon
              :disabled="!isDefined(newOption?.trim())"
              @click="addOption"
              :color="isDefined(newOption?.trim()) ? 'primary' : 'secondary'"
              icon="mdi-plus"
          /></template>
        </PrimaryTextField>
        <v-chip
          class="ma-1"
          color="primary"
          size="small"
          @click="removeOption(value)"
          :model-value="true"
          v-for="(value, index) in options"
          :key="index"
          label
          density="compact"
        >
          {{ value }}
          <v-icon :color="Colors.mainPrimary" icon="mdi-close-circle" end></v-icon>
        </v-chip>
      </v-col>
    </v-row>
    <p class="required-text">* = {{ t('labels.required').toLowerCase() }}</p>
  </BaseEditor>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { useRulesManager } from '@/composables/RulesManager'
import { isDefined } from '@/composables/utils'
import { Rule } from '@/enums/Rule'
import { TableStoreId } from '@/enums/TableStoreId'
import type { ICustomFieldsStore } from '@/interfaces/api/ICustomFieldsStore'
import { CustomFieldRecord } from '@/models/CustomFieldRecord'
import { useCustomFieldsStore } from '@/stores/db/CustomFieldsStore'
import { useDashboardStore } from '@/stores/ui/DashboardStore'
import Colors from '@/styles/_colors.module.scss'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
const MAX_OPTIONS = 10

const store = useCustomFieldsStore() as unknown as ICustomFieldsStore
const dashboardStore = useDashboardStore()
const RulesManager = useRulesManager()
const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS

// REACTIVE VARIABLES
const item = ref<CustomFieldRecord>(new CustomFieldRecord())

const newOption = ref<string>()

// COMPUTED PROPERTIES
const labelNames = computed(() => {
  return store.items
    .map((customField) => {
      return customField.labelName
    })
    .filter((labelName) => {
      return labelName !== (store.selectedItem as CustomFieldRecord)?.labelName
    })
})

const options = computed((): Array<string> => {
  const keys = Object.keys(item.value)
    .filter((key) => {
      return key.startsWith('option')
    })
    .sort()

  let results: Array<string> = []

  keys.forEach((key) => {
    if (isDefined(item.value[key])) {
      results.push(item.value[key])
    }
  })
  results.sort()
  return results
})

const title = computed((): string => {
  if (item.value.id) {
    return t('dialogs.headers.editCustomField')
  }
  return t('dialogs.headers.createCustomField')
})

const isSaveDisabled = computed((): boolean => {
  if (
    item.value.combo === 0 &&
    options.value.length === 0 &&
    (!isDefined(newOption.value) || newOption.value?.trim().length === 0)
  ) {
    return true
  }
  return false
})

// WATCHERS
watch(
  (): any => store.selectedItem,

  (value) => {
    item.value = { ...value }
  }
)

// LIFECYCLE HOOKS

// FUNCTIONS
const addOption = () => {
  if (newOption.value) {
    newOption.value = newOption.value.trim()
    if (newOption.value.length === 0) {
      return
    }
  }
  const keys = Object.keys(item.value)
    .filter((key) => {
      return key.startsWith('option')
    })
    .sort()
  keys.some((key) => {
    if (!isDefined(item.value[key])) {
      item.value[key] = newOption.value
      newOption.value = undefined
      return true
    }
    return false
  })
}

const removeOption = (value: string) => {
  const keys = Object.keys(item.value)
    .filter((key) => {
      return key.startsWith('option')
    })
    .sort()

  keys.some((key) => {
    if (item.value[key] === value) {
      item.value[key] = null
      return true
    }
    return false
  })
}

const formatOptions = (item: CustomFieldRecord) => {
  const finalOptions = [...options.value]
  for (let counter = 0; counter < MAX_OPTIONS; counter++) {
    if (isDefined(finalOptions[counter])) {
      item[`option${counter}`] = finalOptions[counter]
    } else {
      item[`option${counter}`] = null
    }
  }
}

const saveItem = async () => {
  const newItem = { ...item.value }
  // If the user is entering a new option assume the want to add it.
  if (isDefined(newOption.value)) {
    addOption()
  }
  formatOptions(newItem)
  await store.save(newItem)

  // If filtering is disabled, clear out any existing filters.
  if (!newItem.allowFiltering && newItem.id) {
    const fieldDefinition = store.getFieldDefinition(newItem.id)
    if (fieldDefinition) {
      dashboardStore.filters[fieldDefinition.valueField] = {}
    }
  }
}

const onTypeChange = () => {
  if (item.value.combo === 0) {
    item.value.autoComplete = false
  }
}
</script>

<style lang="scss" scoped>
.max-message {
  padding: 0;
  margin: 0;
  font-size: 0.75rem;
}
</style>

<template>
  <div>
    <ScheduleXCalendar :calendar-app="calendarApp">
      <template v-slot:headerContent>
        <PrimarySelect
          :width="100"
          v-model="store.currentView"
          :items="store.views"
          :hide-details="true"
        />
        <v-icon style="margin-left: 1rem; margin-top: 0.5rem" @click="goBackward"
          >mdi-chevron-left</v-icon
        >
        <v-icon style="margin-top: 0.5rem; margin-right: 1rem" @click="goForward"
          >mdi-chevron-right</v-icon
        >
        <ButtonIcon
          :disabled="store.selectedDate === dayjs().format('YYYY-MM-DD')"
          style="margin-top: 0.25rem"
          @click="gotoToday"
          :text="t('buttons.today')"
          icon="mdi-calendar-today"
        />
        <v-menu location="bottom" :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <ButtonIcon
              style="margin-top: 0.25rem"
              v-bind="props"
              :text="t('buttons.filter')"
              icon="mdi-calendar-filter"
            />
          </template>
          <div class="menu">
            <strong>{{ t('labels.calendar.show') }}:</strong>
            <PrimarySwitch
              v-model="store.enableFitTests"
              color="primary"
              density="compact"
              :hide-details="true"
              :label="t('labels.categories.testing')"
              @update:model-value="onFilterChanged"
            ></PrimarySwitch>
            <PrimarySwitch
              v-model="store.enableClearance"
              v-if="settingsStore.globals.clearance.enabled"
              color="primary"
              density="compact"
              :hide-details="true"
              :label="t('labels.categories.clearance')"
              @update:model-value="onFilterChanged"
            ></PrimarySwitch>
            <PrimarySwitch
              v-model="store.enableTraining"
              v-if="settingsStore.globals.training.enabled"
              color="primary"
              density="compact"
              :hide-details="true"
              :label="t('labels.categories.training')"
              @update:model-value="onFilterChanged"
            ></PrimarySwitch>
            <PrimarySwitch
              v-model="store.enableTentative"
              density="compact"
              color="primary"
              :hide-details="true"
              :label="t('labels.categories.tentative')"
              @update:model-value="onFilterChanged"
            ></PrimarySwitch>
          </div>
        </v-menu>
        <ButtonIcon
          style="margin-top: 0.25rem"
          @click="onSubscribe"
          :text="t('buttons.subscribe')"
          icon="mdi-calendar-export"
        />
        <v-spacer />
        <h3 style="margin-top: 0.6rem; display: inline-flex">{{ store.getDateTitle() }}</h3>
      </template>
    </ScheduleXCalendar>
    <DialogSubscribe
      v-model="store.showSubscribeDialog"
      :title="t('dialogs.headers.subscribe')"
      @close="hideSubscribe"
    />
  </div>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { CalendarId } from '@/enums/CalendarId'
import { EventStatus } from '@/enums/EventStatus'
import { ProgramCategory } from '@/enums/ProgramCategory'
import type { ICalendarRange } from '@/interfaces/ICalendarRange'
import { EventRecord } from '@/models/EventRecord'
import { useCalendarStore } from '@/stores/ui/CalendarStore'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import Colors from '@/styles/_colors.module.scss'
import {
  createCalendar,
  createViewDay,
  createViewMonthAgenda,
  createViewMonthGrid,
  createViewWeek
} from '@schedule-x/calendar'
import { createCalendarControlsPlugin } from '@schedule-x/calendar-controls'
import { createScrollControllerPlugin } from '@schedule-x/scroll-controller'
import { ScheduleXCalendar } from '@schedule-x/vue'
import dayjs from 'dayjs'
import { nextTick, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })
const settingsStore = useSettingsStore()
const store = useCalendarStore()

// NON-REACTIVE VARIABLES
let initialized = false

// Scrolls to the current hour automatically.
const scrollController = createScrollControllerPlugin({
  initialScroll: dayjs().format('HH:00')
})

// Interface to update the calendar from Vue.
const calendarControls = createCalendarControlsPlugin()

// The main calendar configuration. Includes multiple calendar types.
const calendarApp = createCalendar({
  plugins: [calendarControls, scrollController],
  views: [createViewWeek(), createViewDay(), createViewMonthGrid(), createViewMonthAgenda()],
  selectedDate: store.selectedDate,
  locale: settingsStore.localeCode,
  defaultView: store.currentView,
  isResponsive: false,
  skipValidation: true,
  weekOptions: {
    gridHeight: 1500,
    nDays: settingsStore.globals.calendar.totalWeekdays
  },
  firstDayOfWeek: settingsStore.globals.calendar.firstDayOfWeek,
  callbacks: {
    // Fires when an event is clicked.
    onEventClick(calendarEvent) {
      showEventDetails(calendarEvent)
    },
    // Fires when a user clicks a date on the Agenda view.
    onClickAgendaDate(date) {
      store.selectedDate = date
    },
    // Fires when the calendar first renders.
    onRender($app) {
      if (!initialized) {
        initialized = true
        // initializeWatchers()
      }
      const range = $app.calendarState.range.value
      if (range) {
        onCalendarChanged(range)
      }
    },
    onRangeUpdate: (range) => {
      onCalendarChanged(range)
    }
  },
  calendars: {
    [CalendarId.Tentative]: {
      colorName: CalendarId.Tentative,
      lightColors: {
        main: '#afafaf',
        container: '#f7f7f7',
        onContainer: Colors.textPrimary
      }
    },
    [CalendarId.Testing]: {
      colorName: CalendarId.Testing,
      lightColors: {
        main: '#4baaef',
        container: '#edf6fd',
        onContainer: Colors.textPrimary
      }
    },
    [CalendarId.Training]: {
      colorName: CalendarId.Training,
      lightColors: {
        main: '#dc338c',
        container: '#f4e3ec',
        onContainer: Colors.textPrimary
      }
    },
    [CalendarId.Clearance]: {
      colorName: CalendarId.Clearance,
      lightColors: {
        main: '#60e888',
        container: '#e9f6ed',
        onContainer: Colors.textPrimary
      }
    }
  },
  events: []
})

// PROPS & EMITS
const props = defineProps({
  events: {
    type: Array<EventRecord>,
    default: () => {
      return []
    }
  }
})

const emit = defineEmits<{
  (e: 'change', payload: ICalendarRange): void
  (e: 'eventSelected', id: number): void
}>()

// WATCHERS
watch(
  (): any => store.currentView,
  () => {
    if (store.currentView) {
      calendarControls.setView(store.currentView)
    }
  }
)

watch(
  (): any => props.events,
  () => {
    updateCalendar()
  },
  { deep: true }
)

watch(
  (): any => store.selectedDate,
  () => {
    calendarControls.setDate(store.selectedDate)
  }
)

watch(
  (): any => settingsStore.globals.calendar.firstDayOfWeek,
  () => {
    calendarControls.setFirstDayOfWeek(settingsStore.globals.calendar.firstDayOfWeek)
  }
)

watch(
  (): any => settingsStore.globals.calendar.totalWeekdays,
  () => {
    const weekOptions = calendarControls.getWeekOptions()
    weekOptions.nDays = settingsStore.globals.calendar.totalWeekdays
    calendarControls.setWeekOptions(weekOptions)
  }
)

watch(
  (): any => settingsStore.localeCode,
  (newValue) => {
    calendarControls.setLocale(newValue)
    nextTick().then(() => {
      updateCalendar()
    })
  }
)

// REACTIVE VARIABLES

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS
onMounted(() => {
  if (!settingsStore.globals.clearance.enabled) {
    store.enableClearance = false
  }
  if (!settingsStore.globals.training.enabled) {
    store.enableTraining = false
  }
})

// FUNCTIONS
const onCalendarChanged = (newRange: ICalendarRange) => {
  store.range = newRange
  emit('change', store.range)
  store.selectedDate = calendarControls.getDate()
  store.currentView = calendarControls.getView()
}

const onFilterChanged = () => {
  updateCalendar()
}

const gotoToday = () => {
  store.selectedDate = dayjs().format('YYYY-MM-DD')
}

const goForward = () => {
  store.selectedDate = dayjs(store.selectedDate).add(1, store.getDateUnit()).format('YYYY-MM-DD')
}

const goBackward = () => {
  store.selectedDate = dayjs(store.selectedDate)
    .subtract(1, store.getDateUnit())
    .format('YYYY-MM-DD')
}

const hideSubscribe = () => {
  store.showSubscribeDialog = false
}

const showEventDetails = (calendarEvent: any) => {
  emit('eventSelected', calendarEvent.id)
}

const onSubscribe = () => {
  store.showSubscribeDialog = true
}

/**
 * Update calendar with events based on the current date/time range.
 */
const updateCalendar = () => {
  // Filter by category and tentative.
  const filteredEvents = props.events.filter((event: EventRecord) => {
    if (
      store.enableFitTests &&
      event.category === ProgramCategory.FitTest &&
      (store.enableTentative || event.status !== EventStatus.Tentative)
    ) {
      return true
    }
    if (
      store.enableClearance &&
      event.category === ProgramCategory.MedicalClearance &&
      (store.enableTentative || event.status !== EventStatus.Tentative)
    ) {
      return true
    }
    if (
      store.enableTraining &&
      event.category === ProgramCategory.Training &&
      (store.enableTentative || event.status !== EventStatus.Tentative)
    ) {
      return true
    }
    return false
  })

  // Convert EventRecords to schedule-x calendar format.
  const calendarEvents = filteredEvents.map((event: EventRecord) => {
    return {
      id: event.id,
      calendarId: store.getCalendarId(event),
      title: store.getEventTitle(event),
      decription: '',
      location: event.location,
      start: dayjs(event.dtStart).format('YYYY-MM-DD HH:mm'),
      end: dayjs(event.dtEnd).format('YYYY-MM-DD HH:mm'),
      people: [],
      _options: {
        disableDND: true,
        disableResize: true
      }
    }
  })

  // Update calendar events.
  calendarApp.events.set(calendarEvents as any)
}
</script>

<style lang="scss" scoped>
.menu {
  margin: 0.5rem 0 0 0;
  padding: 1rem;
  background-color: white;
  border-radius: 4px;
  border: grey 1px solid;
}
</style>

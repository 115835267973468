<template>
  <v-snackbar
    v-model="snackbarStore.show"
    @update:model-value="close()"
    :timeout="snackbarStore.current?.timeout"
    v-bind="$attrs"
    fixed
    location="bottom"
    center
  >
    <v-row>
      <v-col style="max-width: 3rem">
        <v-icon :color="icons[snackbarStore.current?.type].color" size="22">{{
          icons[snackbarStore.current?.type].icon
        }}</v-icon>
      </v-col>
      <v-col style="width: 100%">
        <div>{{ snackbarStore.current?.text }}</div>
      </v-col>
    </v-row>
    <template v-slot:actions>
      <v-btn variant="text" @click="close">{{ buttonText }}</v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { MessageType } from '@/enums/MessageType'
import { useSnackbarStore } from '@/stores/ui/SnackbarStore'
import Colors from '@/styles/_colors.module.scss'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const snackbarStore = useSnackbarStore()

// PROPS & EMITS
defineProps({
  buttonText: {
    type: String,
    default: () => {
      const i18n = useI18n({ useScope: 'global' })
      const t = i18n.t
      return t('snackbar.defaultButtonText')
    }
  },
  messages: {
    type: Array,
    default: () => {
      return []
    }
  },
  type: { type: String, default: MessageType.Info }
})

// REACTIVE VARIABLES
let icons = ref<Record<string, { icon: string; color: string }>>({
  [MessageType.Info]: {
    icon: 'mdi-information-outline',
    color: Colors.mainPrimary
  },
  [MessageType.Success]: {
    icon: 'mdi-check-circle-outline',
    color: Colors.accentSuccess
  },
  [MessageType.Warning]: {
    icon: 'mdi-alert',
    color: Colors.accentWarning
  },
  [MessageType.Error]: {
    icon: 'mdi-alert-circle',
    color: Colors.accentError
  },
  [MessageType.DatabaseError]: {
    icon: 'mdi-database-alert',
    color: Colors.accentError
  }
})

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// FUNCTIONS
const close = () => {
  snackbarStore.close()
}
</script>

<style lang="scss" scoped></style>

<template>
  <div style="margin-bottom: 0.75rem">
    <div class="display-label">{{ label }}</div>
    <div>
      <div class="display-value">
        {{ isDefined(value) ? value : _defaultValue
        }}<v-icon class="clear-icon" @click="onClick">mdi-close-circle</v-icon>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { isDefined } from '@/composables/utils'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS
const emit = defineEmits<{
  (e: 'clear'): void
}>()

const props = defineProps({
  defaultValue: { type: String },
  label: { type: String },
  value: { type: [String, Number, null] }
})

// REACTIVE VARIABLES
const _defaultValue = ref<string>(t('labels.dash'))
// COMPUTED PROPERTIES

// WATCHERS
watch(
  (): any => props.defaultValue,
  (value) => {
    _defaultValue.value = value
  }
)

// LIFECYCLE HOOKS

// FUNCTIONS
const onClick = () => {
  emit('clear')
}
</script>

<style lang="scss" scoped>
.clear-icon {
  margin: 0;
  padding-bottom: 0.15rem;
  margin-left: 0.25rem;
  color: $color-accent-error;
  font-size: 1rem;
}
.main-value {
  vertical-align: middle;
}
</style>

import { EmailTemplate } from '@/enums/EmailTemplate'
import { EndPoint } from '@/enums/EndPoint'
import { LocaleDefinition } from '@/enums/LocaleDefinition'
import { MessageType } from '@/enums/MessageType'
import { SettingsTab } from '@/enums/SettingsTab'
import { StatusField } from '@/enums/StatusField'
import { StoreId } from '@/enums/StoreId'
import type { IGlobalSettings } from '@/interfaces/IGlobalSettings'
import type { IItemsPerPageOption } from '@/interfaces/IItemsPerPageOption'
import { useAPIStore } from '@/stores/db/ApiStore'
import dayjs from 'dayjs'
import { cloneDeep, isEqual, merge } from 'lodash'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLocale } from 'vuetify'
import { useSnackbarStore } from './SnackbarStore'

export const useSettingsStore = defineStore(
  StoreId.Settings,
  () => {
    // STORES, IMPORTS, & COMPOSABLES
    const i18n = useI18n({ useScope: 'global' })
    const apiStore = useAPIStore()
    const snackbarStore = useSnackbarStore()
    const vuetifyLocale = useLocale()

    // REACTIVE VARIABLES
    const localeDefinitions = ref<Array<LocaleDefinition>>([
      LocaleDefinition.German,
      LocaleDefinition.English,
      LocaleDefinition.Spanish,
      LocaleDefinition.French,
      LocaleDefinition.Japanese,
      LocaleDefinition.Korean,
      LocaleDefinition.Portuguese,
      LocaleDefinition.ChineseSimplified,
      LocaleDefinition.ChineseTraditional
    ])

    // Stored in database
    const defaultGlobals = {
      calendar: {
        testDuration: 15,
        clearanceDuration: 30,
        trainingDuration: 30,
        totalWeekdays: 7,
        firstDayOfWeek: 1
      },
      emailTemplates: {
        [EmailTemplate.AppointmentTesting]: {
          enabled: true,
          subject: 'Upcoming Fit Test Appointment',
          content: '<strong>Testing 1</strong><p>Hello #1</p>'
        },
        [EmailTemplate.DueSoonTesting]: {
          enabled: true,
          subject: 'Your Next Fit Test is Due Soon',
          content: '<strong>Testing 2</strong><p>Hello #2</p>'
        },
        [EmailTemplate.OverdueTesting]: {
          enabled: true,
          subject: 'Fit Test is Overdue',
          content: '<strong>Testing 3</strong><p>Hello #3</p>'
        },
        [EmailTemplate.AppointmentClearance]: {
          enabled: true,
          subject: 'Upcoming Medical Clearance Appointment',
          content: '<strong>Testing 4</strong><p>Hello #4</p>'
        },
        [EmailTemplate.DueSoonClearance]: {
          enabled: true,
          subject: 'Medical Clearance is Due Soon',
          content: '<strong>Testing 5</strong><p>Hello #5</p>'
        },
        [EmailTemplate.OverdueClearance]: {
          enabled: true,
          subject: 'Medical Clearance is Overdue',
          content: '<strong>Testing 6</strong><p>Hello #6</p>'
        },
        [EmailTemplate.AppointmentTraining]: {
          enabled: true,
          subject: 'Upcoming Respirator Training Appointment',
          content: '<strong>Testing 7</strong><p>Hello #7</p>'
        },
        [EmailTemplate.DueSoonTraining]: {
          enabled: true,
          subject: 'Respirator Training is Due Soon',
          content: '<strong>Testing 8</strong><p>Hello #8</p>'
        },
        [EmailTemplate.OverdueTraining]: {
          enabled: true,
          subject: 'Respirator Training is Overdue',
          content: '<strong>Testing 9</strong><p>Hello #9</p>'
        }
      },
      clearance: {
        daysToExpire: 365,
        daysWarning: 30,
        enabled: true,
        expires: true,
        enableScheduling: true,
        reminders: {
          maxOverdue: 3,
          appointmentDaysBefore: 1
        }
      },
      devices: {
        daysToExpireCalibration: 365,
        daysToExpirePlan: 365,
        daysWarningCalibration: 60,
        daysWarningPlan: 60
      },
      testing: {
        daysToExpire: 365,
        daysWarning: 30,
        expires: true,
        enableScheduling: true,
        maxOverdue: 3,
        reminders: {
          maxOverdue: 3,
          appointmentDaysBefore: 1
        }
      },
      training: {
        daysToExpire: 365,
        daysWarning: 30,
        enabled: true,
        expires: true,
        enableScheduling: true,
        reminders: {
          maxOverdue: 3,
          appointmentDaysBefore: 1
        }
      },
      defaultLocaleCode: LocaleDefinition.English.code
    }
    const disabledHeaders = ref<Record<string, Array<string>>>({})
    const itemsPerPageOptions = ref<Array<IItemsPerPageOption>>([
      { title: i18n.t('tables.five'), value: 5 },
      { title: i18n.t('tables.ten'), value: 10 },
      { title: i18n.t('tables.fifteen'), value: 15 },
      { title: i18n.t('tables.twenty'), value: 20 },
      { title: i18n.t('tables.twentyFive'), value: 25 },
      { title: i18n.t('tables.fifty'), value: 50 },
      { title: i18n.t('tables.oneHundred'), value: 100 }
    ])
    const isDirty = ref<boolean>(false)
    const globals = ref<IGlobalSettings>(cloneDeep(defaultGlobals))
    const id = ref<number | undefined>()
    const isLoading = ref<boolean>()
    const localeCode = ref<string>()
    const minTableCount = ref<number>(10)
    const oldGlobals = ref<IGlobalSettings>(cloneDeep(defaultGlobals))
    const sapAccountId = ref<string>('')
    const selectedTab = ref<string>(SettingsTab.Account)

    // WATCHERS
    watch(localeCode, () => {
      if (localeCode.value) {
        i18n.locale.value = localeCode.value
        vuetifyLocale.current.value = locale.value.vuetify
        dayjs.locale(locale.value.language)
      }
    })

    watch(
      globals,
      (value) => {
        if (value && !localeCode.value) {
          localeCode.value = globals.value.defaultLocaleCode
        }
        isDirty.value = !isEqual(globals.value, oldGlobals.value)
      },
      { deep: true }
    )

    // COMPUTED
    const locale = computed(() => {
      const locale = localeDefinitions.value.find((locale) => {
        return locale.code === localeCode.value
      })

      if (!locale) {
        return LocaleDefinition.English
      }
      return locale
    })

    const languageOptions = computed(() => {
      return localeDefinitions.value.map((locale) => {
        return { title: locale.title, value: locale.code }
      })
    })

    // FUNCTIONS
    const cancel = () => {
      if (oldGlobals.value) {
        globals.value = cloneDeep(oldGlobals.value)
      }
    }

    const _getCategory = (statusField: StatusField) => {
      switch (statusField) {
        case StatusField.ClearanceExpiration: {
          return globals.value.clearance
        }
        case StatusField.TestDueDate: {
          return globals.value.testing
        }
        case StatusField.TrainingExpiration: {
          return globals.value.training
        }
      }
    }

    const getDaysWarning = (field: StatusField): number => {
      const category = _getCategory(field)
      if (category) {
        return category.daysWarning
      }
      return 30
    }

    const expires = (field: StatusField): boolean => {
      const category = _getCategory(field)
      if (category) {
        return category.expires
      }
      return true
    }

    const load = async () => {
      if (isLoading.value) {
        return
      }
      let response
      try {
        response = await apiStore.getMany(EndPoint.AccountSettings)
      } catch {
        snackbarStore.addMessage(i18n.t('warnings.loadGlobalSettings'), MessageType.Warning)
      }
      if (response) {
        if (!response.error && response.data?.count === 0) {
          await save()
        } else {
          let jsonGlobals
          try {
            id.value = response.data?.results[0]?.id
            sapAccountId.value = response.data?.results[0]?.sapAccountId
            jsonGlobals = JSON.parse(response.data?.results[0].globals)
            globals.value = merge(defaultGlobals, jsonGlobals)
            oldGlobals.value = cloneDeep(globals.value)
          } catch {
            snackbarStore.addMessage(i18n.t('warnings.loadGlobalSettings'), MessageType.Warning)
          }
        }
      }
      isLoading.value = true
    }

    const save = async () => {
      try {
        await apiStore.save(
          EndPoint.AccountSettings,
          {
            id: id.value,
            globals: JSON.stringify(globals.value)
          },
          false
        )
        oldGlobals.value = cloneDeep(globals.value)
        isDirty.value = false
      } catch {
        snackbarStore.addMessage(i18n.t('warnings.saveGlobalSettings'), MessageType.Warning)
      }
    }

    return {
      disabledHeaders,
      globals,
      isDirty,
      isLoading,
      itemsPerPageOptions,
      languageOptions,
      locale,
      localeCode,
      minTableCount,
      sapAccountId,
      selectedTab,
      cancel,
      expires,
      getDaysWarning,
      load,
      save
    }
  },
  {
    persist: {
      storage: localStorage,
      pick: ['disabledHeaders', 'localeCode']
    }
  }
)

<template>
  <v-form :fast-fail="false" @submit.prevent ref="form">
    <v-container class="ma-0 pa-0" style="position: relative" fluid>
      <v-row>
        <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3" xxl="3">
          <v-card class="card" :title="t('headers.localization')" variant="outlined">
            <v-container style="margin-top: -0.5rem">
              <PrimarySelect
                v-model="store.globals.defaultLocaleCode"
                :label="t('labels.defaultLanguage')"
                :items="store.languageOptions"
              ></PrimarySelect>
              <p style="margin: 0; padding: 0">{{ t('text.defaultLanguage') }}</p>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3" xxl="3">
          <v-card class="card" :title="t('headers.account.calendar')" variant="outlined">
            <v-container style="margin-top: -1.5rem">
              <PrimaryTextField
                required
                inputmode="numeric"
                :label="t('labels.settings.testingDuration')"
                min="0"
                max="99999"
                :maxlength="5"
                v-model.trim="store.globals.calendar.testDuration"
                :rules="RulesManager.getRules([Rule.ValidNumber, Rule.Required])"
              />
              <PrimaryTextField
                required
                inputmode="numeric"
                :label="t('labels.settings.clearanceDuration')"
                min="0"
                max="99999"
                :maxlength="5"
                v-model.trim="store.globals.calendar.clearanceDuration"
                :rules="RulesManager.getRules([Rule.ValidNumber, Rule.Required])"
              />
              <PrimaryTextField
                required
                inputmode="numeric"
                :label="t('labels.settings.trainingDuration')"
                min="0"
                max="99999"
                :maxlength="5"
                v-model.trim="store.globals.calendar.trainingDuration"
                :rules="RulesManager.getRules([Rule.ValidNumber, Rule.Required])"
              />
              <PrimaryTextField
                required
                inputmode="numeric"
                :label="t('labels.settings.totalWeekDays')"
                min="1"
                max="7"
                :maxlength="1"
                v-model.trim="store.globals.calendar.totalWeekdays"
                :rules="RulesManager.getRules([Rule.ValidNumber, Rule.Required])"
              />
              <PrimarySelect
                v-model="store.globals.calendar.firstDayOfWeek"
                :label="t('labels.settings.firstDayOfWeek')"
                :hide-details="true"
                :items="getWeekdays()"
              />
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3" xxl="3">
          <v-card class="card" :title="t('headers.account.training')" variant="outlined">
            <v-container style="margin-top: -1.5rem">
              <PrimarySwitch
                density="compact"
                :label="t('labels.settings.enableTraining')"
                v-model="store.globals.training.enabled"
              />
              <span v-if="store.globals.training.enabled">
                <PrimarySwitch
                  v-if="store.globals.training.enabled"
                  density="compact"
                  :label="t('labels.settings.trainingExpires')"
                  v-model="store.globals.training.expires"
                />
                <PrimarySwitch
                  density="compact"
                  :label="t('labels.settings.enableScheduling')"
                  v-model="store.globals.training.enableScheduling"
                />
              </span>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3" xxl="3">
          <v-card class="card" :title="t('headers.account.clearance')" variant="outlined">
            <v-container style="margin-top: -1.5rem">
              <PrimarySwitch
                density="compact"
                :label="t('labels.settings.enableClearance')"
                v-model="store.globals.clearance.enabled"
              />
              <span v-if="store.globals.clearance.enabled">
                <PrimarySwitch
                  v-if="store.globals.clearance.enabled"
                  density="compact"
                  :label="t('labels.settings.clearanceExpires')"
                  v-model="store.globals.clearance.expires"
                />
                <PrimarySwitch
                  density="compact"
                  :label="t('labels.settings.enableScheduling')"
                  v-model="store.globals.clearance.enableScheduling"
                />
              </span>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <div class="fixed-buttons d-flex justify-center align-center">
        <ButtonSecondary
          style="margin-right: 0.5rem"
          @click="store.cancel()"
          :loading="!store.isLoading"
          :disabled="!store.isDirty"
          :text="t('buttons.cancel')"
        />
        <ButtonPrimary
          @click="store.save()"
          :disabled="!store.isDirty || !form?.isValid"
          type="submit"
          :loading="!store.isLoading"
          :text="t('buttons.save')"
        />
      </div>
    </v-container>
  </v-form>
</template>

<script setup lang="ts">
import PrimarySelect from '@/components/inputs/PrimarySelect.vue'
import { useRulesManager } from '@/composables/RulesManager'
import { Rule } from '@/enums/Rule'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import dayjs from 'dayjs'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
const RulesManager = useRulesManager()

// STORES, IMPORTS, & COMPOSABLES
const { t } = useI18n({ useScope: 'global' })
const store = useSettingsStore()

// PROPS & EMITS

// REACTIVE VARIABLES
const form = ref<any>()

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// FUNCTIONS
const getWeekdays = () => {
  const valueMap = [7, 1, 2, 3, 4, 5, 6]
  const weekdays = dayjs.localeData().weekdays()
  return weekdays.map((weekday, index) => {
    return { title: weekday, value: valueMap[index] }
  })
}
</script>

<style lang="scss" scoped>
.top-line {
  border-top: 1px solid $color-dark-16;
}
.card {
  min-height: 23.1rem;
  margin-bottom: 1rem;
  border-color: $color-dark-16;
}
.sub-header {
  font-size: 0.8rem;
  font-weight: 500;
}

.fixed-buttons {
  position: fixed;
  width: 100%;
  left: 4%;
  bottom: 0rem;
  margin: 0;
  padding: 0.5rem 0 0.5rem 0;
  background-color: $color-background-default;
  border-top: 1px solid $color-dark-16;
  z-index: 1000;
}
</style>

import { TableStoreOption } from '@/classes/TableStoreOption'
import { getStoreDefinition } from '@/composables/StoreDefinition'
import { TableStoreId } from '@/enums/TableStoreId'
import type { ITableStore } from '@/interfaces/api/ITableStore'
import { defineStore } from 'pinia'

export const useMasksStore = (): ITableStore => {
  const useStore = defineStore(TableStoreId.Masks, getStoreDefinition(TableStoreOption.Masks))
  return useStore() as unknown as ITableStore
}

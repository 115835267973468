import { TableRecord } from '@/models/TableRecord'

export class DeviceRecord extends TableRecord {
  public name: string | null = null
  public serialNumber: string = ''
  public model: string | null = null
  public lastCalibration: string | null = null
  public calCertId: number | null = null
  public location: string | null = null
  public planInfo: string | null = null
  public planExpiration: string | null = null
}

<template>
  <div>
    <DisplayText :value="value" :label="label" v-if="mode === EditorMode.View" />
    <v-date-input
      v-if="mode === EditorMode.Edit"
      class="date-label"
      v-bind="$attrs"
      color="primary"
      required
      :label="customLabel"
      prepend-icon=""
      prepend-inner-icon="$calendar"
      v-model="model"
      v-maska="getInputMask"
      :hide-actions="true"
      variant="underlined"
      @blur="onBlur($event)"
      :rules="getRules()"
    >
    </v-date-input>
  </div>
</template>

<script setup lang="ts">
import { useRulesManager } from '@/composables/RulesManager'
import { EditorMode } from '@/enums/EditorMode'
import { LocaleDefinition } from '@/enums/LocaleDefinition'
import { Rule } from '@/enums/Rule'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import dayjs from 'dayjs'
import { computed, type PropType } from 'vue'

const RulesManager = useRulesManager()
const settingsStore = useSettingsStore()

// STORES, IMPORTS, & COMPOSABLES

// PROPS & EMITS
const props = defineProps({
  required: { type: Boolean, default: false },
  label: { type: String, default: '' },
  mode: { type: String as PropType<EditorMode>, default: EditorMode.Edit },
  value: { type: [String, Number, null] }
})

// REACTIVE VARIABLES
const model = defineModel<Date | null>({ default: null })

// COMPUTED PROPERTIES
const customLabel = computed(() => {
  if (props.required) {
    return `${props.label} *`
  }
  return `${props.label}`
})

// LIFECYCLE HOOKS

// FUNCTIONS
const onBlur = (event: any) => {
  if (event.target?._value && dayjs(event.target._value).isValid()) {
    model.value = new Date(event.target._value)
  } else {
    model.value = null
  }
}

const getInputMask = (): string => {
  switch (settingsStore.localeCode) {
    case LocaleDefinition.ChineseSimplified.code: {
      return '##/##/####'
    }
    case LocaleDefinition.ChineseTraditional.code: {
      return '##/##/####'
    }
    case LocaleDefinition.English.code: {
      return '##/##/####'
    }
    case LocaleDefinition.French.code: {
      return '##.##.####'
    }
    case LocaleDefinition.German.code: {
      return '##.##.####'
    }
    case LocaleDefinition.Japanese.code: {
      return '####/##/##'
    }
    case LocaleDefinition.Korean.code: {
      return '####. ##. ##'
    }
    case LocaleDefinition.Portuguese.code: {
      return '##.##.####'
    }
    case LocaleDefinition.Spanish.code: {
      return '##.##.####'
    }
  }
  return ''
}

const getRules = (): any => {
  let rules = RulesManager.getRules(Rule.ValidDate)
  if (props.required) {
    rules = rules.concat(RulesManager.getRules(Rule.Required))
  }
  return rules
}
</script>

<style lang="scss" scoped></style>

import { EndPoint } from '@/enums/EndPoint'
import type { IImportDefinition } from '@/interfaces/IImportDefinition'
import { getImportDefinition as getCustomFieldImportDefinition } from './ImportCustomFields'
import { getImportDefinition as getDailyChecksImportDefinition } from './ImportDailyCheckRecords'
import { getImportDefinition as getDeviceImportDefinition } from './ImportDevices'
import { getImportDefinition as getFitTestImportDefinition } from './ImportFitTests'
import { getImportDefinition as getMaskImportDefinition } from './ImportMasks'
import { getImportDefinition as getPeopleImportDefinition } from './ImportPeople'

const _importDefinitions = new Map<EndPoint, IImportDefinition>()

const _initialize = () => {
  _importDefinitions.set(EndPoint.CustomFields, getCustomFieldImportDefinition())
  _importDefinitions.set(EndPoint.DailyChecks, getDailyChecksImportDefinition())
  _importDefinitions.set(EndPoint.Devices, getDeviceImportDefinition())
  _importDefinitions.set(EndPoint.FitTests, getFitTestImportDefinition())
  _importDefinitions.set(EndPoint.Masks, getMaskImportDefinition())
  _importDefinitions.set(EndPoint.People, getPeopleImportDefinition())
}

export const getImportDefinitions = (): Array<IImportDefinition> => {
  if (_importDefinitions.size === 0) {
    _initialize()
  }
  return [..._importDefinitions.values()]
}

import type { ITableHeader } from '@/interfaces/ITableHeader'
import { type Composer } from 'vue-i18n'
import type { MaskRecord } from '../MaskRecord'

export const getTableHeaders = (
  i18n: Composer<{}, {}, {}, string, never, string>
): Array<ITableHeader> => {
  return [
    { title: i18n.t('fields.masks.manufacturer'), key: 'maskManufacturer', align: 'start' },
    { title: i18n.t('fields.masks.model'), key: 'maskModel', align: 'start' },
    { title: i18n.t('fields.masks.style'), key: 'maskStyle', align: 'start' },
    {
      title: i18n.t('fields.masks.n95'),
      key: 'n95',
      align: 'start',
      value: (item: MaskRecord) => `${item.n95 ? i18n.t('labels.yes') : i18n.t('labels.no')}`
    },
    { title: i18n.t('fields.masks.passLevel'), key: 'ffPassLevel', align: 'start' },
    { title: i18n.t('fields.actions'), key: 'actions', align: 'start', sortable: false }
  ]
}

import {
  toAlgorithm,
  toBoolean,
  toISODate,
  toNumber,
  toPassFail,
  toText
} from '@/composables/utils'
import { EndPoint } from '@/enums/EndPoint'
import type { IImportDefinition } from '@/interfaces/IImportDefinition'
import { useI18n } from 'vue-i18n'

export const getImportDefinition = (): IImportDefinition => {
  const i18n = useI18n({ useScope: 'global' })
  return {
    endpoint: EndPoint.FitTests,
    keyFields: ['serialNumber', 'testDate'],
    friendlyName: i18n.t('tables.names.fitTests'),
    ignoredFields: ['PersonInactive', 'AutoDescription', 'ProtocolN95', 'Method'],
    fields: [
      {
        title: i18n.t('fields.fitTests.testDate'),
        name: 'testDate',
        aliases: ['Test Date Time'],
        convert: toISODate
      },
      {
        title: i18n.t('fields.fitTests.dueDate'),
        name: 'dueDate',
        aliases: ['Next Test Date', 'Next Test Date Time'],
        convert: toISODate
      },
      {
        title: i18n.t('fields.fitTests.operator'),
        name: 'operator',
        aliases: ['TestOperator'],
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.maskSize'),
        name: 'maskSize',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.description'),
        name: 'description',
        convert: toText(255)
      },
      {
        title: i18n.t('fields.fitTests.avgAmbient'),
        name: 'avgAmbient',
        aliases: ['Average Ambient'],
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.overallFf'),
        name: 'overallFf',
        aliases: ['Overall Fit Factor'],
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.overallPass'),
        name: 'overallPass',
        aliases: ['Pass'],
        convert: toPassFail
      },
      {
        title: i18n.t('fields.fitTests.serialNumber'),
        name: 'serialNumber',
        aliases: ['PortaCountSN', 'Serial'],
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.n95'),
        name: 'n95',
        aliases: ['Mask N95'],
        convert: toBoolean,
        defaultValue: false
      },
      {
        title: i18n.t('fields.fitTests.firstName'),
        name: 'firstName',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.lastName'),
        name: 'lastName',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.idNumber'),
        name: 'idNumber',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.company'),
        name: 'company',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.location'),
        name: 'location',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.note'),
        name: 'note',
        convert: toText(128)
      },
      {
        title: i18n.t('fields.fitTests.custom1Label'),
        name: 'custom1Label',
        aliases: ['Custom Label 1'],
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.custom1Data'),
        name: 'custom1Data',
        aliases: ['Custom Data 1'],
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.custom2Label'),
        name: 'custom2Label',
        aliases: ['Custom Label 2'],
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.custom2Data'),
        name: 'custom2Data',
        aliases: ['Custom Data 2'],
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.custom3Label'),
        name: 'custom3Label',
        aliases: ['Custom Label 3'],
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.custom3Data'),
        name: 'custom3Data',
        aliases: ['Custom Data 3'],
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.custom4Label'),
        name: 'custom4Label',
        aliases: ['Custom Label 4'],
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.custom4Data'),
        name: 'custom4Data',
        aliases: ['Custom Data 4'],
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.maskManufacturer'),
        name: 'maskManufacturer',
        aliases: ['Manufacturer'],
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.maskModel'),
        name: 'maskModel',
        aliases: ['Model'],
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.maskStyle'),
        name: 'maskStyle',
        aliases: ['Style'],
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.approval'),
        name: 'approval',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.ffPassLevel'),
        name: 'ffPassLevel',
        aliases: ['Pass Level'],
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.maskDescription'),
        name: 'maskDescription',
        aliases: ['description', 'mask', 'respirator'],
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.protocolName'),
        name: 'protocolName',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.protocolModel'),
        name: 'protocolModel',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.ambientPurge'),
        name: 'ambientPurge',
        aliases: ['Ambient Purge (S)'],
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.ambientSample'),
        name: 'ambientSample',
        aliases: ['Ambient Sample (S)'],
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.maskPurge'),
        name: 'maskPurge',
        aliases: ['Mask Purge (S)', 'Respirator Purge (S)', 'Respirator Purge'],
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.period'),
        name: 'period',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.endOnFail'),
        name: 'endOnFail',
        aliases: ['EndOnExFFfail'],
        convert: toBoolean
      },
      {
        title: i18n.t('fields.fitTests.algorithm'),
        name: 'algorithm',
        convert: toAlgorithm
      },
      {
        title: i18n.t('fields.fitTests.numExercises'),
        name: 'numExercises',
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.exercise1'),
        name: 'exercise1',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.maskSample1'),
        name: 'maskSample1',
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.exclude1'),
        name: 'exclude1',
        convert: toBoolean
      },
      {
        title: i18n.t('fields.fitTests.fitFactor1'),
        name: 'fitFactor1',
        aliases: ['Ex.1 FitFactor'],
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.pass1'),
        name: 'pass1',
        aliases: ['Ex.1 Result'],
        convert: toPassFail
      },
      {
        title: i18n.t('fields.fitTests.exercise2'),
        name: 'exercise2',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.maskSample2'),
        name: 'maskSample2',
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.exclude2'),
        name: 'exclude2',
        convert: toBoolean
      },
      {
        title: i18n.t('fields.fitTests.fitFactor2'),
        name: 'fitFactor2',
        aliases: ['Ex.2 FitFactor'],
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.pass2'),
        name: 'pass2',
        aliases: ['Ex.2 Result'],
        convert: toPassFail
      },
      {
        title: i18n.t('fields.fitTests.exercise3'),
        name: 'exercise3',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.maskSample3'),
        name: 'maskSample3',
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.exclude3'),
        name: 'exclude3',
        convert: toBoolean
      },
      {
        title: i18n.t('fields.fitTests.fitFactor3'),
        name: 'fitFactor3',
        aliases: ['Ex.3 FitFactor'],
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.pass3'),
        name: 'pass3',
        aliases: ['Ex.3 Result'],
        convert: toPassFail
      },
      {
        title: i18n.t('fields.fitTests.exercise4'),
        name: 'exercise4',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.maskSample4'),
        name: 'maskSample4',
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.exclude4'),
        name: 'exclude4',
        convert: toBoolean
      },
      {
        title: i18n.t('fields.fitTests.fitFactor4'),
        name: 'fitFactor4',
        aliases: ['Ex.4 FitFactor'],
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.pass4'),
        name: 'pass4',
        aliases: ['Ex.4 Result'],
        convert: toPassFail
      },
      {
        title: i18n.t('fields.fitTests.exercise5'),
        name: 'exercise5',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.maskSample5'),
        name: 'maskSample5',
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.exclude5'),
        name: 'exclude5',
        convert: toBoolean
      },
      {
        title: i18n.t('fields.fitTests.fitFactor5'),
        name: 'fitFactor5',
        aliases: ['Ex.5 FitFactor'],
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.pass5'),
        name: 'pass5',
        aliases: ['Ex.5 Result'],
        convert: toPassFail
      },
      {
        title: i18n.t('fields.fitTests.exercise6'),
        name: 'exercise6',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.maskSample6'),
        name: 'maskSample6',
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.exclude6'),
        name: 'exclude6',
        convert: toBoolean
      },
      {
        title: i18n.t('fields.fitTests.fitFactor6'),
        name: 'fitFactor6',
        aliases: ['Ex.6 FitFactor'],
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.pass6'),
        name: 'pass6',
        aliases: ['Ex.6 Result'],
        convert: toPassFail
      },
      {
        title: i18n.t('fields.fitTests.exercise7'),
        name: 'exercise7',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.maskSample7'),
        name: 'maskSample7',
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.exclude7'),
        name: 'exclude7',
        convert: toBoolean
      },
      {
        title: i18n.t('fields.fitTests.fitFactor7'),
        name: 'fitFactor7',
        aliases: ['Ex.7 FitFactor'],
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.pass7'),
        name: 'pass7',
        aliases: ['Ex.7 Result'],
        convert: toPassFail
      },
      {
        title: i18n.t('fields.fitTests.exercise8'),
        name: 'exercise8',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.maskSample8'),
        name: 'maskSample8',
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.exclude8'),
        name: 'exclude8',
        convert: toBoolean
      },
      {
        title: i18n.t('fields.fitTests.fitFactor8'),
        name: 'fitFactor8',
        aliases: ['Ex.8 FitFactor'],
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.pass8'),
        name: 'pass8',
        aliases: ['Ex.8 Result'],
        convert: toPassFail
      },
      {
        title: i18n.t('fields.fitTests.exercise9'),
        name: 'exercise9',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.maskSample9'),
        name: 'maskSample9',
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.exclude9'),
        name: 'exclude9',
        convert: toBoolean
      },
      {
        title: i18n.t('fields.fitTests.fitFactor9'),
        name: 'fitFactor9',
        aliases: ['Ex.9 FitFactor'],
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.pass9'),
        name: 'pass9',
        aliases: ['Ex.9 Result'],
        convert: toPassFail
      },
      {
        title: i18n.t('fields.fitTests.exercise10'),
        name: 'exercise10',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.maskSample10'),
        name: 'maskSample10',
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.exclude10'),
        name: 'exclude10',
        convert: toBoolean
      },
      {
        title: i18n.t('fields.fitTests.fitFactor10'),
        name: 'fitFactor10',
        aliases: ['Ex.10 FitFactor'],
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.pass10'),
        name: 'pass10',
        aliases: ['Ex.10 Result'],
        convert: toPassFail
      },
      {
        title: i18n.t('fields.fitTests.exercise11'),
        name: 'exercise11',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.maskSample11'),
        name: 'maskSample11',
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.exclude11'),
        name: 'exclude11',
        convert: toBoolean
      },
      {
        title: i18n.t('fields.fitTests.fitFactor11'),
        name: 'fitFactor11',
        aliases: ['Ex.11 FitFactor'],
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.pass11'),
        name: 'pass11',
        aliases: ['Ex.11 Result'],
        convert: toPassFail
      },
      {
        title: i18n.t('fields.fitTests.exercise12'),
        name: 'exercise12',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.fitTests.maskSample12'),
        name: 'maskSample12',
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.exclude12'),
        name: 'exclude12',
        convert: toBoolean
      },
      {
        title: i18n.t('fields.fitTests.fitFactor12'),
        name: 'fitFactor12',
        aliases: ['Ex.12 FitFactor'],
        convert: toNumber
      },
      {
        title: i18n.t('fields.fitTests.pass12'),
        name: 'pass12',
        aliases: ['Ex.12 Result'],
        convert: toPassFail
      },
      {
        title: i18n.t('fields.fitTests.comfortScore'),
        name: 'comfortScore',
        convert: toText(16)
      },
      {
        title: i18n.t('fields.fitTests.userCompetency'),
        name: 'userCompetency',
        convert: toText(16)
      },
      {
        title: i18n.t('fields.fitTests.comfortValidation'),
        name: 'comfortValidation',
        convert: toText(16)
      },
      {
        title: i18n.t('fields.fitTests.fitTestPerformed'),
        name: 'fitTestPerformed',
        convert: toBoolean
      },
      {
        title: i18n.t('fields.fitTests.reserved1'),
        name: 'reserved1',
        aliases: ['QLFTTestSolution'],
        convert: toText(32)
      },
      {
        title: i18n.t('fields.fitTests.reserved2'),
        name: 'reserved2',
        aliases: ['QLFTSqueezes'],
        convert: toText(32)
      },
      {
        title: i18n.t('fields.fitTests.reserved3'),
        name: 'reserved3',
        convert: toText(32)
      },
      {
        title: i18n.t('fields.fitTests.reserved4'),
        name: 'reserved4',
        convert: toText(32)
      }
    ]
  }
}

<template>
  <div>
    <div v-if="model === 1">
      <span class="pass-label">{{ t('labels.passed') }}</span>
      <v-icon class="pass-icon">mdi-check-circle</v-icon>
    </div>
    <div v-else>
      <span class="fail-label">{{ t('labels.failed') }}</span>
      <v-icon class="fail-icon">mdi-alert-circle</v-icon>
    </div>
  </div>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS

// REACTIVE VARIABLES
const model = defineModel<number>({ default: 0 })

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// FUNCTIONS
</script>

<style lang="scss" scoped>
.pass-icon {
  color: $color-accent-success;
}
.pass-label {
  margin-right: 0.3rem;
  color: $color-accent-success;
  text-transform: uppercase;
}
.fail-icon {
  color: $color-accent-error;
}
.fail-label {
  margin-right: 0.3rem;
  color: $color-accent-error;
  text-transform: uppercase;
}
</style>

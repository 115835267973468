import { TableRecord } from '@/models/TableRecord'

export class MedicalClearanceRecord extends TableRecord {
  constructor(personId?: number) {
    super()
    this.personId = personId
  }
  public personId?: number
  public clearanceDate: string | null = null
  public expiration: string | null = null
  public restrictions: string | null = null
  public singleUse: boolean = false
  public scba: boolean = false
  public nonPressuredHood: boolean = false
  public hoodPAPR: boolean = false
  public hfPAPR: boolean = false
  public hfNegPressure: boolean = false
  public ffPAPR: boolean = false
  public ffNegPressure: boolean = false
}

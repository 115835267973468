<template>
  <DataTable
    show-expand
    :table-store-id="TableStoreId.Masks"
    :delete-warning="deleteWarning"
    :delete-title="t('dialogs.headers.deleteMask')"
    @new="createNewItem"
  >
    <template v-slot:expanded-row="{ item }">
      <tr class="expanded-row">
        <td class="expanded-column" colspan="2">
          <strong>{{ t('fields.masks.maskDescription') }}:</strong><br />{{ item.maskDescription }}
        </td>
        <td class="expanded-column" colspan="2">
          <strong>{{ t('fields.masks.approval') }}:</strong><br />
          <span v-if="item.approval">
            {{ item.approval }}
          </span>
          <span v-else>-</span>
        </td>
        <td class="expanded-column" colspan="3">
          <strong>{{ t('fields.masks.reserved1') }}:</strong><br />
          <span>
            {{ getFormFactor(item.reserved1) }}
          </span>
        </td>
      </tr>
    </template>
    <template v-slot:footer>
      <EditMask />
    </template>
  </DataTable>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { TableStoreId } from '@/enums/TableStoreId'
import type { ITableStore } from '@/interfaces/api/ITableStore'
import { MaskRecord } from '@/models/MaskRecord'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

const store = TableStoreFactory.get(TableStoreId.Masks) as ITableStore

// PROPS & EMITS

// REACTIVE VARIABLES

// COMPUTED PROPERTIES
const deleteWarning = computed((): string => {
  if (store.selectedItem) {
    return t('dialogs.delete.warning', {
      name: `${store.selectedItem.maskManufacturer} ${store.selectedItem.maskModel} ${store.selectedItem.maskStyle}`
    })
  }
  return t('dialogs.delete.warning')
})

// LIFECYCLE HOOKS

// FUNCTIONS
const createNewItem = () => {
  store.createNewItem(new MaskRecord())
}

const getFormFactor = (value: string): string => {
  let result = ''
  switch (value) {
    case '0': {
      result = t('fields.masks.reserved1Option2')
      break
    }
    case '1': {
      result = t('fields.masks.reserved1Option1')
      break
    }
    default: {
      result = '-'
      break
    }
  }
  return result
}
</script>

<style lang="scss" scoped></style>

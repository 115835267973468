<template>
  <div>
    <DataTable
      :search-type="SearchType.Query"
      :title="title"
      :table-store-id="TableStoreId.FitTests"
      class="expanded-row"
      :no-data-text="t('text.noTestsPerformed')"
      :delete-warning="deleteWarning"
      :delete-title="deleteTitle"
      :allow-new="false"
    >
      <template v-slot:item.overallPass="{ item }">
        <FitTestStatus v-model="item.overallPass" />
      </template>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { formatDate } from '@/composables/utils'
import { SearchType } from '@/enums/SearchType'
import { TableStoreId } from '@/enums/TableStoreId'
import type { IFitTestsStore } from '@/interfaces/api/IFitTestsStore'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import { computed, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })
const fitTestStore = TableStoreFactory.get(TableStoreId.FitTests) as IFitTestsStore
const settingsStore = useSettingsStore()

// PROPS & EMITS
const props = defineProps({
  person: { type: Object, required: true }
})

// REACTIVE VARIABLES

// COMPUTED PROPERTIES
const deleteTitle = computed(() => {
  return t('dialogs.headers.deleteFitTest', {
    name: `${props.person.firstName} ${props.person.lastName}`
  })
})

const title = computed(() => {
  return `${t('headers.fitTestHistory')}: ${props.person.firstName} ${props.person.lastName} (${props.person.idNumber})`
})

const deleteWarning = computed((): string => {
  return t('dialogs.delete.warning', {
    name: `the test performed on ${formatDate(fitTestStore.selectedItem?.testDate, true, settingsStore.localeCode)}`
  })
})

// LIFECYCLE HOOKS
onBeforeMount(async () => {
  if (fitTestStore.headers.length === 0) {
    fitTestStore.updateHeaders()
  }
})

// FUNCTIONS
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <DisplayText :value="model" :label="label" v-if="mode === EditorMode.View" />
    <v-select
      v-bind="$attrs"
      v-model="model"
      v-if="mode === EditorMode.Edit"
      color="primary"
      variant="underlined"
      density="compact"
    >
      <template v-slot:label>
        <span>{{ label }}&nbsp;<span v-if="required" class="required-text">*</span></span>
      </template>
      <!-- @vue-skip -->
      <template v-for="(_, name) in $slots" v-slot:[name]="slotData"
        ><slot :name="name" v-bind="slotData"
      /></template>
    </v-select>
  </div>
</template>

<script setup lang="ts">
import { EditorMode } from '@/enums/EditorMode'
import type { PropType } from 'vue'

// STORES, IMPORTS, & COMPOSABLES

// PROPS & EMITS
defineProps({
  required: { type: Boolean, default: false },
  label: { type: String, default: '' },
  mode: { type: String as PropType<EditorMode>, default: EditorMode.Edit }
})

// REACTIVE VARIABLES
const model = defineModel()

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// FUNCTIONS
</script>

<style lang="scss" scoped></style>

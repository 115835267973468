import { toBoolean, toISODate, toNumber, toText } from '@/composables/utils'
import { EndPoint } from '@/enums/EndPoint'
import type { IImportDefinition } from '@/interfaces/IImportDefinition'
import { useI18n } from 'vue-i18n'

export const getImportDefinition = (): IImportDefinition => {
  const i18n = useI18n({ useScope: 'global' })
  return {
    endpoint: EndPoint.DailyChecks,
    keyFields: ['serialNumber', 'date'],
    ignoredFields: ['Ambient'],
    friendlyName: i18n.t('tables.names.dailyChecks'),
    fields: [
      {
        title: i18n.t('fields.dailyChecks.serialNumber'),
        name: 'serialNumber',
        aliases: ['Serial Number'],
        convert: toText(64),
        required: true
      },
      {
        title: i18n.t('fields.dailyChecks.date'),
        name: 'date',
        aliases: ['Test Date', 'Test Date Time'],
        convert: toISODate,
        required: true
      },
      {
        title: i18n.t('fields.dailyChecks.particle'),
        name: 'particle',
        aliases: ['Min. Particle Result'],
        convert: toNumber,
        required: true
      },
      {
        title: i18n.t('fields.dailyChecks.classifier'),
        name: 'classifier',
        aliases: ['Classifier Result'],
        convert: toNumber
      },
      {
        title: i18n.t('fields.dailyChecks.zero'),
        name: 'zero',
        aliases: ['Zero Count Result'],
        convert: toNumber,
        required: true
      },
      {
        title: i18n.t('fields.dailyChecks.maxFf'),
        name: 'maxFf',
        aliases: ['Max. FF Result'],
        convert: toNumber,
        required: true
      },
      {
        title: i18n.t('fields.dailyChecks.n95'),
        name: 'n95',
        aliases: ['N95 Enabled'],
        convert: toBoolean,
        required: true
      }
    ]
  }
}

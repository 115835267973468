<template>
  <v-container fluid class="main-container">
    <v-row :class="{ 'top-row': !title, 'top-row-small': title }" align="center">
      <SearchField
        v-if="searchType === SearchType.FieldSearch"
        v-model.trim="store.searchText"
        :max-width="225"
      />
      <h3 v-if="title" class="table-title">{{ title }}:</h3>
      <ButtonIcon
        :text="t('buttons.refreshData')"
        icon="mdi-database-refresh"
        @click="store.loadItems()"
      />
      <slot name="left"></slot>
      <v-spacer />
      <ButtonPrimary
        v-if="allowNew"
        class="button-new"
        density="compact"
        :disabled="disableNew"
        @click="createNewItem"
        :text="t('buttons.new')"
      />
    </v-row>
    <v-row class="table-row">
      <v-data-table-server
        class="data-table"
        v-bind="$attrs"
        density="compact"
        v-model:items-per-page="store.itemsPerPage"
        :items="store.items"
        :items-length="store.count"
        :loading="store.workStatus?.search || store.workStatus?.getMany"
        item-value="id"
        :search="store.searchText"
        :items-per-page-options="settingsStore.itemsPerPageOptions"
        :sort-by="tableSort"
        :headers="store.filteredHeaders"
        :must-sort="true"
        :hide-default-footer="store.count < settingsStore.minTableCount"
        @update:options="loadItems"
      >
        <template v-slot:item.actions="{ item }">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                class="dot-menu"
                icon="mdi-dots-vertical"
                size="small"
                density="comfortable"
                variant="text"
                v-bind="props"
              ></v-btn>
            </template>
            <v-list>
              <v-list-item
                density="compact"
                :title="editButtonTitle"
                :prepend-icon="editButtonIcon"
                @click="store.editItem(item as TableRecord)"
              />
              <div v-tooltip="{ disabled: !isDisabled(item as any), text: disableDeleteMessage }">
                <v-list-item
                  :disabled="isDisabled(item as any)"
                  density="compact"
                  :title="t('buttons.delete')"
                  prepend-icon="mdi-delete"
                  @click="store.confirmDelete(item)"
                />
              </div>
              <slot name="menuItems" :item="item"></slot>
            </v-list>
          </v-menu>
        </template>
        <!-- @vue-skip -->
        <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
          <slot :name="name" v-bind="slotData" />
        </template>
      </v-data-table-server>
    </v-row>
    <DialogDelete
      v-model="store.showDeleteDialog"
      :loading="store.workStatus?.deleteOne"
      :message="deleteWarning"
      :title="deleteTitle"
      @cancel="store.finalizeDelete"
      @delete="store.deleteOne(store.selectedItem!)"
    />
    <slot name="footer"></slot>
  </v-container>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { isDefined } from '@/composables/utils'
import { EditorMode } from '@/enums/EditorMode'
import { SearchType } from '@/enums/SearchType'
import { SqlOrder } from '@/enums/SqlOrder'
import type { TableStoreId } from '@/enums/TableStoreId'
import type { ISort } from '@/interfaces/api/ISort'
import type { ITableStore } from '@/interfaces/api/ITableStore'
import type { ITableOptions } from '@/interfaces/ITableOptions'
import type { ITableSort } from '@/interfaces/ITableSort'
import { TableRecord } from '@/models/TableRecord'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import { computed, onBeforeMount, watch, type PropType } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })
const settingsStore = useSettingsStore()

// PRIVATE VARIABLES
let _previousSearch: string | undefined = ''

// PROPS & EMITS
const emit = defineEmits<{
  (e: 'new'): void
}>()

const props = defineProps({
  allowNew: { type: Boolean, default: true },
  disableDeleteMessage: { type: String },
  disableDeleteProperty: { type: String },
  disabledValue: { type: [Boolean, Number], default: false },
  disableNew: { type: Boolean, default: false },
  deleteTitle: { type: String, default: '' },
  deleteWarning: { type: String, default: '' },
  noteTitle: { type: String, default: '' },
  searchType: { type: String as PropType<SearchType>, default: SearchType.FieldSearch },
  tableStoreId: { type: String as PropType<TableStoreId>, required: true },
  title: { type: String, default: '' }
})

// LIFECYCLE HOOKS
onBeforeMount(async () => {
  // Get headers every time because they can change dynamically.
  store.updateHeaders()
})

const store = TableStoreFactory.get(props.tableStoreId) as ITableStore

// REACTIVE VARIABLES

// WATCHERS
watch(
  (): any => settingsStore.localeCode,

  () => {
    // Headers must change when a different language is selected.
    void store.updateHeaders()
  }
)

// COMPUTED PROPERTIES
const editButtonTitle = computed(() => {
  return store.editorMode === EditorMode.View ? t('buttons.viewDetails') : t('buttons.edit')
})

const editButtonIcon = computed(() => {
  return store.editorMode === EditorMode.View ? 'mdi-information' : 'mdi-pencil'
})

const tableSort = computed((): Array<ITableSort> => {
  return store.defaultSort.map((item: ISort): ITableSort => {
    const order = item.order === SqlOrder.ASC ? 'asc' : 'desc'
    return { key: item.field, order }
  })
})

// FUNCTIONS

const isDisabled = (item: Record<string, any>): boolean => {
  if (!props.disableDeleteProperty) {
    return false
  }
  return item[props.disableDeleteProperty] === props.disabledValue
}

const loadItems = async (tableOptions: ITableOptions) => {
  store.searchType = props.searchType
  const defer = isDefined(tableOptions.search) && _previousSearch !== tableOptions.search
  await store.loadItems(defer, tableOptions)
  _previousSearch = tableOptions.search
}

const createNewItem = (): void => {
  emit('new')
}
</script>

<style lang="scss" scoped>
.button-new {
  margin-right: 0.5rem;
}

.data-table {
  margin-bottom: 0.25rem;
}

.dot-menu {
  padding: 0;
  margin: 0.1rem;
}

.main-container {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.table-row {
  margin-top: -1rem;
  padding-top: 0;
}

.table-title {
  margin: 0 0.5rem 0 0.25rem;
}

.top-row {
  height: 5.5rem;
}
.top-row-small {
  height: 2.5rem;
}
</style>

<template>
  <BaseEditor
    v-bind="$attrs"
    :table-store-id="TableStoreId.FitTests"
    :title="t('dialogs.headers.fitTest')"
    @save-item="saveItem"
    :max-width="850"
    :allow-edit="permissionsManager.flags.allowEditFitTests"
  >
    <template v-if="store.selectedItem !== undefined" v-slot:status>
      <FitTestStatus v-model="item.overallPass" />
    </template>
    <v-tabs
      v-model="store.selectedTab"
      align-tabs="start"
      density="compact"
      color="primary"
      style="margin: 0; padding: 0"
    >
      <v-tab :key="FitTestTab.Details" :value="FitTestTab.Details">
        {{ t('tabs.fitTests.details') }}</v-tab
      >
      <v-tab :key="FitTestTab.Protocols" :value="FitTestTab.Protocols">
        {{ t('tabs.fitTests.protocols') }}</v-tab
      >
      <v-tab :key="FitTestTab.Respirator" :value="FitTestTab.Respirator">
        {{ t('tabs.fitTests.mask') }}</v-tab
      >
      <v-tab :key="FitTestTab.Results" :value="FitTestTab.Results">
        {{ t('tabs.fitTests.results') }}</v-tab
      >
    </v-tabs>

    <v-divider class="tab-divider"></v-divider>

    <v-tabs-window class="tab-content" v-model="store.selectedTab">
      <v-tabs-window-item :key="FitTestTab.Details" :value="FitTestTab.Details">
        <v-row>
          <v-col cols="6" xs="6" sm="6" md="4" lg="3" xl="3" xxl="3">
            <DisplayText :label="t('fields.fitTests.lastName')" :value="item.lastName" />
            <DisplayText :label="t('fields.fitTests.firstName')" :value="item.firstName" />
            <DisplayText :label="t('fields.fitTests.idNumber')" :value="item.idNumber" />
          </v-col>
          <v-col cols="6" xs="6" sm="6" md="4" lg="3" xl="3" xxl="3">
            <DisplayText
              :label="t('fields.fitTests.testDate')"
              :value="formatDate(item.testDate, true, settingsStore.localeCode)"
            />
            <DateField
              required
              :mode="store.editorMode"
              :label="t('fields.fitTests.dueDate')"
              :maxlength="20"
              :value="formatDate(item.dueDate, false, settingsStore.localeCode)"
              v-model.trim="dueDate"
            />
            <DisplayText :label="t('fields.fitTests.respirator')" :value="item.maskDescription" />
            <PrimaryTextField
              :mode="store.editorMode"
              :label="t('fields.fitTests.maskSize')"
              :maxlength="20"
              v-model.trim="item.maskSize"
            />
          </v-col>
          <v-col
            v-if="customFieldsStore.activeFieldDefinitions.length > 0"
            cols="6"
            xs="6"
            sm="6"
            md="4"
            lg="3"
            xl="3"
            xxl="3"
          >
            <DisplayText
              v-for="fieldDefinition in customFieldsStore.activeFieldDefinitions"
              :key="fieldDefinition.id"
              :label="fieldDefinition.item?.labelName"
              :value="item[fieldDefinition.valueField]"
            />
          </v-col>
          <v-col cols="6" xs="6" sm="6" md="4" lg="3" xl="3" xxl="3">
            <DisplayText :label="t('fields.fitTests.operator')" :value="item.operator" />
            <PrimaryTextArea
              :label="t('fields.fitTests.note')"
              v-model="item.note"
              :mode="store.editorMode"
            />
          </v-col>
        </v-row>
      </v-tabs-window-item>
      <v-tabs-window-item :key="FitTestTab.Protocols" :value="FitTestTab.Protocols">
        <h3>{{ item.protocolName }}</h3>
        <v-row>
          <v-col cols="6" xs="6" sm="6" md="4" lg="3" xl="3" xxl="3">
            <DisplayText :label="t('fields.fitTests.maskPurge')" :value="item.maskPurge" />
          </v-col>
          <v-col cols="6" xs="6" sm="6" md="4" lg="3" xl="3" xxl="3">
            <DisplayText :label="t('fields.fitTests.ambientSample')" :value="item.ambientSample" />
          </v-col>
          <v-col cols="6" xs="6" sm="6" md="4" lg="3" xl="3" xxl="3">
            <DisplayText :label="t('fields.fitTests.ambientPurge')" :value="item.ambientPurge" />
          </v-col>
          <v-col cols="6" xs="6" sm="6" md="4" lg="3" xl="3" xxl="3">
            <DisplayText
              :label="t('fields.fitTests.totalTestTime')"
              :value="getTotalTestTime(item)"
            />
          </v-col>
        </v-row>
        <v-row style="margin-top: -12px">
          <v-col style="margin-top: -16px" cols="12">
            <span class="sub-header">{{ t('fields.fitTests.maskSamples') }}</span>
            <v-divider class="sample-divider" />
          </v-col>
        </v-row>
        <v-container class="ma-0 pa-0" fluid>
          <v-row :key="index" v-for="(sample, index) in samples">
            <v-col style="min-width: 16rem" class="table-column justify-right" fluid>
              {{ sample.exercise }}
            </v-col>
            <v-col class="table-column" fluid>
              {{ sample.maskSample }}
            </v-col>
          </v-row>
        </v-container>
      </v-tabs-window-item>
      <v-tabs-window-item :key="FitTestTab.Respirator" :value="FitTestTab.Respirator">
        <v-row>
          <v-col cols="6" xs="6" sm="6" md="4" lg="3" xl="3" xxl="3">
            <DisplayText
              :label="t('fields.fitTests.maskManufacturer')"
              :value="item.maskManufacturer"
            />
            <DisplayText :label="t('fields.fitTests.approval')" :value="item.approval" />
          </v-col>
          <v-col cols="6" xs="6" sm="6" md="4" lg="3" xl="3" xxl="3">
            <DisplayText :label="t('fields.fitTests.maskModel')" :value="item.maskModel" />
            <PrimarySwitch
              :mode="store.editorMode"
              :label="t('fields.fitTests.n95')"
              :value="item.n95 ? t('labels.yes') : t('labels.no')"
              v-model="item.n95"
            />
          </v-col>
          <v-col cols="6" xs="6" sm="6" md="4" lg="3" xl="3" xxl="3">
            <DisplayText :label="t('fields.fitTests.maskStyle')" :value="item.maskStyle" />
            <DisplayText
              :label="t('fields.fitTests.maskDescription')"
              :value="item.maskDescription"
            />
          </v-col>
          <v-col cols="6" xs="6" sm="6" md="4" lg="3" xl="3" xxl="3">
            <DisplayText :label="t('fields.fitTests.ffPassLevel')" :value="item.ffPassLevel" />
          </v-col>
        </v-row>
      </v-tabs-window-item>
      <v-tabs-window-item :key="FitTestTab.Results" :value="FitTestTab.Results">
        <v-row style="margin-bottom: -20px">
          <v-col cols="6" xs="6" sm="4" md="4" lg="4" xl="4" xxl="4">
            <DisplayText :label="t('fields.fitTests.ffPassLevel')" :value="item.ffPassLevel" />
          </v-col>
          <v-col cols="6" xs="6" sm="4" md="4" lg="4" xl="4" xxl="4">
            <DisplayText
              :class="{ 'ff-pass': item.ffPassLevel, 'ff-fail': !item.ffPassLevel }"
              :label="t('fields.fitTests.overallFf')"
              :value="item.overallFf"
            />
          </v-col>
        </v-row>
        <v-container class="ma-0 pa-0" fluid>
          <v-row style="margin-top: 0.5rem" class="results-row">
            <v-col cols="6" class="table-column" fluid>
              <span class="sub-header">{{ t('fields.fitTests.exercise') }}</span>
            </v-col>
            <v-col cols="6" class="table-column" fluid>
              <span class="sub-header">{{ t('fields.fitTests.fitFactor') }}</span>
            </v-col>
          </v-row>
          <v-row class="results-row" :key="index" v-for="(sample, index) in samples">
            <v-col cols="6" class="table-column" fluid>
              {{ sample.exercise }}
            </v-col>
            <v-col
              cols="4"
              class="table-column"
              :class="{
                'ff-pass': sample.pass || sample.exclude,
                'ff-fail': !sample.pass && !sample.exclude
              }"
              fluid
            >
              {{ getFitFactor(sample) }}
            </v-col>
          </v-row>
        </v-container>
      </v-tabs-window-item>
    </v-tabs-window>
  </BaseEditor>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { formatDate, isDefined } from '@/composables/utils'
import { Algorithm } from '@/enums/Algorithm'
import { FitTestTab } from '@/enums/FitTestTab'
import { TableStoreId } from '@/enums/TableStoreId'
import type { ICustomFieldsStore } from '@/interfaces/api/ICustomFieldsStore'
import type { IFitTestsStore } from '@/interfaces/api/IFitTestsStore'
import type { ISample } from '@/interfaces/ISample'
import { FitTestRecord } from '@/models/FitTestRecord'
import { useCustomFieldsStore } from '@/stores/db/CustomFieldsStore'
import { usePeopleStore } from '@/stores/db/PeopleStore'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import { getPermissionsInstance } from '@fusion/auth'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import DisplayText from '../DisplayText.vue'
import DateField from '../inputs/DateField.vue'
import PrimarySwitch from '../inputs/PrimarySwitch.vue'
import PrimaryTextArea from '../inputs/PrimaryTextArea.vue'

const store = TableStoreFactory.get(TableStoreId.FitTests) as IFitTestsStore
const customFieldsStore = useCustomFieldsStore() as ICustomFieldsStore
const peopleStore = usePeopleStore()
const permissionsManager = getPermissionsInstance()
const settingsStore = useSettingsStore()

const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS

// REACTIVE VARIABLES
const item = ref<FitTestRecord>(new FitTestRecord())

// COMPUTED PROPERTIES
const dueDate = computed({
  get: (): Date => {
    if (isDefined(item.value.dueDate)) {
      return new Date(item.value.dueDate!)
    }
    return new Date()
  },
  set: (value: Date) => {
    item.value.dueDate = isDefined(value) ? value.toISOString() : new Date().toISOString()
  }
})

const samples = computed((): Array<ISample> => {
  if (!isDefined(item.value)) {
    return []
  }
  const results: Array<ISample> = []
  const numExercises = item.value.numExercises!
  for (let index = 1; index <= numExercises; index++) {
    results.push({
      exercise: item.value[`exercise${index}`],
      maskSample: item.value[`maskSample${index}`],
      exclude: item.value[`exclude${index}`] === true,
      fitFactor: item.value[`fitFactor${index}`],
      pass: item.value[`pass${index}`] === true
    })
  }
  return results
})

// WATCHERS
watch(
  (): any => store.selectedItem,

  (value) => {
    if (isDefined(value)) {
      store.selectedTab = FitTestTab.Details
      void customFieldsStore.loadItems()
    }
    item.value = { ...value }
  }
)

// LIFECYCLE HOOKS

// FUNCTIONS
const getFitFactor = (sample: ISample) => {
  if (sample.exclude) {
    return t('labels.exclude')
  }
  return sample.fitFactor
}

const getTotalTestTime = (item: FitTestRecord): number => {
  let duration = 0
  const ambientPurge = item.ambientPurge || 0
  const ambientSample = item.ambientSample || 0
  const maskPurge = item.maskPurge || 0

  samples.value.forEach((sample, index) => {
    if (sample.exclude) {
      duration += Math.max(ambientPurge + ambientSample, sample.maskSample)
    } else {
      switch (item.algorithm) {
        case Algorithm.QNFT: {
          duration += maskPurge + ambientPurge + ambientSample + sample.maskSample
          break
        }
        case Algorithm.FastFit: {
          if (index === 0) {
            duration += maskPurge + ambientPurge + ambientSample + sample.maskSample
          } else {
            duration += sample.maskSample
          }
          break
        }
        case Algorithm.QLFT: {
          duration += sample.maskSample
          break
        }
      }
    }
  })

  if (item.algorithm !== Algorithm.QNFT) {
    duration += item.algorithm === Algorithm.QLFT ? 0 : ambientPurge + ambientSample
  }
  return duration
}

const saveItem = async () => {
  const response = await store.save({ ...item.value })
  if (!response.error) {
    peopleStore.loadItems()
  }
}
</script>

<style lang="scss" scoped>
.table-column {
  margin: 0.25rem 0 0.25rem;
  padding: 0rem 0rem 0 1rem;
}
.sample-divider {
  margin: 0;
  border-style: dashed;
}
.justify-right {
  text-align: right;
}
.ff-pass {
  color: $color-accent-success;
}
.ff-fail {
  color: $color-accent-error;
}
.results-row {
  border-bottom: 1px solid $color-dark-16;
}
.sub-header {
  font-size: 0.8rem;
  text-transform: uppercase;
  color: $color-dark-36;
}
</style>

<template>
  <BaseEditor
    v-bind="$attrs"
    :table-store-id="TableStoreId.Events"
    :title="title"
    :max-height="600"
    :max-width="500"
    @save-item="saveItem"
  >
    <v-row>
      <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6"> </v-col>
      <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" xxl="6"> </v-col>
    </v-row>

    <p class="required-text">* = {{ t('labels.required').toLowerCase() }}</p>
  </BaseEditor>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { TableStoreId } from '@/enums/TableStoreId'
import type { IDevicesStore } from '@/interfaces/api/IDevicesStore'
import { EventRecord } from '@/models/EventRecord'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const store = TableStoreFactory.get(TableStoreId.Events) as IDevicesStore
const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS

// REACTIVE VARIABLES
const item = ref<EventRecord>(new EventRecord())

// COMPUTED PROPERTIES
const title = computed((): string => {
  if (item.value?.id) {
    return t('dialogs.headers.editEvent')
  }
  return t('dialogs.headers.createEvent')
})

// WATCHERS
watch(
  (): any => store.selectedItem,

  (value) => {
    item.value = { ...value }
  }
)

// LIFECYCLE HOOKS
const saveItem = async () => {
  console.info('saveItem: ', saveItem)
}
</script>

<style lang="scss" scoped>
.max-message {
  padding: 0;
  margin: 0;
  font-size: 0.75rem;
}
</style>

import ButtonAlternate from '@/components/buttons/ButtonAlternate.vue'
import ButtonIcon from '@/components/buttons/ButtonIcon.vue'
import ButtonPrimary from '@/components/buttons/ButtonPrimary.vue'
import ButtonSecondary from '@/components/buttons/ButtonSecondary.vue'

import BasePage from '@/components/BasePage.vue'
import CalendarEvents from '@/components/CalendarEvents.vue'
import DataTable from '@/components/DataTable.vue'
import BaseDialog from '@/components/dialogs/BaseDialog.vue'
import DialogDelete from '@/components/dialogs/DialogDelete.vue'
import DialogInfo from '@/components/dialogs/DialogInfo.vue'
import DialogSubscribe from '@/components/dialogs/DialogSubscribe.vue'
import DisplayText from '@/components/DisplayText.vue'
import BaseEditor from '@/components/editors/BaseEditor.vue'
import FitTestHistory from '@/components/FitTestHistory.vue'
import FitTestStatus from '@/components/FitTestStatus.vue'
import HeaderWithFilter from '@/components/HeaderWithFilter.vue'
import DashboardFilter from '@/components/inputs/DashboardFilter.vue'
import SnackBar from '@/components/SnackBar.vue'
import StatsSection from '@/components/StatsSection.vue'
import StatusChip from '@/components/StatusChip.vue'
import { vMaska } from 'maska/vue'

import EditColumns from '@/components/editors/EditColumns.vue'
import EditCustomField from '@/components/editors/EditCustomField.vue'
import EditDevice from '@/components/editors/EditDevice.vue'
import EditEvent from '@/components/editors/EditEvent.vue'
import EditFitTest from '@/components/editors/EditFitTest.vue'
import EditMask from '@/components/editors/EditMask.vue'
import EditMedicalClearance from '@/components/editors/EditMedicalClearance.vue'
import EditPerson from '@/components/editors/EditPerson.vue'

import DateField from '@/components/inputs/DateField.vue'
import FileView from '@/components/inputs/FileView.vue'
import PrimaryComboBox from '@/components/inputs/PrimaryComboBox.vue'
import PrimarySelect from '@/components/inputs/PrimarySelect.vue'
import PrimarySwitch from '@/components/inputs/PrimarySwitch.vue'
import PrimaryTextArea from '@/components/inputs/PrimaryTextArea.vue'
import PrimaryTextField from '@/components/inputs/PrimaryTextField.vue'
import SearchField from '@/components/inputs/SearchField.vue'
import RichTextEditor from '@/components/richText/RichTextEditor.vue'

import TextSelectCombo from '@/components/inputs/TextSelectCombo.vue'

import type { App } from 'vue'

export const localComponents = {
  install(app: App) {
    app.component('EditColumns', EditColumns)
    app.component('HeaderWithFilter', HeaderWithFilter)
    app.component('BaseEditor', BaseEditor)
    app.component('BasePage', BasePage)
    app.component('DataTable', DataTable)
    app.component('DateField', DateField)
    app.component('DisplayText', DisplayText)
    app.component('SnackBar', SnackBar)
    app.component('ButtonAlternate', ButtonAlternate)
    app.component('ButtonPrimary', ButtonPrimary)
    app.component('ButtonSecondary', ButtonSecondary)
    app.component('BaseDialog', BaseDialog)
    app.component('DashboardFilter', DashboardFilter)
    app.component('DialogDelete', DialogDelete)
    app.component('DialogInfo', DialogInfo)
    app.component('DialogSubscribe', DialogSubscribe)
    app.component('EditMedicalClearance', EditMedicalClearance)
    app.component('EditCustomField', EditCustomField)
    app.component('EditDevice', EditDevice)
    app.component('EditEvent', EditEvent)
    app.component('EditFitTest', EditFitTest)
    app.component('EditMask', EditMask)
    app.component('EditPerson', EditPerson)
    app.component('CalendarEvents', CalendarEvents)
    app.component('FileView', FileView)
    app.component('FitTestHistory', FitTestHistory)
    app.component('FitTestStatus', FitTestStatus)
    app.component('ButtonIcon', ButtonIcon)
    app.component('PrimaryComboBox', PrimaryComboBox)
    app.component('PrimarySelect', PrimarySelect)
    app.component('PrimarySwitch', PrimarySwitch)
    app.component('PrimaryTextArea', PrimaryTextArea)
    app.component('PrimaryTextField', PrimaryTextField)
    app.component('RichTextEditor', RichTextEditor)
    app.component('SearchField', SearchField)
    app.component('StatusChip', StatusChip)
    app.component('StatsSection', StatsSection)
    app.component('TextSelectCombo', TextSelectCombo)
    app.directive('maska', vMaska)
  }
}

import type { IImportFieldDefinition } from '@/interfaces/IImportFieldDefinition'

/**
 * Class that defines a field definition used to map raw import file columns to database table fields.
 */
export class ImportField implements IImportFieldDefinition {
  public name: string | null
  public title: string
  public index: number
  public aliases: Array<string>
  public ignore?: boolean
  public convert?: Function | undefined
  public defaultValue?: any
  public required?: boolean
  public sortable?: boolean
  public key: string

  constructor(
    name: string | null,
    title: string,
    index: number,
    ignore: boolean = false,
    aliases: Array<string> = [],
    convert: Function | undefined = undefined,
    defaultValue?: any,
    required: boolean = false,
    sortable: boolean = false
  ) {
    this.name = name
    this.title = title
    this.index = index
    this.ignore = ignore
    this.aliases = aliases
    this.convert = convert
    this.defaultValue = defaultValue
    this.required = required
    this.sortable = sortable
    this.key = `[${this.index}]`
  }
}

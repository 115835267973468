import { TableStoreOption } from '@/classes/TableStoreOption'
import { getStoreDefinition } from '@/composables/StoreDefinition'
import { EndPoint } from '@/enums/EndPoint'
import { SqlComparison } from '@/enums/SqlComparison'
import { SqlOperator } from '@/enums/SqlOperator'
import { TableStoreId } from '@/enums/TableStoreId'
import type { IDevicesStore } from '@/interfaces/api/IDevicesStore'
import type { IQueryOptions } from '@/interfaces/api/IQueryOptions'
import dayjs from 'dayjs'
import { defineStore } from 'pinia'
import { useSettingsStore } from '../ui/SettingsStore'
import { useAPIStore } from './ApiStore'

let _store: IDevicesStore
export const useDevicesStore = () => {
  if (_store) {
    return _store
  }
  const useStore = defineStore(TableStoreId.Devices, getStoreDefinition(TableStoreOption.Devices))
  _store = useStore() as unknown as IDevicesStore
  _extend(_store)
  return _store
}
const _extend = (store: IDevicesStore) => {
  const apiStore = useAPIStore()
  const settingsStore = useSettingsStore()

  store.getDeviceExpirations = async () => {
    const calExpires = dayjs().subtract(
      settingsStore.globals.devices.daysToExpireCalibration -
        settingsStore.globals.devices.daysWarningCalibration -
        1,
      'day'
    )
    const planExpires = dayjs().add(settingsStore.globals.devices.daysWarningPlan - 1, 'day')
    const queryOptions: IQueryOptions = {
      where: [
        {
          comparison: SqlComparison.LessThan,
          field: 'lastCalibration',
          value1: calExpires.toISOString(),
          operator: SqlOperator.Or
        },
        {
          comparison: SqlComparison.LessThan,
          field: 'planExpiration',
          value1: planExpires.toISOString()
        }
      ],
      fields: ['id', 'serialNumber', 'lastCalibration', 'planExpiration']
    }
    return apiStore.getMany(EndPoint.Devices, queryOptions)
  }
}

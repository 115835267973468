import { getActivePinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createApp } from 'vue'

// Default styles
import '@fusion/saas-components-v3/dist/style.css'

// Components
import { fusionComponents } from '@/plugins/fusionComponents'
import { localComponents } from '@/plugins/localComponents'

// Dayjs
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/ja'
import 'dayjs/locale/ko'
import 'dayjs/locale/pt'
import 'dayjs/locale/zh'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localeData)
dayjs.extend(localizedFormat)

// I18n
import { i18n } from '@/plugins/i18n'

// Vuetify
import { vuetify } from '@/plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// HighCharts
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import highchartsAccessibility from 'highcharts/modules/accessibility'
highchartsAccessibility(Highcharts)

// Fusion Auth
import Auth0Plugin, { getAuthOptions } from '@/plugins/auth'
import { permissions } from '@/plugins/permissions'

// Rich editor

import App from '@/App.vue'
import { getRouter } from '@/router'

const setup = async () => {
  const app = createApp(App)
  const router = await getRouter(app)
  app.use(Auth0Plugin, getAuthOptions(router))
  app.use(fusionComponents)
  app.use(localComponents)
  app.use(permissions)
  app.use(router)
  app.use(vuetify)
  app.use(HighchartsVue)
  app.use(i18n)

  const pinia = getActivePinia()
  pinia!.use(piniaPluginPersistedstate)

  app.mount('#app')
}

setup()

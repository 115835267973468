<template>
  <v-app>
    <v-app-bar class="no-padding" theme="dark">
      <FusionAppBar
        :logoSrc="logoSrc"
        :logoWidth="'206'"
        :hasDrawer="hasDrawer"
        :isLoading="!auth0?.isAuthenticated"
        :user="user"
        @drawerClicked="drawerToggle"
        data-cy="appBar"
      >
        <template v-slot:burger>
          <span
            v-if="hasDrawer && display.lgAndDown.value && !drawerPermanent"
            :class="{
              'application-burger': hasDrawer && display.lgAndDown.value && !drawerPermanent
            }"
          >
            <v-app-bar-nav-icon
              @click.stop="drawerToggle"
              class="mx-0 text"
              size="x-large"
              :color="theme.current.value.colors['on-surface']"
              data-cy="hamburgerButton"
            />
          </span>
          <span v-else class="no-application-burger" />
        </template>

        <template v-slot:left-toolbar>
          <div class="application-title">
            <h2>{{ t('app.title') }}</h2>
          </div>
        </template>
        <template v-slot:right-toolbar>
          <FusionAuthenticator :hideUserProfile="true" />
        </template>
      </FusionAppBar>
    </v-app-bar>
    <v-navigation-drawer
      v-if="hasDrawer"
      v-model="drawer.open"
      :permanent="drawerPermanent"
      :rail="false"
      rail-width="68"
      width="240"
      color="#f2f2f2"
    >
      <FusionSideBar :routes="routes" :drawerMini="false" data-cy="sideBar"></FusionSideBar>
      <template v-if="drawer.open" v-slot:append>
        <ButtonIcon
          style="margin-left: 0.25rem"
          @click="openFeedbackDialog"
          :text="t('buttons.openFeedback')"
          icon="mdi-bullhorn"
        />
        <v-divider class="divider" />
        <div class="footer">
          <span>
            &copy; {{ new Date().getFullYear() }}
            {{ t('app.copyright') }}
          </span>
          <span>v{{ appVersion }}</span>
        </div>
      </template>
      <PrimarySelect
        class="language-selector"
        v-model="settingsStore.localeCode"
        :label="t('labels.activeLanguage')"
        :items="settingsStore.languageOptions"
      ></PrimarySelect>
    </v-navigation-drawer>
    <v-main>
      <v-container class="main-container ma-0 pa-0" fluid>
        <router-view />
      </v-container>
      <SnackBar />
    </v-main>
    <BaseDialog
      :title="t('dialogs.headers.deviceAlert')"
      v-model="showWarning"
      v-bind="$attrs"
      :max-width="500"
      icon="mdi-alert"
      icon-color="red"
    >
      <p v-if="devicesExpirations.length === 1">
        {{ t('warnings.deviceExpirationSingle', { total: devicesExpirations.length }) }}
      </p>
      <p v-else>
        {{ t('warnings.deviceExpirationMultiple', { total: devicesExpirations.length }) }}
      </p>

      <template v-slot:actions>
        <ButtonSecondary @click="cancelWarning" :text="t('buttons.cancel')" />
        <v-spacer />
        <ButtonPrimary @click="gotoDevices" :text="t('buttons.yes')" />
      </template>
    </BaseDialog>
    <FusionFeedbackDialog
      :open="showFeedbackDialog"
      @close="closeFeedbackDialog"
    ></FusionFeedbackDialog>
  </v-app>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES

import { useSettingsStore } from '@/stores/ui/SettingsStore'
import { getAuthInstance, type Auth0User } from '@fusion/auth'
import { FusionMixins } from '@fusion/saas-components-v3'
import { computed, onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import type { DisplayInstance, ThemeInstance } from 'vuetify'
import { useDisplay, useTheme } from 'vuetify'
import { SettingsTab } from './enums/SettingsTab'
import { TableStoreId } from './enums/TableStoreId'
import type { IDevicesStore } from './interfaces/api/IDevicesStore'
import { DeviceRecord } from './models/DeviceRecord'
import { TableStoreFactory } from './stores/db/TableStoreFactory'

const devicesStore = TableStoreFactory.get(TableStoreId.Devices) as IDevicesStore
const settingsStore = useSettingsStore()
const i18n = useI18n({ useScope: 'global' })
const t = i18n.t
const auth0 = getAuthInstance()
const router = useRouter()
const routes = router.getRoutes()
const display: DisplayInstance = useDisplay()
const theme: ThemeInstance = useTheme()

// CONSTANTS
const logoSrc = `./img/tsi_link_logo.svg`
const { drawer, drawerPermanent, drawerToggle } = FusionMixins.drawer()

// PROPS & EMITS

// REACTIVE VARIABLES
const devicesExpirations = ref<Array<DeviceRecord>>([])
const showFeedbackDialog = ref<boolean>(false)
const showWarning = ref<boolean>(false)

// LIFECYCLE HOOKS
onBeforeMount(async () => {
  let intervalId = setInterval(async () => {
    if (auth0?.isAuthenticated) {
      clearInterval(intervalId)
      const response = await devicesStore.getDeviceExpirations()
      if (
        !response.error &&
        response.data?.results.length > 0 &&
        router.currentRoute.value.name !== 'preview'
      ) {
        devicesExpirations.value = response.data.results
        showWarning.value = true
      }
    }
  }, 500)
})

// COMPUTED PROPERTIES
const appVersion = computed((): string => {
  return APP_VERSION
})

const user = computed((): Auth0User => {
  return auth0?.user?.value ? auth0.user.value : {}
})

const hasDrawer = computed((): boolean => {
  return auth0?.isAuthenticated === true
})

// FUNCTIONS
const cancelWarning = () => {
  showWarning.value = false
}

const closeFeedbackDialog = () => {
  showFeedbackDialog.value = false
}

const gotoDevices = () => {
  showWarning.value = false
  settingsStore.selectedTab = SettingsTab.Devices
  router.push('/settings')
}

const openFeedbackDialog = () => {
  showFeedbackDialog.value = true
}
</script>

<style lang="scss" scoped>
.main-container {
  position: relative;
  left: 0;
  right: 0;
}

.divider {
  margin-bottom: 0;
  padding-bottom: 0;
}

.footer {
  opacity: 0.3;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-height: 74px;
  white-space: nowrap;
}

.language-selector {
  padding: 3rem 0.75rem 0 0.75rem;
}

.application-title {
  @media (max-width: 777px) {
    display: none;
  }

  h2 {
    margin: 0;
  }
}

.application-burger {
  background-color: #fff;
  height: 100%;
  align-items: center;
  padding-left: 8px;
  padding-right: 0;
}

.no-application-burger {
  background-color: #fff;
  align-items: center;
  padding-left: 8px;
  padding-right: 0;
}
</style>

<style lang="scss" src="@/styles/_styles.scss" />

import { WorkStatus } from '@/classes/WorkStatus'
import { isDefined } from '@/composables/utils'
import { EditorMode } from '@/enums/EditorMode'
import { EndPoint } from '@/enums/EndPoint'
import { MessageType } from '@/enums/MessageType'
import { SqlOrder } from '@/enums/SqlOrder'
import { TableStoreId } from '@/enums/TableStoreId'
import type { ICollectionResponse } from '@/interfaces/api/ICollectionResponse'
import type { ICustomFieldDefinition } from '@/interfaces/api/ICustomFieldDefinition'
import type { ICustomFieldsStore } from '@/interfaces/api/ICustomFieldsStore'
import type { IFitTestsStore } from '@/interfaces/api/IFitTestsStore'
import type { IItemResponse } from '@/interfaces/api/IItemResponse'
import type { IPeopleStore } from '@/interfaces/api/IPeopleStore'
import type { IQueryOptions } from '@/interfaces/api/IQueryOptions'
import type { ISort } from '@/interfaces/api/ISort'
import type { ISimpleOption } from '@/interfaces/ISimpleOption'
import type { ITableHeader } from '@/interfaces/ITableHeader'
import { CustomFieldRecord } from '@/models/CustomFieldRecord'
import { getDefaultSort } from '@/models/defaults/TableSorts'
import type { PeopleRecord } from '@/models/PeopleRecord'
import { getTableHeaders } from '@/models/tableHeaders'
import type { TableRecord } from '@/models/TableRecord'
import { useAPIStore } from '@/stores/db/ApiStore'
import { useSnackbarStore } from '@/stores/ui/SnackbarStore'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { TableStoreFactory } from './TableStoreFactory'

export const useCustomFieldsStore = defineStore(
  TableStoreId.CustomFields,
  (): ICustomFieldsStore => {
    // STORES, IMPORTS, & COMPOSABLES
    const snackbarStore = useSnackbarStore()
    const i18n = useI18n({ useScope: 'global' })
    const apiStore = useAPIStore()
    const peopleStore = TableStoreFactory.get(TableStoreId.People) as IPeopleStore
    const fitTestsStore = TableStoreFactory.get(TableStoreId.FitTests) as IFitTestsStore

    // REACTIVE VARIABLES
    const _allFieldDefinitions = ref<Array<ICustomFieldDefinition>>([])
    const _backupItem = ref<TableRecord>()
    const _selectedItem = ref<TableRecord>()
    const count = ref<number>(0)
    const customFieldsLoaded = ref<boolean>(false)
    const defaultEditorMode = ref<EditorMode>(EditorMode.Edit)
    const editorMode = ref<EditorMode>(defaultEditorMode.value)
    const headers = ref<Array<ITableHeader>>([])
    const items = ref<Array<CustomFieldRecord>>([])
    const inactiveItems = ref<Array<CustomFieldRecord>>([])
    const showEditDialog = ref<boolean>(false)
    const showDeleteDialog = ref<boolean>(false)
    const workStatus = ref<WorkStatus>(new WorkStatus())

    // COMPUTED PROPERTIES
    const activeFieldDefinitions = computed(() => {
      return _allFieldDefinitions.value.filter((fieldDefinition) => {
        return fieldDefinition.item?.active === 1
      })
    })

    const filterableFieldDefinitions = computed(() => {
      return _allFieldDefinitions.value.filter((fieldDefinition) => {
        return fieldDefinition.item?.active === 1 && fieldDefinition.item.allowFiltering
      })
    })

    const disabledFieldDefinitions = computed(() => {
      return _allFieldDefinitions.value.filter((fieldDefinition) => {
        return fieldDefinition.item?.enabled === 0
      })
    })

    const defaultSort = computed((): Array<ISort> => {
      return getDefaultSort(TableStoreId.CustomFields)
    })

    const filteredHeaders = computed(() => {
      return headers.value
    })

    const selectedItem = computed(() => {
      return _selectedItem.value
    })

    // FUNCTIONS
    const addFieldDefinition = (item: CustomFieldRecord) => {
      const fieldDefinition: ICustomFieldDefinition = {
        id: item.id!,
        valueField: item.enabled ? `custom${item.id}Data` : `${item.labelName?.toLowerCase()}`,
        labelField: item.enabled ? `custom${item.id}Label` : undefined,
        autoValues: [],
        selectOptions: item.combo === 0 ? _getFieldOptions(item) : [],
        item
      }
      fieldDefinition.autoValues = []
      _allFieldDefinitions.value.push(fieldDefinition)
    }

    const _loadAutoCompleteValues = async (): Promise<Array<void>> => {
      const promises: Array<Promise<void>> = []
      activeFieldDefinitions.value.forEach((fieldDefinition: ICustomFieldDefinition) => {
        if (fieldDefinition.item?.autoComplete) {
          promises.push(
            getAutoCompleteValues(fieldDefinition).then((results) => {
              fieldDefinition.autoValues = results
            })
          )
        }
      })
      return Promise.all(promises)
    }

    // Note the CustomFields endpoint never really deletes, it just sets the item to inactive.
    /**
     * Deactivate a custom field. Note the CustomFields endpoint never
     * really deletes, it just sets the item to inactive.
     * @param showError Flag that is used to suppress any server-side errors.
     */
    const deleteOne = async (
      item: TableRecord,
      showError: boolean = true
    ): Promise<IItemResponse> => {
      if (!item.enabled) {
        throw Error('You cannot delete disabled CustomFieldRecords.')
      }
      workStatus.value.deleteOne = true

      const definition = getFieldDefinition(item.id!)
      const label = definition
        ? definition.valueField[0].toUpperCase() + definition.valueField.slice(1)
        : ''
      const newItem = new CustomFieldRecord()
      newItem.id = item.id
      newItem.required = 0
      newItem.active = 0
      newItem.combo = 0
      newItem.labelName = label

      const response = await apiStore.save(EndPoint.CustomFields, newItem, showError)

      if (!response.error && definition) {
        const query: Record<string, any> = {
          update: {}
        }
        if (definition.labelField) {
          query.update[definition.labelField] = null
        }
        query.update[definition.valueField] = null
        await peopleStore.updateMany(query)
        await fitTestsStore.updateMany(query)
      }
      workStatus.value.deleteOne = false
      finalizeDelete()
      await loadItems()
      return response
    }

    const getFieldDefinition = (id: number): ICustomFieldDefinition | undefined => {
      return _allFieldDefinitions.value.find((fieldDefinition) => {
        return fieldDefinition.id === id
      })
    }

    /**
     * Gets the collection of all field options if it is type = combo.
     * @param id Item unique identifier.
     * @returns
     */
    const _getFieldOptions = (item: CustomFieldRecord): Array<ISimpleOption> => {
      const options: Array<ISimpleOption> = []
      if (item) {
        const keys = Object.keys(item).filter((key) => {
          return key.startsWith('option')
        })
        keys.forEach((key) => {
          if (item[key]) {
            options.push({ title: item[key], value: item[key] })
          }
        })
      }
      return options
    }

    const revertEdit = async () => {
      _selectedItem.value = _backupItem.value ? { ..._backupItem.value } : undefined
      editorMode.value = EditorMode.View
    }

    /**
     * Saves an item to the collection.
     * @param item A CustomField record instance.
     * @param showError Flag that is used to supress any server-side errors.
     * @returns
     */
    const save = async (item: CustomFieldRecord, showError: boolean = true) => {
      let response: IItemResponse = { data: null, error: null }
      if (item.combo === 1) {
        const keys = Object.keys(item).filter((key) => {
          return key.startsWith('option')
        })
        keys.forEach((key) => {
          item[key] = null
        })
      }
      if (item.id) {
        response = await apiStore.save(EndPoint.CustomFields, item, showError)
      } else {
        const nextInactiveItem: CustomFieldRecord = inactiveItems.value[0]
        if (nextInactiveItem) {
          item = Object.assign(item, { enabled: 1, active: 1, id: nextInactiveItem.id })
          response = await apiStore.save(EndPoint.CustomFields, item, showError)
        } else {
          snackbarStore.addMessage(i18n.t('errors.noInactiveFields'), MessageType.Error)
        }
      }

      const definition = getFieldDefinition(item.id!)
      if (definition?.labelField) {
        const query: Record<string, any> = {
          update: {}
        }
        query.update[definition.labelField] = item.labelName
        await peopleStore.updateMany(query)
        await fitTestsStore.updateMany(query)
      }
      if (!response.error) {
        await loadItems()
        finalizeEdit()
      }
    }

    /**
     * Gets all custom field records from the database. Re-orders them arbirarily.
     * @param showError Flag that is used to supress any server-side errors.
     */
    const loadItems = async (showError: boolean = true): Promise<void> => {
      workStatus.value.search = true
      const queryOptions: IQueryOptions = {
        orderBy: [{ field: 'id', order: SqlOrder.ASC }]
      }
      const response: ICollectionResponse = await apiStore.getMany(
        EndPoint.CustomFields,
        queryOptions,
        showError
      )
      workStatus.value.search = false

      items.value = []
      inactiveItems.value = []
      _allFieldDefinitions.value = []

      // Order as required.
      const indices = [4, 5, 0, 1, 2, 3]
      if (response.data && response.data.results.length > 0) {
        const sortedData: Array<CustomFieldRecord> = []
        indices.forEach((index) => {
          if (response.data?.results[index]) {
            sortedData.push(response.data.results[index] as CustomFieldRecord)
          }
        })

        sortedData.forEach(async (item: CustomFieldRecord) => {
          if (item.active === 1) {
            items.value.push(item as CustomFieldRecord)
          } else {
            inactiveItems.value.push(item as CustomFieldRecord)
          }
          addFieldDefinition(item)
        })
        await _loadAutoCompleteValues()
      }
      count.value = items.value.length
    }

    const finalizeDelete = () => {
      showDeleteDialog.value = false
      _selectedItem.value = undefined
    }

    const finalizeEdit = () => {
      showEditDialog.value = false
      _selectedItem.value = undefined
    }

    const confirmDelete = (item: TableRecord) => {
      _selectedItem.value = { ...item }
      showDeleteDialog.value = true
    }

    const createNewItem = (item: TableRecord) => {
      _selectedItem.value = item
      showEditDialog.value = true
    }

    const editItem = (item: TableRecord) => {
      _selectedItem.value = { ...item }
      showEditDialog.value = true
    }

    const getSanitizedData = (
      customField: CustomFieldRecord | undefined,
      customValue: string | null
    ): string | null => {
      // Nullify disabled fields.
      if (customField?.active !== 1) {
        return null
      }
      // If the component is not a combo no need to sanitize.
      if (customField.combo === 1) {
        return customValue
      }
      // Search select options. If there is not a match return null.
      const fieldDefinition = getFieldDefinition(customField.id!)
      let foundOption
      if (fieldDefinition) {
        foundOption = fieldDefinition.selectOptions.find((option: ISimpleOption) => {
          return option.value === customValue
        })
      }
      return isDefined(foundOption) ? customValue : null
    }

    const setSelectedItem = (item: CustomFieldRecord | undefined) => {
      _selectedItem.value = item ? { ...item } : undefined
      _backupItem.value = item ? { ...item } : undefined
    }

    const getAutoCompleteValues = async (
      definition: ICustomFieldDefinition
    ): Promise<Array<string>> => {
      let values: Array<string> = []
      if (definition.item && definition.item.autoComplete) {
        const response = await apiStore.getMany(EndPoint.People, {
          isDistinct: true,
          fields: [definition.valueField]
        })
        if (response?.data && response?.data?.count > 0) {
          const results: Array<CustomFieldRecord> = response.data
            .results as Array<CustomFieldRecord>

          values = results
            .filter((customFieldRecord) => {
              return customFieldRecord[definition.valueField] !== null
            })
            .map((customFieldRecord) => {
              return customFieldRecord[definition.valueField]
            })
        }
      }
      return values
    }

    const sanitizePerson = (item: PeopleRecord) => {
      if (item) {
        _allFieldDefinitions.value.forEach(async (fieldDefinition) => {
          if (fieldDefinition.item?.enabled === 1) {
            item[fieldDefinition.valueField] = getSanitizedData(
              fieldDefinition.item,
              item[fieldDefinition.valueField]
            )
          }
        })
      }
    }

    /**
     * Updates table headers in response to any changes.
     */
    const updateHeaders = async () => {
      const allHeaders = await getTableHeaders(TableStoreId.CustomFields, i18n)
      headers.value = allHeaders
    }

    return {
      confirmDelete,
      createNewItem,
      deleteOne,
      editItem,
      finalizeDelete,
      finalizeEdit,
      getFieldDefinition,
      loadItems,
      revertEdit,
      sanitizePerson,
      save,
      setSelectedItem,
      updateHeaders,
      activeFieldDefinitions,
      count,
      customFieldsLoaded,
      defaultEditorMode,
      defaultSort,
      disabledFieldDefinitions,
      editorMode,
      filterableFieldDefinitions,
      filteredHeaders,
      headers,
      items,
      selectedItem,
      showEditDialog,
      showDeleteDialog,
      workStatus
    } as unknown as ICustomFieldsStore
  }
)

import { Slug } from '@/enums/Slug'
import Auth0Plugin, { type Auth0PluginOptions } from '@fusion/auth'
import type { Router } from 'vue-router'

const permissionsOptions = {
  simple: [
    {
      slug: Slug.EditFitTests,
      propertyName: 'allowEditFitTests'
    },
    {
      slug: Slug.EditSettings,
      propertyName: 'allowEditSettings'
    }
  ]
}

export const getAuthOptions = (router: Router): Auth0PluginOptions => {
  const authOptions: Auth0PluginOptions = {
    onRedirectCallback: (appState: { targetUrl: string }) => {
      router
        .push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
        .catch(() => {})
    },
    redirectSuffix: '/',
    accountsHost: APP_ENV.VITE_APP_ACCOUNTS_HOST,
    audience: APP_ENV.VITE_APP_AUTH0_AUDIENCE,
    cacheLocation: 'localstorage',
    clientID: APP_ENV.VITE_APP_AUTH0_CLIENT_ID,
    domain: APP_ENV.VITE_APP_AUTH0_DOMAIN,
    useRefreshTokens: APP_ENV.VITE_APP_AUTH0_USE_REFRESH_TOKENS.toLowerCase() === 'true',
    permissionsOptions
  }

  return authOptions
}

export default Auth0Plugin

import { TableRecord } from '@/models/TableRecord'

export class FitTestRecord extends TableRecord {
  public testDate: string | null = null
  public dueDate: string | null = null
  public operator: string | null = null
  public maskSize: string | null = null
  public description: string | null = null
  public avgAmbient: number | null = null
  public overallFf: number | null = null
  public overallPass: number | null = null
  public serialNumber: string | null = null
  public n95: boolean = false
  public firstName: string | null = null
  public lastName: string | null = null
  public idNumber: string | null = null
  public company: string | null = null
  public location: string | null = null
  public note: string | null = null
  public custom1Label: string | null = null
  public custom1Data: string | null = null
  public custom2Label: string | null = null
  public custom2Data: string | null = null
  public custom3Label: string | null = null
  public custom3Data: string | null = null
  public custom4Label: string | null = null
  public custom4Data: string | null = null
  public maskManufacturer: string | null = null
  public maskModel: string | null = null
  public maskStyle: string | null = null
  public approval: string | null = null
  public ffPassLevel: number | null = null
  public maskDescription: string | null = null
  public protocolName: string | null = null
  public protocolModel: string | null = null
  public ambientPurge: number | null = null
  public ambientSample: number | null = null
  public maskPurge: number | null = null
  public period: string | null = null
  public endOnFail: boolean = false
  public algorithm: number | null = null
  public numExercises: number | null = null
  public exercise1: string | null = null
  public maskSample1: number | null = null
  public exclude1: boolean = false
  public fitFactor1: number | null = null
  public pass1: boolean = false
  public exercise2: string | null = null
  public maskSample2: number | null = null
  public exclude2: boolean | null = null
  public fitFactor2: number | null = null
  public pass2: boolean | null = null
  public exercise3: string | null = null
  public maskSample3: number | null = null
  public exclude3: boolean = false
  public fitFactor3: number | null = null
  public pass3: boolean = false
  public exercise4: string | null = null
  public maskSample4: number | null = null
  public exclude4: boolean = false
  public fitFactor4: number | null = null
  public pass4: boolean = false
  public exercise5: string | null = null
  public maskSample5: number | null = null
  public exclude5: boolean = false
  public fitFactor5: number | null = null
  public pass5: boolean = false
  public exercise6: string | null = null
  public maskSample6: number | null = null
  public exclude6: boolean = false
  public fitFactor6: number | null = null
  public pass6: boolean = false
  public exercise7: string | null = null
  public maskSample7: number | null = null
  public exclude7: boolean = false
  public fitFactor7: number | null = null
  public pass7: boolean = false
  public exercise8: string | null = null
  public maskSample8: number | null = null
  public exclude8: boolean = false
  public fitFactor8: number | null = null
  public pass8: boolean = false
  public exercise9: string | null = null
  public maskSample9: number | null = null
  public exclude9: boolean = false
  public fitFactor9: number | null = null
  public pass9: boolean = false
  public exercise10: string | null = null
  public maskSample10: number | null = null
  public exclude10: boolean = false
  public fitFactor10: number | null = null
  public pass10: boolean = false
  public exercise11: string | null = null
  public maskSample11: number | null = null
  public exclude11: boolean = false
  public fitFactor11: number | null = null
  public pass11: boolean = false
  public exercise12: string | null = null
  public maskSample12: number | null = null
  public exclude12: boolean = false
  public fitFactor12: number | null = null
  public pass12: boolean = false
  public comfortScore: string | null = null
  public userCompetency: string | null = null
  public comfortValidation: string | null = null
  public fitTestPerformed: boolean = false
  public reserved1: string | null = null
  public reserved2: string | null = null
  public reserved3: string | null = null
  public reserved4: string | null = null
}

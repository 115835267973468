<template>
  <div style="display: flex">
    <ButtonIcon
      @click="open"
      :text="t('buttons.showHideColumns')"
      icon="mdi-table-column-plus-before"
    ></ButtonIcon>
    <BaseDialog
      :title="t('dialogs.headers.showHideColumns')"
      v-model="showEditColumns"
      v-bind="$attrs"
      :max-width="500"
      icon="mdi-filter"
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            md="6"
            lg="6"
            xl="6"
            xxl="6"
            class="pa-0 ma-0"
            v-for="header in headers"
            :key="header.key"
          >
            <v-checkbox
              v-if="header.key !== 'actions'"
              :disabled="isDisabled && selectedHeaders[header.key] === true"
              density="compact"
              :hide-details="true"
              :key="header.key"
              :label="header.title"
              v-model="selectedHeaders[header.key]"
            />
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:actions>
        <ButtonSecondary @click="close" :text="t('buttons.cancel')" />
        <v-spacer />
        <ButtonPrimary @click="save" :text="t('buttons.save')" />
      </template>
    </BaseDialog>
  </div>
</template>

<script setup lang="ts">
import type { ITableHeader } from '@/interfaces/ITableHeader'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import { onUpdated, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import ButtonIcon from '../buttons/ButtonIcon.vue'

// STORES, IMPORTS, & COMPOSABLES
const settingsStore = useSettingsStore()
const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS
const props = defineProps({
  headers: {
    type: Array<ITableHeader>,
    default: () => {
      return []
    }
  },
  tableStoreId: { type: String, required: true }
})

const emit = defineEmits<{
  (e: 'save'): void
}>()

// REACTIVE VARIABLES
const selectedHeaders = ref<Record<string, boolean>>({})
const showEditColumns = ref<boolean>(false)
const isDisabled = ref<boolean>(false)

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS
onUpdated(() => {
  const disabledHeaders = settingsStore.disabledHeaders[props.tableStoreId]
  if (!disabledHeaders) {
    settingsStore.disabledHeaders[props.tableStoreId] = []
  }
  props.headers.forEach((header: ITableHeader) => {
    selectedHeaders.value[header.key] = !disabledHeaders.includes(header.key)
  })
})

// WATCHERS
watch(
  selectedHeaders,
  () => {
    checkDisabled()
  },
  { deep: true }
)

// FUNCTIONS
const save = () => {
  const newDisabledHeaders: Array<string> = []
  const keys = Object.keys(selectedHeaders.value)
  keys.forEach((key) => {
    if (!selectedHeaders.value[key]) {
      newDisabledHeaders.push(key)
    }
  })
  settingsStore.disabledHeaders[props.tableStoreId] = newDisabledHeaders
  showEditColumns.value = false
  emit('save')
}

const checkDisabled = () => {
  let count = 0
  const keys = Object.keys(selectedHeaders.value)
  keys.forEach((key) => {
    if (selectedHeaders.value[key] === true) {
      count++
    }
  })
  isDisabled.value = count <= 2
}

const close = () => {
  showEditColumns.value = false
}

const open = () => {
  showEditColumns.value = true
}
</script>

<style lang="scss" scoped></style>

import { EndPoint } from '@/enums/EndPoint'
import { MessageType } from '@/enums/MessageType'
import { StoreId } from '@/enums/StoreId'
import type { ISnackbarMessage } from '@/interfaces/ISnackBarMessage'
import type { AxiosError } from 'axios'
import { defineStore } from 'pinia'
import { computed, nextTick, ref } from 'vue'
import { useI18n, type Composer } from 'vue-i18n'

const DEFAULT_TIMEOUT = 5000

/**
 * Formats an error message for the snackbar.
 * @param endpoint The endpoint that caused the error.
 * @param error An Error instance.
 * @param i18n I18N instance.
 * @param timeout Numeric timeout for snackbar display.
 * @returns
 */
function formatAxiosErrorMessage(
  endpoint: EndPoint,
  error: any,
  i18n: Composer<{}, {}, {}, string, never, string>,
  timeout: number
): ISnackbarMessage {
  const name = error.response?.data?.error?.name || error.name
  let text = ''
  switch (name) {
    case 'SequelizeUniqueConstraintError': {
      const fields = Object.keys(error.response.data.error.fields)
      let key = `errors.axios.${endpoint}.constraintError`
      if (fields.length > 0) {
        key += `:${fields.join('_')}`
      }
      text = i18n.t(key)
      break
    }

    default: {
      switch (error.code) {
        case 'ERROR_NETWORK': {
          text = i18n.t(`errors.axios.${error.code.toLowerCase()}`)
          break
        }
        default: {
          text = `${error.message}`
          break
        }
      }
    }
  }
  return { text, type: MessageType.DatabaseError, timeout }
}

export const useSnackbarStore = defineStore(StoreId.SnackBars, () => {
  // STORES, IMPORTS, & COMPOSABLES
  const i18n = useI18n({ useScope: 'global' })

  // REACTIVE VARIABLES
  const messages = ref<Array<ISnackbarMessage>>([])
  const show = ref<boolean>(false)

  // COMPUTED PROPERTIES
  const current = computed((): ISnackbarMessage => {
    if (messages.value.length > 0) {
      return messages.value[0]
    }
    return { text: '', type: MessageType.Info, timeout: -1 }
  })

  // FUNCTIONS - PUBLIC

  /**
   * Close/hide the current snackbar.
   */
  const close = () => {
    messages.value.shift()
    show.value = false
    nextTick(() => {
      if (messages.value.length > 0) {
        show.value = true
      }
    })
  }

  /**
   * Add an Axios message to the snackbar queue.
   * @param endpoint The endpoint that caused the error.
   * @param error An Error instance.
   * @param timeout Numeric timeout for snackbar display.
   */
  const addAxiosMessage = (
    endpoint: EndPoint,
    error: AxiosError,
    timeout: number = DEFAULT_TIMEOUT
  ) => {
    const errorMessage = formatAxiosErrorMessage(endpoint, error, i18n, timeout)
    const found = messages.value.find((message: ISnackbarMessage) => {
      return message.text === errorMessage.text
    })
    if (!found) {
      messages.value.push(errorMessage)
      show.value = true
    }
  }

  /**
   * Add a generic message to the snackbar queue.
   * @param text Message to be displayed.
   * @param type The message type which sets an associated icon.
   * @param timeout Numeric timeout for snackbar display.
   */
  const addMessage = (
    text: string,
    type: MessageType = MessageType.Info,
    timeout: number = DEFAULT_TIMEOUT
  ) => {
    messages.value.push({ text, type, timeout })
    show.value = true
  }

  return { addAxiosMessage, addMessage, close, current, show, messages }
})

import { toBoolean, toFormFactor, toNumber, toText } from '@/composables/utils'
import { EndPoint } from '@/enums/EndPoint'
import type { IImportDefinition } from '@/interfaces/IImportDefinition'
import { useI18n } from 'vue-i18n'

export const getImportDefinition = (): IImportDefinition => {
  const i18n = useI18n({ useScope: 'global' })
  return {
    endpoint: EndPoint.Masks,
    keyFields: ['maskDescription'],
    friendlyName: i18n.t('tables.names.masks'),
    fields: [
      {
        title: i18n.t('fields.masks.manufacturer'),
        name: 'maskManufacturer',
        aliases: ['Manufacturer'],
        convert: toText(64)
      },
      {
        title: i18n.t('fields.masks.model'),
        name: 'maskModel',
        aliases: ['Model'],
        convert: toText(64),
        required: true
      },
      {
        title: i18n.t('fields.masks.style'),
        name: 'maskStyle',
        aliases: ['Style'],
        convert: toText(64),
        required: true
      },
      {
        title: i18n.t('fields.masks.approval'),
        name: 'approval',
        convert: toText(64),
        required: true,
        defaultValue: ''
      },
      {
        title: i18n.t('fields.masks.passLevel'),
        name: 'ffPassLevel',
        aliases: ['Pass Level'],
        convert: toNumber,
        required: true
      },
      {
        title: i18n.t('fields.masks.n95'),
        name: 'n95',
        aliases: ['MaskN95'],
        convert: toBoolean,
        required: true
      },
      {
        title: i18n.t('fields.masks.maskDescription'),
        name: 'maskDescription',
        convert: toText(64),
        required: true
      },
      {
        title: i18n.t('fields.masks.autoDescription'),
        name: 'autoDescription',
        convert: toBoolean,
        required: true
      },
      {
        title: i18n.t('fields.masks.reserved1'),
        name: 'reserved1',
        aliases: ['Mask Form Factor'],
        convert: toFormFactor
      }
    ]
  }
}

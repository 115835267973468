<template>
  <div></div>
  <v-container class="ma-1 pa-1" style="position: relative" fluid>
    <v-row>
      <v-col>
        <CalendarEvents
          @change="updateEvents"
          @event-selected="showEventDetails"
          :events="eventsStore.items"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { TableStoreId } from '@/enums/TableStoreId'
import type { IEventsStore } from '@/interfaces/api/IEventsStore'
import type { ICalendarRange } from '@/interfaces/ICalendarRange'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import '@schedule-x/theme-default/dist/index.css'

// STORES, IMPORTS, & COMPOSABLES
const eventsStore = TableStoreFactory.get(TableStoreId.Events) as IEventsStore

// PROPS & EMITS

// REACTIVE VARIABLES

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// FUNCTIONS
const updateEvents = (range: ICalendarRange) => {
  eventsStore.loadEventsByRange(range)
}

const showEventDetails = (eventId: number) => {
  console.info('eventId: ', eventId)
  eventsStore.showEditDialog = true
}
</script>

<style lang="scss" scoped></style>

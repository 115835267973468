import { Rule } from '@/enums/Rule'
import type { IRuleWithPayload } from '@/interfaces/IRuleWithPayload'
import * as EmailValidator from 'email-validator'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { isDefined } from './utils'

/**
 * Manages all rules related to user import and selection controls.
 * @returns
 */
export function useRulesManager() {
  const rules = ref<Map<Rule, Function>>(new Map<Rule, Function>())
  const i18n = useI18n({ useScope: 'global' })

  rules.value.set(
    Rule.DuplicateString,
    (value: string, payload: Array<string> = [], errorSlug: string) => {
      if (
        !payload
          .map((item) => {
            return item.toLowerCase()
          })
          .includes(value?.toLowerCase())
      ) {
        return true
      }
      return i18n.t(errorSlug)
    }
  )

  rules.value.set(Rule.ValidEmail, (value: string) => {
    if (!value) return true
    const isValid = EmailValidator.validate(value)
    if (isValid) {
      return true
    }
    return i18n.t('validation.invalidEmail')
  })

  rules.value.set(Rule.ValidNumber, (value: number) => {
    if (!isNaN(value)) return true
    return i18n.t('validation.invalidNumber')
  })

  rules.value.set(Rule.Required, (value: string) => {
    if (isDefined(value)) return true
    return i18n.t('validation.valueRequired')
  })

  rules.value.set(Rule.ValidDate, (value: string) => {
    if (!value) return true
    const date = new Date(value)
    if (isNaN(date.getMilliseconds())) {
      return i18n.t('validation.invalidDate')
    }
    return true
  })

  /**
   * Returns a set of rules.
   * @param keys A single rule, a single rule with a payload or any array of rules.
   * @returns
   */
  const getRules = (keys: Rule | IRuleWithPayload | Array<Rule | IRuleWithPayload>) => {
    if (!Array.isArray(keys)) {
      keys = [keys]
    }
    return keys.map((rule: Rule | IRuleWithPayload) => {
      if (typeof rule === 'string') {
        return rules.value.get(rule)
      }
      const foundRule = rules.value.get(rule.key)
      if (foundRule) {
        return (value: any) => {
          return foundRule(value, rule.payload, rule.errorSlug)
        }
      }
    })
  }

  return {
    getRules
  }
}

<template>
  <v-expansion-panel icon="mdi-help" :title="title">
    <template v-slot:title>
      <v-checkbox
        :indeterminate="isIndeterminate"
        density="compact"
        :hide-details="true"
        v-model:model-value="allOn"
        @click.prevent.stop="toggleAllValues"
      />{{ title }}
    </template>
    <template v-slot:text>
      <div class="scroll-box">
        <v-checkbox
          v-for="(value, index) in values"
          density="compact"
          :hide-details="true"
          :key="index"
          :label="value"
          :model-value="dashboardstore.filters[valueField][value]"
          @click="toggleSingleValue(value)"
        />
      </div>
    </template>
  </v-expansion-panel>
</template>

<script setup lang="ts">
import { useDashboardStore } from '@/stores/ui/DashboardStore'
import { computed, type PropType } from 'vue'

// STORES, IMPORTS, & COMPOSABLES
const dashboardstore = useDashboardStore()

// PROPS & EMITS
const props = defineProps({
  values: {
    type: Array as PropType<Array<string>>,
    default: () => {
      return []
    }
  },
  valueField: { type: String, required: true },
  title: { type: String }
})

const emit = defineEmits<{
  (e: 'change'): void
}>()

// REACTIVE VARIABLES

// COMPUTED PROPERTIES
const allOn = computed(() => {
  let allOn = false
  const filter = dashboardstore.filters[props.valueField]
  if (filter && props.values.length > 0) {
    allOn = !props.values.some((value) => {
      return filter[value] === false
    })
  }
  return allOn
})

const allOff = computed(() => {
  let allOff = true
  const filter = dashboardstore.filters[props.valueField]
  if (filter) {
    allOff = !props.values.some((value) => {
      return filter[value] === true
    })
  }
  return allOff
})

const isIndeterminate = computed(() => {
  return !allOn.value && !allOff.value
})

// LIFECYCLE HOOKS

// WATCHERS

// FUNCTIONS
const toggleSingleValue = (value: string) => {
  const filter = dashboardstore.filters[props.valueField]
  if (filter[value]) {
    filter[value] = false
  } else {
    filter[value] = true
  }
  emit('change')
}

const toggleAllValues = () => {
  const filter = dashboardstore.filters[props.valueField]
  const newValue = isIndeterminate.value ? true : !allOn.value
  props.values.forEach((value: string) => {
    filter[value] = newValue
  })
  emit('change')
}
</script>

<style lang="scss" scoped>
.scroll-box {
  max-height: 20rem;
  overflow: auto;
  margin-bottom: 2rem;
  border-bottom: 1px solid $color-dark-16;
  border-top: 1px solid $color-dark-16;
}
</style>

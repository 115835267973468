import { SettingsTab } from '@/enums/SettingsTab'
import { StoreId } from '@/enums/StoreId'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useSchedulerStore = defineStore(
  StoreId.Scheduler,
  () => {
    // STORES, IMPORTS, & COMPOSABLES

    // REACTIVE VARIABLES
    const selectedTab = ref<string>(SettingsTab.Account)

    // WATCHERS

    // COMPUTED

    // FUNCTIONS

    return {
      selectedTab
    }
  },
  {
    persist: {
      storage: localStorage,
      pick: []
    }
  }
)

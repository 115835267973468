/**
 * Class that mimics a complex Enum. Used to define locales.
 */
export class LocaleDefinition {
  static readonly German = new LocaleDefinition('Deutsch (German)', 'de', 'DE', 'de')
  static readonly English = new LocaleDefinition('English', 'en', 'US', 'en')
  static readonly Spanish = new LocaleDefinition('Espanol (Spanish)', 'es', 'ES', 'es')
  static readonly French = new LocaleDefinition('Francais (French)', 'fr', 'FR', 'fr')
  static readonly Japanese = new LocaleDefinition('日本語 (Japanese)', 'ja', 'JP', 'ja')
  static readonly Korean = new LocaleDefinition('한국어 (Korean)', 'ko', 'KR', 'ko')
  static readonly Portuguese = new LocaleDefinition('Português (Portuguese)', 'pt', 'BR', 'pt')
  static readonly ChineseSimplified = new LocaleDefinition(
    '中文 (Chinese Simplified)',
    'zh',
    'CN',
    'zHans'
  )
  static readonly ChineseTraditional = new LocaleDefinition(
    '中文 (Chinese Traditional)',
    'zh',
    'TW',
    'zHant'
  )

  protected constructor(
    public readonly title: string,
    public readonly language: any,
    public readonly region: string,
    public readonly vuetify: string
  ) {
    this.code = `${language}-${region}`
  }

  public readonly code!: string

  toString() {
    return this.code
  }
}

<template>
  <BasePage>
    <v-container class="ma-0 pa-0" fluid>
      <v-row>
        <v-col rowspan="1" cols="12" xs="12" sm="12" md="12" lg="3" xl="2" xxl="2">
          <v-expansion-panels :elevation="0" :rounded="false">
            <DashboardFilter
              v-for="definition in customFieldsStore.filterableFieldDefinitions"
              :key="definition.id"
              :values="getFilterItems(definition)"
              :value-field="definition.valueField"
              :title="t('labels.filterBy', { label: definition.item?.labelName })"
              @change="refreshStatistics"
            />
          </v-expansion-panels>
        </v-col>
        <v-col style="display: flex" cols="12" xs="12" sm="12" md="12" lg="9" xl="10" xxl="10">
          <v-container class="ma-0 pa-0" fluid>
            <v-row>
              <v-col style="display: flex" cols="12" xs="12" sm="12" md="12" lg="6" xl="6" xxl="6">
                <StatsSection
                  v-model="dashboardStore.stats.testing"
                  prepend-icon="mdi-text-box-check"
                  :title="t('headers.testingStatus')"
                  :subtitle="t('text.testingStatus')"
                />
              </v-col>
              <v-col
                v-if="settingsStore.globals.training.enabled"
                style="display: flex"
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="6"
                xl="6"
                xxl="6"
              >
                <StatsSection
                  v-model="dashboardStore.stats.training"
                  prepend-icon="mdi-school"
                  :title="t('headers.trainingStatus')"
                  :subtitle="t('text.trainingStatus')"
                  :expires="settingsStore.globals.training.expires"
                />
              </v-col>
              <v-col
                v-if="settingsStore.globals.clearance.enabled"
                style="display: flex"
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="6"
                xl="6"
                xxl="6"
              >
                <StatsSection
                  v-model="dashboardStore.stats.clearance"
                  prepend-icon="mdi-medical-bag"
                  :title="t('headers.clearanceStatus')"
                  :subtitle="t('text.clearanceStatus')"
                  :expires="settingsStore.globals.clearance.expires"
                  :msg-compliant="t('labels.cleared')"
                  :msg-no-record="t('labels.notCleared')"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </BasePage>
</template>

<script setup lang="ts">
import type { ICustomFieldDefinition } from '@/interfaces/api/ICustomFieldDefinition'
import type { ISimpleOption } from '@/interfaces/ISimpleOption'
import { useCustomFieldsStore } from '@/stores/db/CustomFieldsStore'
import { useDashboardStore } from '@/stores/ui/DashboardStore'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import type { DebouncedFunc } from 'lodash'
import debounce from 'lodash/debounce'
import { onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'

// STORES, IMPORTS, & COMPOSABLES
const customFieldsStore = useCustomFieldsStore()
const dashboardStore = useDashboardStore()
const settingsStore = useSettingsStore()
const { t } = useI18n({ useScope: 'global' })

// PRIVATE VARIABLES
let _searchPending: DebouncedFunc<any>

// PROPS & EMITS

// REACTIVE VARIABLES

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS
onBeforeMount(async () => {
  await customFieldsStore.loadItems()
  await dashboardStore.loadStatistics()
})

// FUNCTIONS
const getFilterItems = (definition: ICustomFieldDefinition): Array<string> => {
  if (definition.item?.combo === 0) {
    return definition.selectOptions.map((option: ISimpleOption) => {
      return option.title
    })
  }
  return definition.autoValues
}

const refreshStatistics = () => {
  if (_searchPending) {
    _searchPending.cancel()
  }
  _searchPending = debounce(async () => {
    dashboardStore.loadStatistics()
  }, 1250)
  _searchPending()
}
</script>

<style lang="scss" scoped></style>

import { toISODate, toText } from '@/composables/utils'
import { EndPoint } from '@/enums/EndPoint'
import type { IImportDefinition } from '@/interfaces/IImportDefinition'
import { useI18n } from 'vue-i18n'

export const getImportDefinition = (): IImportDefinition => {
  const i18n = useI18n({ useScope: 'global' })
  return {
    endpoint: EndPoint.Devices,
    ignoredFields: ['calCertId', 'calibrationCert', 'calibrationCertificate'],
    keyFields: ['serialNumber'],
    friendlyName: i18n.t('tables.names.devices'),
    fields: [
      {
        title: i18n.t('fields.devices.name'),
        name: 'name',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.devices.serialNumber'),
        name: 'serialNumber',
        convert: toText(64),
        required: true
      },
      {
        title: i18n.t('fields.devices.model'),
        name: 'model',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.devices.lastCalibration'),
        name: 'lastCalibration',
        convert: toISODate
      },
      {
        title: i18n.t('fields.devices.location'),
        name: 'location',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.devices.planInfo'),
        name: 'planInfo',
        aliases: ['Plan Information'],
        convert: toText(64)
      },
      {
        title: i18n.t('fields.devices.planExpiration'),
        name: 'planExpiration',
        convert: toISODate
      }
    ]
  }
}

import { TableStoreOption } from '@/classes/TableStoreOption'
import { getStoreDefinition } from '@/composables/StoreDefinition'
import { EndPoint } from '@/enums/EndPoint'
import { SqlComparison } from '@/enums/SqlComparison'
import { SqlOrder } from '@/enums/SqlOrder'
import { TableStoreId } from '@/enums/TableStoreId'
import { View } from '@/enums/View'
import type { IPeopleStore } from '@/interfaces/api/IPeopleStore'
import type { IQueryOptions } from '@/interfaces/api/IQueryOptions'
import type { ISimpleOption } from '@/interfaces/ISimpleOption'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useAPIStore } from './ApiStore'

let _store: IPeopleStore

export const usePeopleStore = () => {
  if (_store) {
    return _store
  }
  const useStore = defineStore(TableStoreId.People, getStoreDefinition(TableStoreOption.People))
  _store = useStore() as unknown as IPeopleStore
  _extend(_store)
  return _store
}

const _extend = (store: IPeopleStore) => {
  const apiStore = useAPIStore()

  // Override the default table with a view.
  store.useView(View.PeopleWithStatus)

  store.showNoteDialog = ref<boolean>(false) as unknown as boolean

  store.getSupervisorOptions = async (): Promise<Array<ISimpleOption>> => {
    const query: IQueryOptions = {
      isDistinct: true,
      fields: ['id', 'firstName', 'lastName'],
      where: [
        {
          field: 'isSupervisor',
          comparison: SqlComparison.Equal,
          value1: true
        }
      ],
      orderBy: [
        { field: 'lastName', order: SqlOrder.ASC },
        { field: 'firstName', order: SqlOrder.ASC }
      ]
    }
    const response = await apiStore.getMany(EndPoint.People, query)
    let options: Array<ISimpleOption> = []
    if (!response.error) {
      const supervisors = response.data?.results || []
      options = supervisors.map((item) => {
        return { title: `${item.lastName}, ${item.firstName}`, value: item.id! }
      })
    }
    return options
  }

  store.getDirectReports = async (personId: number): Promise<Array<string>> => {
    const query: IQueryOptions = {
      isDistinct: true,
      fields: ['id', 'firstName', 'lastName', 'idNumber'],
      where: [
        {
          field: 'supervisorId',
          comparison: SqlComparison.Equal,
          value1: personId
        }
      ],
      orderBy: [
        { field: 'lastName', order: SqlOrder.ASC },
        { field: 'firstName', order: SqlOrder.ASC }
      ]
    }
    const response = await apiStore.getMany(EndPoint.People, query)
    let options: Array<string> = []
    if (!response.error) {
      const supervisors = response.data?.results || []
      options = supervisors.map((item) => {
        return `${item.lastName}, ${item.firstName} (${item.idNumber})`
      })
    }
    return options
  }
}

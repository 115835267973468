<template>
  <v-form :fast-fail="false" @submit.prevent ref="form">
    <BaseDialog
      v-model="store.showEditDialog"
      v-bind="$attrs"
      :max-height="1000"
      :icon="icon"
      :persistent="true"
    >
      <slot name="default"></slot>
      <template v-slot:actions>
        <ButtonSecondary
          @click="cancelEdit"
          v-if="store.editorMode == EditorMode.Edit"
          :loading="store.workStatus?.save"
          :text="t('buttons.cancel')"
        />
        <ButtonSecondary
          v-if="store.editorMode == EditorMode.View"
          @click="closeView"
          :text="t('buttons.close')"
        />
        <v-spacer />
        <ButtonPrimary
          v-if="store.editorMode == EditorMode.Edit"
          @click="saveItem"
          :disabled="!form?.isValid || disableSave"
          type="submit"
          :loading="store.workStatus?.save"
          :text="t('buttons.save')"
        />
        <ButtonPrimary
          v-if="store.editorMode == EditorMode.View && allowEdit"
          @click="startEditing"
          :text="t('buttons.edit')"
        />
      </template>

      <!-- @vue-skip -->
      <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>
    </BaseDialog>
  </v-form>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { EditorMode } from '@/enums/EditorMode'
import type { TableStoreId } from '@/enums/TableStoreId'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { computed, ref, type PropType } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

const emit = defineEmits<{
  (e: 'cancelEdit'): void
  (e: 'closeView'): void
  (e: 'saveItem'): void
  (e: 'startEditing'): void
}>()

const props = defineProps({
  allowEdit: { type: Boolean, default: true },
  disableSave: { type: Boolean, default: false },
  tableStoreId: { type: String as PropType<TableStoreId>, required: true },
  validForm: { type: Boolean, default: false }
})

const store = TableStoreFactory.get(props.tableStoreId)

// REACTIVE VARIABLES
const form = ref<any>()

// COMPUTED PROPERTIES
const icon = computed(() => {
  return store.editorMode === EditorMode.View ? 'mdi-information' : 'mdi-pencil'
})

// LIFECYCLE HOOKS

// FUNCTIONS
const cancelEdit = () => {
  if (store.defaultEditorMode === EditorMode.View) {
    store.revertEdit()
  } else {
    store.finalizeEdit()
  }
  emit('cancelEdit')
}

const closeView = () => {
  store.finalizeEdit()
  emit('closeView')
}

const saveItem = async () => {
  const { valid } = await form.value.validate()
  if (valid) {
    emit('saveItem')
  }
}

const startEditing = () => {
  store.editorMode = EditorMode.Edit
  emit('startEditing')
}
</script>

<style lang="scss" scoped></style>

import type { ImportField } from '@/classes/ImportField'
import { EndPoint } from '@/enums/EndPoint'
import { SqlOrder } from '@/enums/SqlOrder'
import { ImportBooleanError } from '@/errors/ImportBooleanError'
import { ImportDateError } from '@/errors/ImportDateError'
import { ImportFormFactorError } from '@/errors/ImportFormFactorError'
import { ImportInvalidValueError } from '@/errors/ImportInvalidValueError'
import { ImportLengthError } from '@/errors/ImportLengthError'
import { ImportNumberError } from '@/errors/ImportNumberError'
import { ImportValueRequiredError } from '@/errors/ImportValueRequiredError'
import type { ITableSort } from '@/interfaces/ITableSort'
import dayjs from 'dayjs'

/**
 * Convert a TableSort to an orderBy. The sort for the UI is different fro mthe database.
 * @param sortBy A UI sort collection.
 * @returns
 */
export const convertTableSortToOrderBy = (
  sortBy: Array<{
    key: string
    order: 'asc' | 'desc'
  }>
) => {
  return sortBy.map((item: ITableSort) => {
    const sqlOrder = item.order.toLowerCase() === 'desc' ? SqlOrder.DESC : SqlOrder.ASC
    return { field: item.key, order: sqlOrder }
  })
}

/**
 * Convert an encoded file to a new File instance.
 * @param encodedFile Base64 encoded file.
 * @param fileName A file name.
 * @returns
 */
export const encodedToFile = (encodedFile: string, fileName: string): File | undefined => {
  const pieces = encodedFile.split(',')
  if (pieces.length >= 2) {
    const type = pieces[0].match(/:(.*?);/)![1]
    const base64 = atob(pieces[pieces.length - 1])
    let base64Length = base64.length
    const blobData = new Uint8Array(base64Length)
    while (base64Length--) {
      blobData[base64Length] = base64.charCodeAt(base64Length)
    }
    return new File([blobData], fileName, { type })
  }
}

/**
 * Formats the date with an optional time.
 * @param isoDate An ISO date atring value.
 * @param includeTime Flag that indicates whteher to include the time.
 * @returns
 */
export const formatDate = (
  isoDate: string | null | undefined,
  includeTime: boolean = false,
  localeCode?: string
): string => {
  let dateValue = '-'
  if (isoDate === null || isoDate === undefined) {
    return dateValue
  }
  const localDate = new Date(isoDate)

  if (isDefined(localDate)) {
    dateValue = localDate.toLocaleDateString(localeCode?.substring(0, 2))
    if (includeTime) {
      dateValue += ` ${dayjs(localDate).format('HH:MM')}`
    }
  }
  return dateValue
}

/**
 * Returns true if the value is defined.
 * @param value Any item value.
 * @param noEmptyObjects By default an empty object is defined.
 * @returns
 */
export const isDefined = (value: any, noEmptyObjects: boolean = false): boolean => {
  if (value === undefined || value === null) {
    return false
  }

  const type = typeof value
  switch (type) {
    case 'number':
    case 'bigint': {
      return isNaN(value) === false
    }
    case 'string': {
      return value !== ''
    }
    case 'object': {
      if (value.getMilliseconds) {
        return !isNaN(value.getMilliseconds())
      }
      if (noEmptyObjects) {
        return Object.keys(value).length !== 0
      }
    }
  }
  return true
}

/**
 * Takes any object and converts all empty strings to null values.
 * @param item An object instance.
 */
export const nullifyEmptyStrings = (
  items: Array<Record<string, any>> | Record<string, any>,
  endpoint: EndPoint
): void => {
  // Allow fo exceptions!
  const excludes: Map<EndPoint, Array<string>> = new Map()
  excludes.set(EndPoint.Masks, ['approval'])
  excludes.set(EndPoint.FitTests, [])
  excludes.set(EndPoint.People, [])
  if (!Array.isArray(items)) {
    items = [items]
  }
  items.forEach((item: Record<string, any>) => {
    const keys = Object.keys(item)
    keys.forEach((key: string) => {
      const excludedFields = excludes.get(endpoint)
      if (!excludedFields || (excludedFields.length > 0 && !excludedFields.includes(key))) {
        if (typeof item[key] === 'string' && item[key].trim() === '') {
          item[key] = null
        }
      }
    })
  })
}

/**
 * Returns the value stripped fo symbols and lower cased.
 * @param value Any string value.
 * @returns
 */
export const sanitizeAlphaNumeric = (value: string): string => {
  if (!isDefined(value)) {
    return ''
  }
  value = value.trim().toLowerCase()
  value = value.replace(/[^a-zA-Z0-9]/g, '')
  return value
}

/**
 * Converts the protocol algorithm to a numeric value.
 * @param value A string that represents an algorithm.
 * @param importField An ImportField instance.
 * @returns
 */
export const toAlgorithm = (value: string, importField: ImportField): number | null => {
  if (!isDefined(value)) {
    if (isDefined(importField.defaultValue)) {
      return importField.defaultValue
    }
    if (importField.required) {
      throw new ImportValueRequiredError(`A value is required for ${importField.title}.`)
    }
    return null
  }

  switch (value.toLowerCase()) {
    case 'original':
    case '0': {
      return 0
    }
    case 'algorithm1':
    case '1': {
      return 1
    }
    case 'qlft':
    case '2': {
      return 2
    }
    default: {
      throw new ImportInvalidValueError(
        `Value for ${importField.title} must be 'ORIGINAL', 'ALGORITHM1' or 'QLFT'.`
      )
    }
  }
}

/**
 * Converts an import string value to a boolean.
 * @param value A text string that represents a boolean [TRUE, FALSE, 0, 1, YES, NO].
 * @param importField An ImportField instance.
 * @returns
 */
export const toBoolean = (value: string, importField: ImportField): boolean | null => {
  if (!isDefined(value)) {
    if (isDefined(importField.defaultValue)) {
      return importField.defaultValue
    }
    if (importField.required) {
      throw new ImportValueRequiredError(`Boolean value is required for ${importField.title}.`)
    }
    return null
  }

  const sanitizedValue = sanitizeAlphaNumeric(value)
  switch (sanitizedValue) {
    case 'true':
    case 'yes':
    case '1': {
      return true
    }
    case 'false':
    case 'no':
    case '0': {
      return false
    }
    default: {
      throw new ImportBooleanError(
        `Value for ${importField.title} is not a valid boolean: ${value}.`
      )
    }
  }
}

/**
 * Converts an import string value to a proper form factor value.
 * @param value A text string that represents a form factor [1, elastomeric, fullfacehalfface, 0, filtering, filteringfacepiece].
 * @param importField An ImportField instance.
 * @returns
 */
export const toFormFactor = (value: string, importField: ImportField): string | null => {
  if (!isDefined(value)) {
    if (isDefined(importField.defaultValue)) {
      return importField.defaultValue
    }
    if (importField.required) {
      throw new ImportValueRequiredError(`Form Factor value is required for ${importField.title}.`)
    }
    return null
  }

  const sanitizedValue = sanitizeAlphaNumeric(value)
  switch (sanitizedValue) {
    case '1':
    case 'elastomeric':
    case 'fullfacehalfface': {
      return '1'
    }
    case '0':
    case 'filtering':
    case 'filteringfacepiece': {
      return '0'
    }
    default: {
      throw new ImportFormFactorError(
        `Value for ${importField.title} is not a valid form factor: ${value}.`
      )
    }
  }
}

/**
 * Converts an import string value to an ISODate string.
 * @param value A string that represents a date.
 * @param importField An ImportField instance.
 * @returns
 */
export const toISODate = (value: string, importField: ImportField): string | null => {
  if (!isDefined(value)) {
    if (isDefined(importField.defaultValue)) {
      return importField.defaultValue
    }
    if (importField.required) {
      throw new ImportValueRequiredError(`ISO Date value is required for ${importField.title}.`)
    }
    return null
  }

  const dateValue = Date.parse(value)
  if (isNaN(dateValue)) {
    throw new ImportDateError(
      `Value for ${importField.title} is not a valid ISO Date string: ${value}.`
    )
  }
  return new Date(value).toISOString()
}

/**
 * Converts an import string to a numeric value.
 * @param value A string that represents a number.
 * @param importField An ImportField instance.
 * @returns
 */
export const toNumber = (value: string, importField: ImportField): number | null => {
  if (!isDefined(value)) {
    if (isDefined(importField.defaultValue)) {
      return importField.defaultValue
    }
    if (importField.required) {
      throw new ImportValueRequiredError(`Number value is required for ${importField.title}.`)
    }
    return null
  }

  const numericValue = Number.parseFloat(value)
  if (isNaN(numericValue)) {
    throw new ImportNumberError(`Value for ${importField.title} is not a valid number: ${value}.`)
  }
  return numericValue
}

/**
 * Converts an overall pass value to a number.  PASS = 1, FAIL = 0.
 * @param value A string that represents a number.
 * @param importField An ImportField instance.
 * @returns
 */
export const toPassFail = (value: string, importField: ImportField): number | null => {
  if (!isDefined(value)) {
    if (isDefined(importField.defaultValue)) {
      return importField.defaultValue
    }
    if (importField.required) {
      throw new ImportValueRequiredError(`PassFail is required for ${importField.title}.`)
    }
    return null
  }

  switch (value.toLowerCase()) {
    case 'pass':
    case '1':
    case 'yes':
    case 'true': {
      return 1
    }
    default: {
      throw new ImportInvalidValueError(`Value for ${importField.title} must be 'PASS' or 'FAIL'.`)
    }
  }
}

/**
 * Converts an import string to a proper database string.
 * @param value A simple string value.
 * @param importField An ImportField instance.
 * @returns
 */
export const toText = (maxLength: number): Function => {
  return (value: string, importField: ImportField): string | null => {
    if (!isDefined(value)) {
      if (isDefined(importField.defaultValue)) {
        return importField.defaultValue
      }
      if (importField.required) {
        throw new ImportValueRequiredError(`Text value is required for ${importField.title}.`)
      }
      return null
    }

    if (value.length > maxLength) {
      throw new ImportLengthError(
        `Text value for ${importField.title} exceeds maximum length of ${maxLength}: ${value}.`
      )
    }
    return value
  }
}

import { toBoolean, toISODate, toText } from '@/composables/utils'
import { EndPoint } from '@/enums/EndPoint'
import type { IImportDefinition } from '@/interfaces/IImportDefinition'
import { v4 as uuidv4 } from 'uuid'
import { useI18n } from 'vue-i18n'

export const getImportDefinition = (): IImportDefinition => {
  const i18n = useI18n({ useScope: 'global' })
  return {
    endpoint: EndPoint.People,
    keyFields: ['firstName', 'lastName', 'idNumber'],
    constraints: [['email']],
    ignoredFields: ['supervisorId'],
    friendlyName: i18n.t('tables.names.people'),
    fields: [
      {
        title: i18n.t('fields.people.lastName'),
        name: 'lastName',
        convert: toText(64),
        required: true
      },
      {
        title: i18n.t('fields.people.firstName'),
        name: 'firstName',
        convert: toText(64),
        required: true
      },
      {
        title: i18n.t('fields.people.idNumber'),
        name: 'idNumber',
        convert: toText(64),
        required: true
      },
      {
        title: i18n.t('fields.people.company'),
        name: 'company',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.people.location'),
        name: 'location',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.people.note'),
        name: 'note',
        convert: toText(128)
      },
      {
        title: i18n.t('fields.people.custom1Label'),
        name: 'custom1Label',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.people.custom1Data'),
        name: 'custom1Data',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.people.custom2Label'),
        name: 'custom2Label',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.people.custom2Data'),
        name: 'custom2Data',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.people.custom3Label'),
        name: 'custom3Label',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.people.custom3Data'),
        name: 'custom3Data',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.people.custom4Label'),
        name: 'custom4Label',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.people.custom4Data'),
        name: 'custom4Data',
        convert: toText(64)
      },
      {
        title: i18n.t('fields.people.inactive'),
        name: 'inactive',
        aliases: ['Person Inactive'],
        convert: toBoolean,
        defaultValue: false
      },
      {
        title: i18n.t('fields.people.email'),
        name: 'email',
        aliases: ['Email Address'],
        convert: toText(384)
      },
      {
        title: i18n.t('fields.people.respiratorRequired'),
        name: 'respiratorRequired',
        convert: toBoolean,
        defaultValue: true
      },
      {
        title: i18n.t('fields.people.trainingDate'),
        name: 'trainingDate',
        aliases: ['Last Training', 'Last Training Date'],
        convert: toISODate
      },
      {
        title: i18n.t('fields.people.trainingStatus'),
        name: 'trainingExpiration',
        aliases: ['Training Expires'],
        convert: toISODate
      },
      {
        title: i18n.t('fields.people.isTestAdmin'),
        name: 'isTestAdmin',
        convert: toBoolean,
        defaultValue: false
      },
      {
        title: i18n.t('fields.people.isSupervisor'),
        name: 'isSupervisor',
        convert: toBoolean,
        defaultValue: false
      },
      {
        title: i18n.t('fields.people.schedulingKey'),
        name: 'schedulingKey',
        convert: toText(36),
        defaultValue: uuidv4()
      }
    ]
  }
}

/**
 * Enum of tab names used in Settings.
 */
export enum SettingsTab {
  Account = 'account',
  CustomFields = 'customFields',
  Masks = 'masks',
  Devices = 'devices',
  Import = 'import',
  Templates = 'templates'
}

<template>
  <BasePage>
    <DataTable
      :search-type="SearchType.Query"
      show-expand
      :table-store-id="TableStoreId.People"
      :delete-warning="deleteWarning"
      :note-title="noteTitle"
      :delete-title="t('dialogs.headers.deletePerson')"
      disable-delete-property="isSupervisor"
      :disableDeleteMessage="t('warnings.deleteSupervisor')"
      :disabled-value="true"
      @new="createNewItem"
      v-model:expanded="store.expandedItems"
      @update:expanded="loadFitTestsByPerson"
      @update:options="onOptionsUpdated"
    >
      <template v-slot:left>
        <EditColumns
          :table-store-id="TableStoreId.People"
          :headers="store.headers"
          @save="onColumnsSaved"
        />
        <ButtonIcon
          :disabled="!store.advancedFilterApplied"
          @click="store.resetAdvancedFilter()"
          :text="t('buttons.resetFilters')"
          icon="mdi-close-circle"
        />
      </template>
      <template
        v-for="(header, index) in store.headers"
        v-slot:[`header.${header.key}`]
        :key="index"
      >
        <HeaderWithFilter
          :header="header"
          :current-sort="currentSort"
          :table-store-id="TableStoreId.People"
        />
      </template>
      <template v-slot:item.email="{ item }">
        <v-icon
          v-if="item.email"
          v-tooltip="item.email"
          size="small"
          @click="copyToClipboard(item)"
        >
          mdi-email
        </v-icon>
        <v-icon
          v-else
          v-tooltip="t('warnings.missingEmail')"
          @click="store.editItem(item)"
          :color="Colors.accentWarning"
          size="small"
        >
          mdi-alert
        </v-icon>
      </template>

      <template v-slot:item.testDueDate="{ item, value }">
        <StatusChip
          :disabled="!item.respiratorRequired"
          :iso-date="value"
          :days-warning="settingsStore.globals.testing.daysWarning"
          :expires="true"
        />
      </template>

      <template
        v-if="settingsStore.globals.training.enabled"
        v-slot:item.trainingExpiration="{ item, value }"
      >
        <StatusChip
          :disabled="!item.respiratorRequired"
          :iso-date="value"
          :days-warning="settingsStore.globals.training.daysWarning"
          :expires="settingsStore.globals.training.expires"
        />
      </template>

      <template
        v-if="settingsStore.globals.clearance.enabled"
        v-slot:item.clearanceExpiration="{ item, value }"
      >
        <StatusChip
          :disabled="!item.respiratorRequired"
          :iso-date="value"
          :days-warning="settingsStore.globals.clearance.daysWarning"
          :expires="settingsStore.globals.clearance.expires"
          :msg-compliant="t('labels.cleared')"
          :msg-no-record="t('labels.notCleared')"
        />
      </template>

      <template v-slot:item.note="{ item }">
        <v-icon v-if="(item as PeopleRecord).note" size="small" @click="showNote(item)">
          mdi-note-text
        </v-icon>
        <span v-else>-</span>
      </template>

      <template v-if="settingsStore.globals.clearance.enabled" v-slot:menuItems="{ item }">
        <v-list-item
          density="compact"
          :title="t('buttons.editMedicalClearance')"
          prepend-icon="mdi-medical-bag"
          @click="editMedicalClearance(item)"
        />
      </template>

      <template v-slot:expanded-row="{ item }">
        <tr class="expanded-row">
          <td class="expanded-column" :colspan="store.headers.length + 1">
            <FitTestHistory :person="item" />
          </td>
        </tr>
      </template>

      <template v-slot:footer>
        <DialogInfo
          v-model="store.showNoteDialog"
          :title="noteTitle"
          :message="store.selectedItem?.note"
          @close="hideNote"
        />
        <EditMedicalClearance />
        <EditPerson />
        <EditFitTest />
      </template>
    </DataTable>
  </BasePage>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { MessageType } from '@/enums/MessageType'
import { SearchType } from '@/enums/SearchType'
import { TableStoreId } from '@/enums/TableStoreId'
import type { IFitTestsStore } from '@/interfaces/api/IFitTestsStore'
import type { IMedicalClearancesStore } from '@/interfaces/api/IMedicalClearancesStore'
import type { IPeopleStore } from '@/interfaces/api/IPeopleStore'
import type { ITableOptions } from '@/interfaces/ITableOptions'
import type { ITableSort } from '@/interfaces/ITableSort'
import { PeopleRecord } from '@/models/PeopleRecord'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import { useSnackbarStore } from '@/stores/ui/SnackbarStore'
import Colors from '@/styles/_colors.module.scss'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

const store = TableStoreFactory.get(TableStoreId.People) as IPeopleStore
const fitTestStore = TableStoreFactory.get(TableStoreId.FitTests) as IFitTestsStore
const medicalClearanceStore = TableStoreFactory.get(
  TableStoreId.MedicalClearances
) as IMedicalClearancesStore
const settingsStore = useSettingsStore()
const snackbarStore = useSnackbarStore()

// PROPS & EMITS

// REACTIVE VARIABLES
const currentSort = ref<ITableSort>()

// COMPUTED PROPERTIES
const deleteWarning = computed((): string => {
  if (store.selectedItem?.firstName && store.selectedItem?.lastName) {
    return t('dialogs.delete.warning', {
      name: `${store.selectedItem.firstName} ${store.selectedItem.lastName}`
    })
  }
  return t('dialogs.delete.warning')
})

const noteTitle = computed((): string => {
  let title = t('dialogs.headers.showNotePerson')
  if (store.selectedItem) {
    title += ` ${store.selectedItem.firstName} ${store.selectedItem.lastName}:`
  }
  return title
})

// LIFECYCLE HOOKS

// FUNCTIONS
const copyToClipboard = (item: PeopleRecord) => {
  if (item?.email) {
    navigator.clipboard.writeText(item.email)
    snackbarStore.addMessage(t('text.copiedToClipboard'), MessageType.Info)
  }
}

const createNewItem = () => {
  store.createNewItem(new PeopleRecord())
}

const editMedicalClearance = async (item: PeopleRecord) => {
  store.setSelectedItem(item)
  medicalClearanceStore.getOneByPersonId(item.id!)
}

const hideNote = () => {
  store.setSelectedItem(undefined)
}

const loadFitTestsByPerson = () => {
  if (store.expandedItems.length > 1) {
    store.expandedItems.shift()
  }
  const item = store.items.find((item) => {
    return item.id === store.expandedItems[0]
  })
  if (item?.idNumber) {
    fitTestStore.setFitTestFilterByIdNumber(item.idNumber!)
  }
}

const onColumnsSaved = () => {
  store.updateHeaders()
}

const onOptionsUpdated = (tableOptions: ITableOptions) => {
  currentSort.value = tableOptions.sortBy[0]
}

const showNote = (item: PeopleRecord) => {
  store.setSelectedItem(item)
  store.showNoteDialog = true
}
</script>

<style lang="scss" scoped></style>

import { EmailTemplate } from '@/enums/EmailTemplate'
import { StoreId } from '@/enums/StoreId'
import type { ISimpleOption } from '@/interfaces/ISimpleOption'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useSettingsStore } from './SettingsStore'

export const useEmailTemplatesStore = defineStore(StoreId.EmailTemplates, () => {
  // STORES, IMPORTS, & COMPOSABLES
  const i18n = useI18n({ useScope: 'global' })
  const settingsStore = useSettingsStore()

  // REACTIVE VARIABLES
  const templateId = ref<EmailTemplate>(EmailTemplate.AppointmentTesting)

  // COMPUTED PROPERTIES
  const selectedTemplate = computed(() => {
    return templates.value.find((template: ISimpleOption) => {
      return template.value === templateId.value
    })
  })

  const templates = computed(() => {
    const _templates: Array<ISimpleOption> = []

    _templates.push({
      title: i18n.t('labels.appointmentTesting'),
      value: EmailTemplate.AppointmentTesting,
      props: {
        hint: i18n.t('hints.appointmentDue', {
          days: settingsStore.globals.testing.reminders.appointmentDaysBefore
        })
      }
    })

    _templates.push({
      title: i18n.t('labels.dueSoonTesting'),
      value: EmailTemplate.DueSoonTesting,
      props: {
        hint: i18n.t('hints.dueSoon', { days: settingsStore.globals.testing.daysWarning })
      }
    })

    _templates.push({
      title: i18n.t('labels.overdueTesting'),
      value: EmailTemplate.OverdueTesting,
      props: {
        hint: i18n.t('hints.overdue', {
          total: settingsStore.globals.testing.reminders.maxOverdue
        })
      }
    })

    if (settingsStore.globals.clearance.enabled) {
      _templates.push({
        title: i18n.t('labels.appointmentClearance'),
        value: EmailTemplate.AppointmentClearance,
        props: {
          disabled: !settingsStore.globals.clearance.enabled,
          hint: i18n.t('hints.appointmentDue', {
            days: settingsStore.globals.clearance.reminders.appointmentDaysBefore
          })
        }
      })
      if (settingsStore.globals.clearance.expires) {
        _templates.push({
          title: i18n.t('labels.dueSoonClearance'),
          value: EmailTemplate.DueSoonClearance,
          props: {
            hint: i18n.t('hints.dueSoon', { days: settingsStore.globals.clearance.daysWarning })
          }
        })
        _templates.push({
          title: i18n.t('labels.overdueClearance'),
          value: EmailTemplate.OverdueClearance,
          props: {
            hint: i18n.t('hints.overdue', {
              total: settingsStore.globals.clearance.reminders.maxOverdue
            })
          }
        })
      }
    }

    if (settingsStore.globals.training.enabled) {
      _templates.push({
        title: i18n.t('labels.appointmentTraining'),
        value: EmailTemplate.AppointmentTraining,
        props: {
          hint: i18n.t('hints.appointmentDue', {
            days: settingsStore.globals.training.reminders.appointmentDaysBefore
          })
        }
      })
      if (settingsStore.globals.training.expires) {
        _templates.push({
          title: i18n.t('labels.dueSoonTraining'),
          value: EmailTemplate.DueSoonTraining,
          props: {
            hint: i18n.t('hints.dueSoon', { days: settingsStore.globals.training.daysWarning })
          }
        })
        _templates.push({
          title: i18n.t('labels.overdueTraining'),
          value: EmailTemplate.OverdueTraining,
          props: {
            hint: i18n.t('hints.overdue', {
              total: settingsStore.globals.training.reminders.maxOverdue
            })
          }
        })
      }
    }
    if (_templates[0].value) {
      templateId.value = _templates[0].value as EmailTemplate
    }
    return _templates
  })

  return { selectedTemplate, templates, templateId }
})

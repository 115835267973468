/**
 * Enum of calendar ids. Unknown should never occur.
 */
export enum CalendarId {
  Clearance = 'clearance',
  Tentative = 'tentative',
  Testing = 'testing',
  Training = 'training',
  Unknown = 'unknown'
}

<template>
  <BaseEditor
    v-bind="$attrs"
    :table-store-id="TableStoreId.People"
    :title="title"
    :max-height="1000"
    :max-width="1050"
    @save-item="saveItem"
  >
    <v-row>
      <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" xxl="4">
        <PrimaryTextField
          required
          :label="t('fields.people.firstName')"
          :maxlength="64"
          v-model.trim="item.firstName"
          :rules="RulesManager.getRules(Rule.Required)"
        />
        <PrimaryTextField
          required
          :label="t('fields.people.lastName')"
          :maxlength="64"
          v-model.trim="item.lastName"
          :rules="RulesManager.getRules(Rule.Required)"
        />
        <PrimaryTextField
          required
          :label="t('fields.people.idNumber')"
          :maxlength="64"
          v-model.trim="item.idNumber"
          :rules="RulesManager.getRules(Rule.Required)"
          @blur="showIdNumberWarning"
        />
        <PrimarySelect
          v-if="supervisorOptions.length > 0"
          v-model="item.supervisorId"
          :label="t('fields.people.supervisor')"
          :items="supervisorOptions"
        ></PrimarySelect>
        <DisplayText
          v-if="supervisorOptions.length === 0 && isLoaded"
          :label="t('fields.people.supervisor')"
          :value="t('errors.noSupervisors')"
        >
          <template v-slot:icon>
            <v-icon :color="Colors.accentError">mdi-alert-circle</v-icon>
          </template>
        </DisplayText>
        <PrimaryTextField
          required
          inputmode="email"
          type="email"
          :label="t('fields.people.email')"
          :maxlength="64"
          v-model.trim="item.email"
          :rules="RulesManager.getRules([Rule.ValidEmail, Rule.Required])"
        />
        <DateField
          v-if="settingsStore.globals.training.enabled"
          v-model="trainingDate"
          :label="t('fields.people.trainingDate')"
          @update:model-value="updateTrainingExpiration"
        />
        <div style="position: relative">
          <DateField v-model="trainingExpiration" :label="t('fields.people.trainingExpiration')" />
          <StatusChip
            style="position: absolute; top: 1.05rem; right: 0"
            :iso-date="trainingExpiration?.toISOString()"
            :days-warning="settingsStore.globals.training.daysWarning"
            :expires="settingsStore.globals.training.expires"
            :mini="true"
          />
        </div>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" xxl="4">
        <PrimarySwitch
          :hideDetails="directReports.length === 0"
          :hint="t('warnings.disableInactiveSupervisor')"
          :persistent-hint="directReports.length > 0"
          :disabled="directReports.length > 0"
          :label="t('fields.people.active')"
          v-model="item.inactive"
          :true-value="false"
          :false-value="true"
        />
        <PrimarySwitch
          :label="t('fields.people.respiratorRequired')"
          v-model="item.respiratorRequired"
        />
        <PrimarySwitch :label="t('fields.people.isTestAdmin')" v-model="item.isTestAdmin" />
        <PrimarySwitch
          :hideDetails="directReports.length === 0"
          :hint="t('warnings.disableSupervisor')"
          :persistent-hint="directReports.length > 0"
          :disabled="directReports.length > 0"
          style="margin-bottom: 1rem"
          :label="t('fields.people.isSupervisor')"
          v-model="item.isSupervisor"
        />
        <TextSelectCombo
          v-for="fieldDefinition in customFieldsStore.activeFieldDefinitions"
          :key="fieldDefinition.id"
          :maxlength="64"
          v-model="item[fieldDefinition.valueField]"
          :rules="getCustomFieldRules(fieldDefinition.item)"
          :item="fieldDefinition.item"
          :select-options="fieldDefinition.selectOptions"
          :auto-values="fieldDefinition.autoValues"
        />
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4" xxl="4">
        <PrimaryTextArea :maxlength="128" v-model="item.note" :label="t('fields.people.note')" />
        <div v-if="directReports?.length > 0">
          <DisplayText :label="t('labels.directReports')" :values="directReports" />
        </div>
        <div v-if="item.respiratorRequired">
          <DisplayText
            :label="t('fields.people.testExpires')"
            :value="`${formatDate(item.testDueDate, false, settingsStore.localeCode)}`"
          >
            <template v-slot:icon>
              <StatusChip
                :iso-date="item.testDueDate"
                :days-warning="settingsStore.globals.testing.daysWarning"
                :expires="true"
                :mini="true"
              />
            </template>
          </DisplayText>
          <DisplayText
            v-if="settingsStore.globals.clearance.enabled"
            :label="t('fields.people.clearanceExpires')"
            :value="`${formatDate(item.clearanceExpiration, false, settingsStore.localeCode)}`"
          >
            <template v-slot:icon>
              <StatusChip
                :iso-date="item.clearanceExpiration"
                :days-warning="settingsStore.globals.clearance.daysWarning"
                :expires="settingsStore.globals.clearance.expires"
                :mini="true"
              />
            </template>
          </DisplayText>
        </div>
      </v-col>
    </v-row>
    <p class="required-text">* = {{ t('labels.required').toLowerCase() }}</p>
    <BaseDialog
      icon="mdi-alert"
      :icon-color="Colors.accentWarning"
      :title="t('dialogs.headers.fitTestRelationship')"
      v-model="showIdNumberDialog"
    >
      {{ t('warnings.fitTestRelationshipWarning') }}
    </BaseDialog>
  </BaseEditor>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { useRulesManager } from '@/composables/RulesManager'
import { formatDate, isDefined } from '@/composables/utils'
import { Rule } from '@/enums/Rule'
import { TableStoreId } from '@/enums/TableStoreId'
import type { ICustomFieldsStore } from '@/interfaces/api/ICustomFieldsStore'
import type { IPeopleStore } from '@/interfaces/api/IPeopleStore'
import type { ISimpleOption } from '@/interfaces/ISimpleOption'
import { CustomFieldRecord } from '@/models/CustomFieldRecord'
import { PeopleRecord } from '@/models/PeopleRecord'
import { useCustomFieldsStore } from '@/stores/db/CustomFieldsStore'
import { useFitTestsStore } from '@/stores/db/FitTestsStore'
import { TableStoreFactory } from '@/stores/db/TableStoreFactory'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import Colors from '@/styles/_colors.module.scss'
import dayjs from 'dayjs'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import DisplayText from '../DisplayText.vue'

const store = TableStoreFactory.get(TableStoreId.People) as IPeopleStore
const customFieldsStore = useCustomFieldsStore() as ICustomFieldsStore
const fitTestsStore = useFitTestsStore()
const settingsStore = useSettingsStore()

const { t } = useI18n({ useScope: 'global' })

const RulesManager = useRulesManager()

// PROPS & EMITS

// REACTIVE VARIABLES
const isLoaded = ref<boolean>(false)
const showIdNumberDialog = ref<boolean>(false)
const item = ref<PeopleRecord>(new PeopleRecord())
const supervisorOptions = ref<Array<ISimpleOption>>([])
const directReports = ref<Array<string>>([])

// COMPUTED PROPERTIES
const trainingDate = computed({
  get: (): Date | null => {
    if (isDefined(item.value.trainingDate)) {
      return new Date(item.value.trainingDate!)
    }
    return null
  },
  set: (value: Date | null) => {
    item.value.trainingDate = isDefined(value) ? value!.toISOString() : null
  }
})

const trainingExpiration = computed({
  get: (): Date | null => {
    if (isDefined(item.value.trainingExpiration)) {
      return new Date(item.value.trainingExpiration!)
    }
    if (trainingDate.value) {
      return dayjs(trainingDate.value)
        .add(settingsStore.globals.clearance.daysToExpire, 'days')
        .toDate()
    }
    return null
  },
  set: (value: Date | null) => {
    item.value.trainingExpiration = isDefined(value) ? value!.toISOString() : null
  }
})

const title = computed((): string => {
  if (item.value?.id) {
    return t('dialogs.headers.editPerson')
  }
  return t('dialogs.headers.createPerson')
})

// LIFECYCLE HOOKS
const loadData = () => {
  const promises = []
  // Get all supervisors
  promises.push(
    store.getSupervisorOptions().then((options: Array<ISimpleOption>) => {
      supervisorOptions.value = options
    })
  )

  // Get all supervised
  if (item.value.id) {
    promises.push(
      store.getDirectReports(item.value.id).then((reports: Array<string>) => {
        directReports.value = reports
      })
    )
  }

  // Get custom field definitions
  promises.push(customFieldsStore.loadItems())
  Promise.all(promises).then(() => {
    isLoaded.value = true
  })
}

// WATCHERS
watch(
  (): any => store.selectedItem,
  (value) => {
    if (isDefined(value)) {
      loadData()
    } else {
      isLoaded.value = false
    }
    item.value = { ...value }
  }
)

// FUNCTIONS
const getCustomFieldRules = (
  customField: CustomFieldRecord | undefined,
  rules: Array<Rule> = []
): Array<Function | undefined> => {
  if (!customField) {
    return []
  }
  if (customField?.required === 1) {
    rules.push(Rule.Required)
  }
  return RulesManager.getRules(rules)
}

const saveItem = async () => {
  // Sanitize the person's custom fields before saving
  const oldIdNumber = store.selectedItem!.idNumber
  const newItem = { ...item.value }
  customFieldsStore.sanitizePerson(newItem)
  const response = await store.save(newItem)
  if (!response.error && newItem.idNumber) {
    await fitTestsStore.updateFitTestsByPerson(oldIdNumber, newItem.idNumber)
  }
}

const showIdNumberWarning = () => {
  if (isDefined(item.value.id)) {
    showIdNumberDialog.value = true
  }
}

const updateTrainingExpiration = () => {
  if (trainingDate.value) {
    trainingExpiration.value = dayjs(trainingDate.value)
      .add(settingsStore.globals.training.daysToExpire, 'days')
      .toDate()
  } else {
    trainingExpiration.value = null
  }
}
</script>

<style lang="scss" scoped></style>

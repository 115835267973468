import { TableStoreId } from '@/enums/TableStoreId'
import type { ICustomFieldsStore } from '@/interfaces/api/ICustomFieldsStore'
import type { ITableStore } from '@/interfaces/api/ITableStore'
import { useCustomFieldsStore } from './CustomFieldsStore'
import { useDailyChecksStore } from './DailyChecksStore'
import { useDevicesStore } from './DevicesStore'
import { useEventsStore } from './EventsStore'
import { useFilesStore } from './FilesStore'
import { useFitTestsStore } from './FitTestsStore'
import { useMasksStore } from './MasksStore'
import { useMedicalClearancesStore } from './MedicalClearancesStore'
import { usePeopleStore } from './PeopleStore'

const _tableStores = new Map<TableStoreId, ITableStore | ICustomFieldsStore>()

const _initializeTableStores = () => {
  _tableStores.set(TableStoreId.CustomFields, useCustomFieldsStore())
  _tableStores.set(TableStoreId.DailyChecks, useDailyChecksStore())
  _tableStores.set(TableStoreId.Devices, useDevicesStore())
  _tableStores.set(TableStoreId.Events, useEventsStore())
  _tableStores.set(TableStoreId.Files, useFilesStore())
  _tableStores.set(TableStoreId.FitTests, useFitTestsStore())
  _tableStores.set(TableStoreId.Masks, useMasksStore())
  _tableStores.set(TableStoreId.MedicalClearances, useMedicalClearancesStore())
  _tableStores.set(TableStoreId.People, usePeopleStore())
}

export class TableStoreFactory {
  public static get(tableStoreId: TableStoreId) {
    if (_tableStores.size === 0) {
      _initializeTableStores()
    }
    const store = _tableStores.get(tableStoreId)
    if (!store) {
      throw new Error(`The table store '${tableStoreId}' is not defined in the Store Factory.`)
    }
    return store
  }
}

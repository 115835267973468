import { isDefined } from '@/composables/utils'
import type { ITableHeader } from '@/interfaces/ITableHeader'
import { type Composer } from 'vue-i18n'
import type { DailyCheckRecord } from '../DailyCheckRecord'

export const getTableHeaders = (
  i18n: Composer<{}, {}, {}, string, never, string>
): Array<ITableHeader> => {
  return [
    { title: i18n.t('fields.dailyChecks.serialNumber'), key: 'serialNumber', align: 'start' },
    {
      title: i18n.t('fields.dailyChecks.date'),
      key: 'date',
      align: 'start',
      value: (item: DailyCheckRecord) => {
        return isDefined(item.date) ? item.date : null
      }
    },
    { title: i18n.t('fields.dailyChecks.particle'), key: 'particle', align: 'start' },
    {
      title: i18n.t('fields.dailyChecks.zero'),
      key: 'zero',
      align: 'start'
    },
    { title: i18n.t('fields.dailyChecks.maxFf'), key: 'maxFf', align: 'start', sortable: false },
    {
      title: i18n.t('fields.dailyChecks.classifier'),
      key: 'classifier',
      align: 'start',
      sortable: false
    },
    {
      title: i18n.t('fields.dailyChecks.n95'),
      key: 'n95',
      align: 'start',
      sortable: false,
      value: (item: DailyCheckRecord) => `${item.n95 ? i18n.t('labels.no') : i18n.t('labels.yes')}`
    },
    { title: i18n.t('fields.actions'), key: 'actions', align: 'start', sortable: false }
  ]
}

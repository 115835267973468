/**
 * Enum that defines all SQL comparisons used in constructing a valid SQL query.
 */
export enum SqlComparison {
  Between = 'BETWEEN',
  Contains = 'CONTAINS',
  Equal = '=',
  FreeText = 'FREETEXT',
  GreaterThan = '>',
  GreaterThanOrEqual = '>=',
  In = 'IN',
  IsNull = 'IS NULL',
  IsNotNull = 'IS NOT NULL',
  LessThan = '<',
  LessThanOrEqual = '<=',
  Like = 'LIKE',
  NotBetween = 'NOT BETWEEN',
  NotEqual = '!=',
  NotIn = 'NOT IN',
  NotLike = 'NOT LIKE'
}

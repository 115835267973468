<template>
  <v-dialog
    v-bind="$attrs"
    v-model="model"
    :scrim="scrim"
    :maxWidth="maxWidth"
    :maxHeight="maxHeight"
    :no-click-animation="persistent"
    :persistent="persistent || persistentOverlay"
    scrollable
    icon="mdi-pencil"
  >
    <v-card :class="{ mobile: display.xs.value }">
      <slot v-if="!noHeader" name="header">
        <v-card-title>
          <v-icon style="float: left" :color="iconColor" class="header-icon" v-if="icon">{{
            icon
          }}</v-icon>
          <div style="margin-left: 0.5rem; float: left">{{ title }}</div>
          <div style="float: right">
            <slot name="status"></slot>
          </div>
        </v-card-title>
      </slot>
      <v-card-text
        style="margin-top: 0; padding-top: 0"
        :max-height="maxHeight"
        :class="{
          mobile: display.xs.value
        }"
      >
        <slot></slot>
      </v-card-text>
      <v-card-text v-if="error && error.length > 0" class="errors">
        <div class="hint-text err-font">{{ error }}</div>
      </v-card-text>
      <v-divider style="margin-left: 1rem; margin-right: 1rem" class="tab-divider"></v-divider>
      <v-card-actions style="margin-top: 0; padding-top: 0" v-if="!noActions" class="actions" text>
        <slot name="actions">
          <v-spacer />
          <ButtonPrimary @click="close" :text="t('buttons.close')" />
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { useI18n } from 'vue-i18n'
import { useDisplay } from 'vuetify'

const { t } = useI18n({ useScope: 'global' })

const display = useDisplay()

// PROPS & EMITS
defineProps({
  open: { type: Boolean, default: false },
  error: { type: String, default: undefined },
  icon: { type: String, default: undefined },
  iconColor: { type: String, default: undefined },
  maxHeight: { type: Number, default: 1000 },
  maxWidth: { type: Number, default: 500 },
  noHeader: { type: Boolean, default: false },
  noActions: { type: Boolean, default: false },
  persistent: { type: Boolean, default: false },
  persistentOverlay: { type: Boolean, default: false },
  scrim: { type: Boolean, default: true },
  title: { type: String, default: null }
})

// REACTIVE VARIABLES
const model = defineModel<boolean>({ default: false })

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// FUNCTIONS
const close = () => {
  model.value = false
}
</script>

<style lang="scss" scoped>
.v-dialog {
  .errors {
    padding: 16px;
  }

  .actions {
    padding: 16px;
  }

  .hint-text {
    font-size: 12px;
    opacity: 0.76;

    &.err-font {
      color: $color-accent-error;
    }
  }
}
</style>

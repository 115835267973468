import { EventStatus } from '@/enums/EventStatus'
import { EventTransparency } from '@/enums/EventTransparency'
import type { ProgramCategory } from '@/enums/ProgramCategory'
import { TableRecord } from './TableRecord'

export class EventRecord extends TableRecord {
  public organizerId!: number
  public attendeeId: number | null = null
  public deviceId?: number | null = null
  public uuid!: string
  public summary: string | null = null
  public sequence: number = 1
  public status: number = EventStatus.Tentative
  public transparency: number = EventTransparency.Opaque
  public dtStart!: number
  public dtEnd!: string
  public category!: ProgramCategory
  public location!: string
  public url: string | null = null
}

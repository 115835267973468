import { isDefined } from '@/composables/utils'
import { StoreId } from '@/enums/StoreId'
import { StoreProcedure } from '@/enums/StoreProcedure'
import type { IStatistics } from '@/interfaces/IStatistics'
import { useAPIStore } from '@/stores/db/ApiStore'
import { useCustomFieldsStore } from '@/stores/db/CustomFieldsStore'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import Colors from '@/styles/_colors.module.scss'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

export const useDashboardStore = defineStore(
  StoreId.Dashboard,
  () => {
    // STORES, IMPORTS, & COMPOSABLES
    const apiStore = useAPIStore()
    const customFieldsStore = useCustomFieldsStore()
    const i18n = useI18n({ useScope: 'global' })
    const settingsStore = useSettingsStore()

    // REACTIVE VARIABLES
    const filters = ref<Record<string, Record<string, boolean>>>({})
    const stats = ref<{ testing: IStatistics; training: IStatistics; clearance: IStatistics }>({
      testing: {
        compliant: 0,
        dueSoon: 0,
        overdue: 0,
        noRecord: 0,
        total: 0,
        compliantPercent: 0,
        dueSoonPercent: 0,
        overduePercent: 0,
        noRecordPercent: 0,
        series: [
          {
            name: i18n.t('labels.percentage'),
            data: []
          }
        ]
      },
      training: {
        compliant: 0,
        dueSoon: 0,
        overdue: 0,
        noRecord: 0,
        total: 0,
        compliantPercent: 0,
        dueSoonPercent: 0,
        overduePercent: 0,
        noRecordPercent: 0,
        series: [
          {
            name: i18n.t('labels.percentage'),
            data: []
          }
        ]
      },
      clearance: {
        compliant: 0,
        dueSoon: 0,
        overdue: 0,
        noRecord: 0,
        total: 0,
        compliantPercent: 0,
        dueSoonPercent: 0,
        overduePercent: 0,
        noRecordPercent: 0,
        series: [
          {
            name: i18n.t('labels.percentage'),
            data: []
          }
        ]
      }
    })

    // COMPUTED PROPERTIES

    // FUNCTIONS - PRIVATE
    const _initFilters = () => {
      const newFilter: Record<string, Record<string, boolean>> = {}
      customFieldsStore.activeFieldDefinitions.forEach((definition) => {
        newFilter[definition.valueField] = {}
        const oldFilter = filters.value[definition.valueField]
        if (definition.item?.combo === 0) {
          definition.selectOptions.forEach((option) => {
            if (option.value) {
              if (oldFilter && isDefined(oldFilter[option.value])) {
                newFilter[definition.valueField][option.value] =
                  filters.value[definition.valueField][option.value]
              } else {
                newFilter[definition.valueField][option.value] = false
              }
            }
          })
        } else if (definition.item?.autoComplete) {
          definition.autoValues.forEach((value) => {
            if (value) {
              if (oldFilter && isDefined(oldFilter[value])) {
                newFilter[definition.valueField][value] =
                  filters.value[definition.valueField][value]
              } else {
                newFilter[definition.valueField][value] = false
              }
            }
          })
        }
      })
      filters.value = newFilter
    }

    // FUNCTIONS - PUBLIC
    const convertToString = (filter: Record<string, boolean>) => {
      if (!filter) {
        return ''
      }
      const items: Array<string> = []
      const keys = Object.keys(filter)
      keys.forEach((key: string) => {
        if (filter[key] === true) {
          items.push(key)
        }
      })

      if (items.length === 0) {
        return ''
      }
      return items.join(',')
    }

    const createSeries = (
      stats: IStatistics,
      expires: boolean = true,
      msgCompliant?: string,
      msgNoRecord?: string
    ) => {
      const data = []
      if (stats.compliant > 0) {
        data.push({
          name: msgCompliant || i18n.t('charts.statistics.compliant'),
          y: stats.compliantPercent,
          color: Colors.accentSuccess,
          raw: stats.compliant
        })
      }
      if (stats.dueSoon > 0 && expires) {
        data.push({
          name: i18n.t('charts.statistics.dueSoon'),
          sliced: true,
          selected: true,
          y: stats.dueSoonPercent,
          color: Colors.accentPrimary,
          raw: stats.dueSoon
        })
      }
      if (stats.overdue > 0 && expires) {
        data.push({
          name: i18n.t('charts.statistics.overdue'),
          y: stats.overduePercent,
          color: Colors.accentError,
          raw: stats.overdue
        })
      }
      if (stats.noRecord > 0) {
        data.push({
          name: msgNoRecord || i18n.t('charts.statistics.noRecord'),
          y: stats.noRecordPercent,
          color: Colors.dark56,
          raw: stats.noRecord
        })
      }
      stats.series = [
        {
          name: i18n.t('labels.percentage'),
          data
        }
      ]
    }

    const loadStatistics = async () => {
      _initFilters()

      // Optionally filter on every custom field.
      const company = convertToString(filters.value.company)
      const location = convertToString(filters.value.location)
      const custom1Data = convertToString(filters.value.custom1Data)
      const custom2Data = convertToString(filters.value.custom2Data)
      const custom3Data = convertToString(filters.value.custom3Data)
      const custom4Data = convertToString(filters.value.custom4Data)

      const response = await apiStore.execute(StoreProcedure.GetStatistics, [
        settingsStore.globals.testing.daysWarning,
        settingsStore.globals.training.daysWarning,
        settingsStore.globals.clearance.daysWarning,
        company,
        location,
        custom1Data,
        custom2Data,
        custom3Data,
        custom4Data
      ])
      if (!response.error && response.data) {
        const results = response.data.results[0]
        const trainingCount =
          results.trainingCompliant + results.trainingDueSoon + results.trainingOverdue
        const clearanceCount =
          results.clearanceCompliant + results.clearanceDueSoon + results.clearanceOverdue
        stats.value = {
          testing: {
            compliant: results.testCompliant,
            dueSoon: results.testDueSoon,
            overdue: results.testOverdue,
            noRecord: results.testNoRecord,
            total: results.total,
            compliantPercent: results.testCompliantPercent,
            dueSoonPercent: results.testDueSoonPercent,
            overduePercent: results.testOverduePercent,
            noRecordPercent: results.testNoRecordPercent,
            series: []
          },
          training: {
            compliant: settingsStore.globals.training.expires
              ? results.trainingCompliant
              : trainingCount,
            dueSoon: settingsStore.globals.training.expires ? results.trainingDueSoon : 0,
            overdue: settingsStore.globals.training.expires ? results.trainingOverdue : 0,
            noRecord: results.trainingNoRecord,
            total: results.total,
            compliantPercent: results.trainingCompliantPercent,
            dueSoonPercent: results.trainingDueSoonPercent,
            overduePercent: results.trainingOverduePercent,
            noRecordPercent: results.trainingNoRecordPercent,
            series: []
          },
          clearance: {
            compliant: settingsStore.globals.clearance.expires
              ? results.clearanceCompliant
              : clearanceCount,
            dueSoon: settingsStore.globals.clearance.expires ? results.clearanceDueSoon : 0,
            overdue: settingsStore.globals.clearance.expires ? results.clearanceOverdue : 0,
            noRecord: results.clearanceNoRecord,
            total: results.total,
            compliantPercent: results.clearanceCompliantPercent,
            dueSoonPercent: results.clearanceDueSoonPercent,
            overduePercent: results.clearanceOverduePercent,
            noRecordPercent: results.clearanceNoRecordPercent,
            series: []
          }
        }
        createSeries(stats.value.testing)
        createSeries(stats.value.training, settingsStore.globals.training.expires)
        createSeries(
          stats.value.clearance,
          settingsStore.globals.clearance.expires,
          i18n.t('labels.cleared'),
          i18n.t('labels.notCleared')
        )
      }
    }

    return {
      loadStatistics,
      filters,
      stats
    }
  },
  {
    persist: {
      storage: localStorage,
      pick: ['filters']
    }
  }
)

import { LocaleDefinition } from '@/enums/LocaleDefinition'
import Colors from '@/styles/_colors.module.scss'
import { createVuetify } from 'vuetify'
import * as directives from 'vuetify/directives'
import { VDateInput } from 'vuetify/labs/VDateInput'
import { VNumberInput } from 'vuetify/labs/VNumberInput'
import { de, en, es, fr, ja, ko, pt, zhHans, zhHant } from 'vuetify/locale'

const additionalComponents = { VDateInput, VNumberInput }

export const vuetify = createVuetify({
  locale: {
    locale: LocaleDefinition.English.language,
    fallback: LocaleDefinition.English.language,
    messages: { de, en, es, fr, ja, ko, pt, zhHans, zhHant }
  },
  theme: {
    themes: {
      light: {
        colors: {
          primary: Colors.mainPrimary,
          accent: Colors.mainQuaternary,
          secondary: Colors.dark100,
          info: Colors.mainPrimary,
          warning: Colors.accentPrimary,
          error: Colors.accentError,
          success: Colors.accentSuccess,
          textPrimary: Colors.textPrimary,
          textAlternate: Colors.textAlternate
        }
      }
    }
  },
  components: additionalComponents,
  directives
})

<template>
  <v-text-field
    style="margin-right: 0.25rem"
    v-bind="$attrs"
    prepend-inner-icon="mdi-text-search"
    :label="t('labels.filterResults')"
    clearable
    color="primary"
    variant="underlined"
  >
    <!-- @vue-skip -->
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData"
      ><slot :name="name" v-bind="slotData"
    /></template>
  </v-text-field>
</template>

<script setup lang="ts">
// STORES, IMPORTS, & COMPOSABLES
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })

// PROPS & EMITS

// REACTIVE VARIABLES

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// FUNCTIONS
</script>

<style lang="scss" scoped></style>

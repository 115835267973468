import { FilterType } from '@/enums/FilterType'
import { Status } from '@/enums/Status'
import type { ICustomFieldDefinition } from '@/interfaces/api/ICustomFieldDefinition'
import type { ITableHeader } from '@/interfaces/ITableHeader'
import { useCustomFieldsStore } from '@/stores/db/CustomFieldsStore'
import { useSettingsStore } from '@/stores/ui/SettingsStore'
import { type Composer } from 'vue-i18n'
import type { PeopleRecord } from '../PeopleRecord'

const _getFixedHeaders = (
  i18n: Composer<{}, {}, {}, string, never, string>
): Array<ITableHeader> => {
  const settingsStore = useSettingsStore()
  const headers: Array<ITableHeader> = [
    {
      title: i18n.t('fields.people.lastName'),
      key: 'lastName',
      align: 'start',
      nowrap: true,
      headerProps: { filterType: FilterType.Text }
    },
    {
      title: i18n.t('fields.people.firstName'),
      key: 'firstName',
      align: 'start',
      nowrap: true,
      headerProps: { filterType: FilterType.Text }
    },
    {
      title: i18n.t('fields.people.idNumber'),
      key: 'idNumber',
      align: 'start',
      nowrap: true,
      headerProps: { filterType: FilterType.Text }
    },
    {
      title: i18n.t('fields.people.active'),
      key: 'inactive',
      align: 'start',
      nowrap: true,
      value: (item: PeopleRecord) =>
        `${item.inactive ? i18n.t('labels.no') : i18n.t('labels.yes')}`,
      headerProps: {
        filterType: FilterType.List,
        options: [
          { title: i18n.t('labels.all'), value: null },
          { title: i18n.t('labels.yes'), value: 0 },
          { title: i18n.t('labels.no'), value: 1 }
        ]
      }
    },
    {
      title: i18n.t('fields.people.respiratorRequired'),
      key: 'respiratorRequired',
      align: 'start',
      nowrap: true,
      value: (item: PeopleRecord) =>
        `${item.respiratorRequired ? i18n.t('labels.yes') : i18n.t('labels.no')}`,
      headerProps: {
        filterType: FilterType.List,
        options: [
          { title: i18n.t('labels.all'), value: null },
          { title: i18n.t('labels.yes'), value: 1 },
          { title: i18n.t('labels.no'), value: 0 }
        ]
      }
    },
    {
      title: i18n.t('fields.people.respiratorRequired'),
      key: 'respiratorRequired',
      align: 'start',
      nowrap: true,
      value: (item: PeopleRecord) =>
        `${item.respiratorRequired ? i18n.t('labels.yes') : i18n.t('labels.no')}`,
      headerProps: {
        filterType: FilterType.List,
        options: [
          { title: i18n.t('labels.all'), value: null },
          { title: i18n.t('labels.yes'), value: 1 },
          { title: i18n.t('labels.no'), value: 0 }
        ]
      }
    },
    {
      title: i18n.t('fields.people.isTestAdmin'),
      key: 'isTestAdmin',
      align: 'start',
      nowrap: true,
      value: (item: PeopleRecord) =>
        `${item.isTestAdmin ? i18n.t('labels.yes') : i18n.t('labels.no')}`,
      headerProps: {
        filterType: FilterType.List,
        options: [
          { title: i18n.t('labels.all'), value: null },
          { title: i18n.t('labels.yes'), value: 1 },
          { title: i18n.t('labels.no'), value: 0 }
        ]
      }
    },
    {
      title: i18n.t('fields.people.isSupervisor'),
      key: 'isSupervisor',
      align: 'start',
      nowrap: true,
      value: (item: PeopleRecord) =>
        `${item.isSupervisor ? i18n.t('labels.yes') : i18n.t('labels.no')}`,
      headerProps: {
        filterType: FilterType.List,
        options: [
          { title: i18n.t('labels.all'), value: null },
          { title: i18n.t('labels.yes'), value: 1 },
          { title: i18n.t('labels.no'), value: 0 }
        ]
      }
    },
    {
      title: i18n.t('fields.people.email'),
      key: 'email',
      align: 'start',
      nowrap: true,
      headerProps: { filterType: FilterType.Text }
    },
    {
      title: i18n.t('fields.people.company'),
      key: 'company',
      align: 'start',
      nowrap: true,
      headerProps: { filterType: FilterType.Text }
    },
    {
      title: i18n.t('fields.people.location'),
      key: 'location',
      align: 'start',
      nowrap: true,
      headerProps: { filterType: FilterType.Text }
    },
    {
      title: i18n.t('fields.people.testingStatus'),
      key: 'testDueDate',
      align: 'start',
      nowrap: true,
      headerProps: {
        filterType: FilterType.Status,
        options: [
          { title: i18n.t('labels.all'), value: null },
          { title: i18n.t('labels.compliant'), value: Status.Compliant },
          { title: i18n.t('labels.dueSoon'), value: Status.DueSoon },
          { title: i18n.t('labels.overdue'), value: Status.Overdue },
          { title: i18n.t('labels.noRecord'), value: Status.NoRecord }
        ]
      }
    },
    {
      title: i18n.t('fields.people.trainingStatus'),
      key: 'trainingExpiration',
      align: 'start',
      nowrap: true,
      exclude: !settingsStore.globals.training.enabled,
      headerProps: {
        filterType: FilterType.Status,
        options: settingsStore.globals.training.expires
          ? [
              { title: i18n.t('labels.all'), value: null },
              { title: i18n.t('labels.compliant'), value: Status.Compliant },
              { title: i18n.t('labels.dueSoon'), value: Status.DueSoon },
              { title: i18n.t('labels.overdue'), value: Status.Overdue },
              { title: i18n.t('labels.noRecord'), value: Status.NoRecord }
            ]
          : [
              { title: i18n.t('labels.all'), value: null },
              { title: i18n.t('labels.compliant'), value: Status.Compliant },
              { title: i18n.t('labels.noRecord'), value: Status.NoRecord }
            ]
      }
    },
    {
      title: i18n.t('fields.people.clearanceStatus'),
      key: 'clearanceExpiration',
      align: 'start',
      nowrap: true,
      exclude: !settingsStore.globals.clearance.enabled,
      headerProps: {
        filterType: FilterType.Status,
        options: settingsStore.globals.clearance.expires
          ? [
              { title: i18n.t('labels.all'), value: null },
              { title: i18n.t('labels.cleared'), value: Status.Compliant },
              { title: i18n.t('labels.dueSoon'), value: Status.DueSoon },
              { title: i18n.t('labels.overdue'), value: Status.Overdue },
              { title: i18n.t('labels.notCleared'), value: Status.NoRecord }
            ]
          : [
              { title: i18n.t('labels.all'), value: null },
              { title: i18n.t('labels.cleared'), value: Status.Compliant },
              { title: i18n.t('labels.notCleared'), value: Status.NoRecord }
            ]
      }
    },
    {
      title: i18n.t('fields.people.note'),
      key: 'note',
      align: 'start',
      sortable: false,
      nowrap: true,
      headerProps: { filterType: FilterType.Text }
    },
    {
      title: i18n.t('fields.actions'),
      key: 'actions',
      align: 'start',
      sortable: false,
      nowrap: true,
      headerProps: {
        filterType: FilterType.Disabled
      }
    }
  ]
  return headers.filter((header) => {
    return !header.exclude
  })
}

const _getDynamicHeaders = async (): Promise<Array<ITableHeader>> => {
  const customFieldsStore = useCustomFieldsStore()
  await customFieldsStore.loadItems()
  const headers: Array<ITableHeader> = []
  customFieldsStore.activeFieldDefinitions.forEach((fieldDefinition: ICustomFieldDefinition) => {
    if (fieldDefinition.item?.enabled) {
      headers.push({
        title: fieldDefinition.item?.labelName || '',
        key: fieldDefinition.valueField,
        align: 'start',
        nowrap: true,
        headerProps: { filterType: FilterType.Text }
      })
    }
  })
  return headers
}

export const getTableHeaders = async (
  i18n: Composer<{}, {}, {}, string, never, string>
): Promise<Array<ITableHeader>> => {
  const headers = _getFixedHeaders(i18n)
  const customHeaders = await _getDynamicHeaders()
  headers.splice(6, 0, ...customHeaders)
  return headers
}

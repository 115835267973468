<template>
  <div>
    <DisplayText :value="value" :label="label" v-if="mode === EditorMode.View" />
    <v-switch
      v-bind="$attrs"
      v-if="mode === EditorMode.Edit"
      density="compact"
      color="primary"
      :hide-details="hideDetails"
      :persistent-hint="true"
    >
      <template v-slot:label>
        <span class="label-override">{{ label }}</span>
      </template>

      <!-- @vue-skip -->
      <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>
    </v-switch>
  </div>
</template>

<script setup lang="ts">
import { EditorMode } from '@/enums/EditorMode'
import type { PropType } from 'vue'

// STORES, IMPORTS, & COMPOSABLES

// PROPS & EMITS

// REACTIVE VARIABLES

defineProps({
  hideDetails: { type: Boolean, default: true },
  label: { type: String, default: '' },
  mode: { type: String as PropType<EditorMode>, default: EditorMode.Edit },
  value: { type: [String, Number, null] }
})

// COMPUTED PROPERTIES

// LIFECYCLE HOOKS

// FUNCTIONS
</script>

<style lang="scss" scoped>
.label-override {
  text-transform: none;
}
</style>
